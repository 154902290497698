.search-div{
    height:40px;
    background-color: $white;
    border-radius: 10px;
  //  min-width: 300px;
    padding:3px 10px;
    display: flex;
    align-items: center;
}

.custom-search-bar{
    border-radius: 10px;
    border: none;
    background-color: $white;
    height: 100%;
    width:80%;
    font: normal normal normal 16px/52px $poppins;
}

.custom-search-bar:focus {
     border: none;
     outline: none;
}

.custom-search-icon{
   padding:5px 15px 5px 0px;
   width:auto;
   height: 35px;
}