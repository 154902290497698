


.people-recommendations-div{
    display: none;
}

.people-suggestion-div{
   display: none;
}

.topslide-page-image{
    max-width: 100%;
    min-width: 100%;
    position: relative;
    min-height: 200px;
    max-height: 300px;
}

.topslide-page-image-div{
    min-height: 200px;
    width: 100%;
    background-color: $grey-faded;
}

@media (max-width: 520px){
    .topslide-page-card{
        min-height: 150px;
        background-color: $white;
    }
}

@media (min-width: 1200px) {

    .topslide-page-card{
        min-height: 200px;
        background-color: $grey-faded;
    }

.people-suggestion-div{
    display: block;
    margin-top: 3em;
    text-align: center;
}

.people-recommendations-div{
     display: grid;
     grid-template-columns: 1fr 1fr;
     grid-template-rows: minmax(150px,auto) minmax(150px,auto);
     gap:30px;
     margin: 2em;
  
}

.people-individual-card{
    box-shadow: 0px 3px 6px #32335E26;
    border-radius: 15px;
    padding: 10px;
    display: block;
    position: relative;
}

.dot-vertical-div{
    position: absolute;
    top: 5px;
    right: 0px;
}

.dot-vertical-icon{
    height:25px;
    width: 25px;
}

.suggestion-profile-image{
    height:60px;
    width: 60px;
    margin-bottom: 18px;
}

.sugg-username-text{
    font: normal normal 500 18px/9px Poppins;
    letter-spacing: 0px;
    color: #0F0F2D;
}

.sugg-type-text{
    font: normal normal bold 16px/7px Lato;
    letter-spacing: 0px;
    color: #979EA4;
}

.cust-follow-btn{
    background: #3E7EFF 0% 0% no-repeat padding-box;
    border-radius: 18px;
    width:100px;
    height:32px;
    border: none;
    color: $white;
    font-size: 15px;
    margin-top: 12px;
    margin-bottom: 25px;
}

 .no-mobile{
     display: block;
 }

 .topslide-text{
     margin-bottom: 8px;
 }

}