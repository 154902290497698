.checkout-div{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
}

.checkout-outer-div{
    min-height: 100vh;
}

.checkout-font-1{
   font-size: 21px;
   margin-bottom: 25px;
}

.checkout-font-2{
    font-size: 18px;
 }

 .checkout-font-3{
    font-size: 16px;
 }

 .checkout-pay-btn{
    padding: 10px 20px;
    border-radius: 10px;
 }

 .checkout-product-card{
      gap:10px;
 }

 .checkout-address-btn{
    padding: 10px 20px;
    border-radius: 10px;
 }

.checkout-product-card-outer-div{
    border: 1px solid #D5D5D5;
    padding: 20px 10px;
    border-radius: 10px;
    gap:0px;
}

.checkout-summary-div{
    box-shadow: 0px 0px 99px #32335E26;
    border-radius: 10px;
    min-height: 400px;
    padding: 20px 20px;
}

.checkout-image{
    height: 50px;
    width: 70px;
    border-radius: 10px;
    object-fit: contain;
}

.checkout-summary-outer-div{
    padding: 20px 0px 20px 40px;
}

.checkout-address-div{
    border: 1px solid #D5D5D5;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkout-address-div{
    display: flex;
    justify-content: space-between;
    padding: 15px 10px;
    border-radius: 10px;
    margin-bottom: 30px;
}

.checkout-outer-div{

}

.mobile-addressdiv{
    height:auto;overflow:auto;
    margin-left: 35px;
}

@media (max-width: 600px){

    .checkout-font-1{
        font-size: 18px;
    }

    .checkout-font-2{
        font-size: 16px;
    }

    .checkout-font-3{
        font-size: 14px;
    }

    .checkout-address-div{
        display: block;
    }

    .checkout-address-btn{
        display: block;
    }

    .checkout-address-btn{
        margin-top: 20px;
        padding: 10px 20px;
        border-radius: 10px;
     }
    
     .checkout-summary-div{
         min-height: auto;
     }

    .checkout-div{
        display: block;
    }

    .checlout-items-div{
        padding: 10px;
    }

    .mobile-addressdiv{
        height:100%;overflow-y:scroll;
        margin-left: 0px;
    }

    .checkout-summary-outer-div{
        padding: 20px 0px;
    }
    
}
