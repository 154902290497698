/*
.content-page{
    background-color: $grey-faded;
    position: relative;
    top: $topbarheight;
    left: $menuwidth;
    bottom: 0px;
    right: 0px;
    min-height: 100%;
}
*/

@media (max-width: 600px) { 
    #wrapper{
    position: relative;
    width: 100vw;
    display: block;
    margin: auto;
    }
}

@media (min-width: 600px) and (max-width: 1200px){
    #wrapper{
        background-color: #F4F3F8;
        position: relative;
    }
}


#wrapper{
    position: relative;
    max-width: 1600px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

