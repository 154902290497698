.author-profile-main-div{
    padding: 40px 80px;
    background-color: white;
    margin-right: 30px;
    min-height: 90vh;
}

.author-profile-pic{
    height: 120px;
    width: 120px;
    border-radius: 50%;
    background-color: #D9D9D9;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display:block;
    margin: 0px auto;
}

.author-profile-name{
    margin-top: 20px;
}

.author-profile-font-1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
}

.author-profile-font-2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #7C7C7C;
}

.author-profile-font-3{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7C7C7C;
}

.author-profile-font-4{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #444444;
}

.author-verification-banner-top{
   display: flex;
   align-items: center;
   column-gap: 8px;
   justify-content: center;
}

.author-profile-sk-posts{
     min-height: 100vh;width: 60%;position: relative;display: block;margin: 0px auto;
}

.author-profile-count-div{
    margin: 16px auto;
    display:flex;
    gap: 50px;
    align-items: center;
    justify-content: center;
}

.author-verification-banner{
    background: #F8F8F8;
    border-radius: 10px;
    margin: 16px auto;
    padding:16px 18px;
    width: 100%;
}

.eq-flex{
  width:120px;
}

.author-profile-btn-1{
    background: #3E7EFF;
    border-radius: 10px;
    padding: 10px 16px;
    width: 400px;
    display: block;
    margin: 20px auto;
}

.author-profile-btn-2{
    color: #3E7EFF;
    border-radius: 30px;
    padding: 10px 24px;
    display: block;
    margin: 16px auto 0px;
    width: 100%;
    border: 2px solid #3E7EFF;
    background-color: white;
}

.author-profile-divider-1{
    border-bottom:3px solid #B6B6B6;
    width: 100%;
    margin: 40px 0px 30px 0px;
}

.no-posts-div{
    margin: 70px auto;
}

.author-profile-posts-list-div{
    display: block;
    width: 70%;
    margin: 0px auto;
    background-color:#F9F9F9;
    padding: 40px;
}

@media (min-width: 900px){
.author-d-back-btn{
    position: absolute;
    top: 40px;
    left: 40px;
}

.author-verification-banner{
    width: 400px;
}

.author-more-opt{
    position: absolute;
    top: 40px;
    right: 110px;
    background: #FFFFFF;
    box-shadow: 12px 12px 40px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 10px;
}

.author-profile-sk-circle{
    height: 120px;
    width: 120px;
    display: block;
    margin: 0px auto;
}

.author-d-more-btn{
    position: absolute;
    top: 40px;
    right: 40px;
}
}
@media(max-width: 600px){
    .author-profile-main-div{
        padding: 10px 10px;
        margin-right: 0px;
        min-height: 90vh;
        width: 100%;
    }

    .author-profile-sk-posts{
       width: 100%;
   }

    .author-profile-posts-list-div{
        display: block;
        width: 100%;
        margin: 0px auto;
        padding: 10px;
    }

    .author-more-opt{
        position: absolute;
        top: 40px;
        right: 20px;
        background: #FFFFFF;
        box-shadow: 12px 12px 40px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        padding: 10px;
    }
    

    .eq-flex{
        flex: 1;
    }

    .author-profile-info-div{
        padding: 0px 10px;
    }
    
    .author-profile-btn-1{
        border-radius: 30px;
        padding: 12px;
        width: 100%;
        display: block;
        margin: 20px auto;
    }
    
}