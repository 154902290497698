

.back-btn-main-div{
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 1.5em;
   top: 0em;
   margin-bottom: 1em;
   padding: 10px 15px;
   background-color: $white;
}

.back-icon{
    height: 30px;
    width: 30px;
}

.login-modal{
    padding: 20px 0px;
}

.back-btn-div{
    display: flex;
    align-items: center;
}

.back-icon{
    height: 30px;
    width: 30px;
    margin-right: 15px;
}

.ap-font-1{
    font: normal normal 600 0.8em $poppins;
    color: #232629;
}

.ap-font-2{
    font: normal normal 600 0.7em $poppins;
    color: #0F0F2D;
}

.ap-img-wrapper{
    background-color: #000000;
    position: relative;
    .ap-img{
        min-height: 30vh;
        width: 100%;
        position: relative;
        max-height: 80vh;
        object-fit: contain;
    }
}

.posts-list-div{
     display: grid;
     grid-template-columns: 100%;
  //   grid-template-rows: repeat(auto-fill, 50vh);
     row-gap:10px;
}

.post-detail-card{
    background-color: $white;
    min-height: 40vh;
    padding-bottom: 10px;
}

.post-detail-header-div{
     padding: 10px 15px;
     position: relative;
}

.post-detail-author-image{
     height: 40px;
     width: 40px;
     margin-right: 10px;
     border-radius: 50%;
     object-fit: cover;
}

.pd-name-div{
    display: flex;
    align-items: center;
}

.pd-font-1{
    font: normal normal 500 0.9em $poppins;
    letter-spacing: 0px;
    color: #232629;
}

.pd-font-2{
    font: normal normal normal 0.8em $lato;
    letter-spacing: 0px;
    color: #0F0F2D;
}

.pd-font-3{
    font: normal normal bold 0.8em $lato;
    letter-spacing: 0px;
    color: #A7AEB4;
}

.pd-font-5{
    font: normal normal bold 0.7em $lato;
    letter-spacing: 0px;
    color: #0F0F2D;
}


.pd-font-4{
    font: normal normal 400 1em $poppins;
    letter-spacing: 0px;
    color: #232629;
}

.pd-font-6{
    font: normal normal medium 18px Lato;
    letter-spacing: 0px;
    color: #0F0F2D;
    opacity: 1;
}

.pd-font-7{
    font: normal normal normal 14px $poppins;
    color: #232629;
}

.verified-icon{
    height: 15px;
    width: 15px;
    margin-left: 5px;
}

.pd-vertical-div{
    position: absolute;
    right: 0px;
    top: 10px;
}

.pd-likes-count{
   // display:none;
}

.load-comments{
   margin-top: 1em;
}

.pd-story{
  //  max-width: 100%;
    margin-top: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
   -webkit-line-clamp: 6; /* number of lines to show */
   -webkit-box-orient: vertical;
    max-width: 100%;
    overflow: hidden;
    white-space: wrap;
 //   word-wrap: break-word;
}

.pd-story-max{
    margin-top: 10px;
    height: auto;
    max-width: 100%;
    overflow: hidden;
    white-space: wrap;
    word-wrap: break-word;
}

.pd-circle-div{
    display: inline-block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: #A7AEB4;
    margin: 0px 6px;
}

.pd-views-div{
    margin-top: 8px;
    display: flex;
}

.pd-v-flex-1{
    flex: 1;
}

.pd-v-flex-2{
    flex: 1;
}

.pd-v-flex-3{
    flex: 1;
}

.divide-margin{
    border-bottom: 2px solid #F4F3F8;
    margin: 0px 15px;
}

.pd-title-div{
    padding: 10px 15px;
}

.pd-interactions-div{
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
}

.pd-like-share-comment-div{
    display: flex;
}

.interaction-icon{
    height: 30px;
    width: 30px;
}

.interaction-rm{
    position: relative;
    margin-right: 20px;
}

.share-number-div{
    height: 20px;
    min-width: 20px;
    border-radius: 50%;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $white;
    position: absolute;
    left: 24px;
    top: -8px;
}

.reactions-popup-div{
    position: absolute;
    top: -52px;
    left: 0px;
}

.comment-name-text{
    font: normal normal 400 13px Poppins;
    color: #0F0F2D;
}

.comment-desc-text{
    font: normal normal medium 11px Lato;
    color: #0F0F2D;
}

.comments-list-div{
  margin: 0px 15px;
  margin-top: 1.8em;
}

.comment-smiley{
   background: transparent linear-gradient(180deg, #EF9E0B 0%, #D67100 100%) 0% 0% no-repeat padding-box;
}

.add-comment-div{
   width: 100%;
   position: relative;
   margin-bottom: 0.8em;
}

.comment-image{
   height: 30px;
   width: 30px;
   border-radius: 50%;
   margin-right: 10px;
   object-fit: cover;
}

.comment-input{
   border: 1px solid #979EA4;
   border-radius: 35px;
   height: 32px;
   min-width: 100%;
 //  width: calc(100% - 70px);
   padding: 5px 12px;
}

.comment-post-btn{
   background: #3E7EFF 0% 0% no-repeat padding-box;
   border-radius: 19px;
   margin-top: 15px;
   color: $white;
   padding: 5px 15px;
   border: none;
   font: normal normal medium 16px Poppins;
}

.comment-details-div{
    margin-top: 2em;
}

.comment-details-list-wrapper{
   margin-top: 1em;
}

.modal-title-text{
    font: normal normal 550 14px Poppins;
    color: #0F0F2D;
}

.cust-modal-content{
    padding: 15px 15px;
}

.modal-close-icon{
    height: 30px;
    width: 30px;
}

.cust-modal{
    border-radius: 20px;
    margin-bottom: 1em;
} 

.new-post-tags-div{
    margin: 16px 0px 12px 0px;
}

@media (max-width: 500px){
    .all-posts-div{
        position: absolute;
        top: -120px;
        z-index:200;
        background-color: $white;
        min-width: 100vw;
        min-height: 100vh;
    }   
    
    .new-post-tags-div{
        margin: 12px 0px 10px 0px;
    }

    .single-post-div{
        position: absolute;
        top: -60px;
        z-index:200;
        background-color: $white;
        width: 100vw;
        min-height: 100vh;
    }

    .single-post-div-card{
        border-radius: 0px;
        box-shadow: none;
    }
}

@media (min-width: 1200px) {

    .all-posts-div{
        position: relative;
        z-index: 0;
        background-color: none;
        top: 0px;
    }

    .login-modal{
        padding: 20px;
    }

    .cust-modal-content{
        padding: 30px 40px;
    }

    .back-icon{
        height: 40px;
        width: 40px;
        margin-right: 30px;
    }

    .back-btn-main-div{
        margin-bottom: 1.2em;
        background-color: #F4F3F8;
    }

    .back-icon{
        height: 35px;
        width: 35px;
    }

    .share-number-div{
        top: -10px;
        left: 21px;
    }

    .pd-font-1{
        font-size: 1em;
    }

    .pd-font-2{
        font-size: 0.8em;
    }

    .pd-font-3{
        font-size: 0.9em;
    }

    .pd-font-4{
        font-size: 1.1em;
    }

    .pd-font-5{
       font-size: 1em;
    }

    .pd-font-6{
         font-size: 1.1em;
    }

    .pd-font-7{
        font-size: 0.9em;
    }

    .modal-title-text{
       font-size: 1.4em;
    }

    .ap-font-1{
        font-size: 1.2em;
    }
    
    .ap-font-2{
        font-size: 1em;
    }

    .pd-story{
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
       -webkit-line-clamp: 6; /* number of lines to show */
       -webkit-box-orient: vertical;
        max-width: 100%;
        overflow: hidden;
        white-space: wrap;
    //   max-width: 100%;
    }

    .pd-story-max{
        margin-top: 10px;
        max-width: 100%;
        overflow: hidden;
        white-space: wrap;
    }
    
    .pd-read-more{
        margin-top: 10px;
    }

    .post-detail-card{
        padding: 20px 40px;
    }

    .post-detail-author-image{
        height: 3em;
        width: 3em;
        border-radius: 50%;
        margin-right: 20px;
   }

   .post-detail-header-div{
    padding: 0px 0px;
    position: relative;
    margin-bottom: 20px;
    }

    .ap-img-wrapper{
        position: relative;
        .ap-img{
            min-height: 400px;  
            max-width: 100%;
        }
    }

    .verified-icon{
        height: 0.9em;
        width: 0.9em;
        margin-left: 10px;
    }

    .pd-views-div{
        margin-top: 0.9em;
        margin-bottom: 0.5em;
    }

    .pd-title-div{
        padding: 20px 0px 30px 0px;
    }

    .divide-margin{
        margin: 0px 0px;
    }

    .interaction-icon{
        height: 32px;
        width: 32px;
    }

    .pd-interactions-div{
        position: relative;
        padding: 0px 0px;
    }

    .interaction-rm{
        position: relative;
        margin-right: 3em;
    }

    .like-interaction-empty::after{
        content: "Like";
        font: normal normal normal 14px $poppins;
        margin-left: 5px;
    }

    .comment-i::after{
        content: "Comment";
        font: normal normal normal 14px $poppins;
        margin-left: 5px;
    }

    .share-i::after{
        content: "Share";
        font: normal normal normal 14px $poppins;
        margin-left: 5px;
    }

    .back-btn-main-div{
       // position: relative;
        margin-top: 0.3em;
        top: calc( $topbarheight);
        padding: 10px 0px;
        background-color: #F4F3F8;
    }

     .post-detail-card{
        background-color: $white;
        min-height: 20vh;
        padding-bottom: 10px;
        border-radius: 10px;
    }

    .posts-list-div{
        row-gap: 20px;
   }

    .reactions-popup-div{
        top: -55px;
    }

    .comment-input{
        height:40px;
        padding: 12px 30px; 
    }

    .comment-image{
        height: 35px;
        width: 35px;
        margin-right: 20px;
    }

    .comment-post-btn{
        margin-top: 20px;
        color: $white;
        padding: 6px 20px;
        font: normal normal medium 20px/9px Poppins;
     }
     
     .comment-details-div{
         margin-top: 2em;
     }
     
     .comment-details-list-wrapper{
        margin-top: 1em;
     }

     .comments-list-div{
        margin: 0px;
        margin-top: 3em;
    }

    .comment-name-text{
        font-size: 16px;
    }
    
    .comment-desc-text{
        font-size: 15px;
    }

    .pd-likes-count{
        display: inline;
    }
}
