.category-list-div{
    display: block;
    background-color: $white;
    position: relative;
}

.category-list-back-btn{
    display: block;
    background-color: $white;
}

.category-back-title{
    top: 0px;
    margin-top: 0px;
    box-shadow: none;
    background-color: $white;
    padding: 10px 10px;
}

.category-back-icon{
    height: 50px;
    width: 40px;
}

.category-list-image{
    object-fit: contain;
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.category-cart-btn{
    padding: 6px 12px;
    border: 2px solid $grey;
    margin-top: 1em;
    border-radius: 29px;
    margin-left: auto;
    margin-right: auto;
    background-color: $white;
    color: $black;
}

.category-list-image-wrapper{
    height: 170px;
    width: 100%;
    position: relative;
    border-radius: 10px;
}

.category-list-product-card{
    display: block;
    padding: 0px 0px;
    max-width: 100%;
    border-radius: 10px;
}

.category-list-product-outer-card{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 10px;
        position: relative;
        border: 1px solid #D5D5D5;
}

.category-cart-icon{
    position: relative;
   // bottom: 20px;
  //  right: 15px;
    z-index: 11;
    display: flex;
    justify-content: flex-end;
    margin: 20px auto;
    width: auto;
}

.cat-btn{
    margin: 0px auto;
    display: block;
    border-radius: 30px;
    padding: 10px 20px;
    margin-bottom: 20px;
    width: 38%;
}

.category-products-div{
    padding-bottom: 20px;    
}

.category-list-product-outer-active-card{
   // box-shadow: 5px 5px 5px $blue;
   box-shadow: none;
   border: 3px solid $blue;
}

.category-title-5{
    margin-left: 30px;
}

@media  (max-width: 600px){

    .category-title-5{
        margin-left: 15px;
    }

    .category-list-div{
    position: fixed;
  //  top:calc(-1 * ($topbarheight + 35px));
    display: block;
    z-index:100;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    padding-bottom: 0px;
    top: 0px;
    }

    .cat-btn{
        padding: 10px 20px;
        border-radius: 0px;
        margin-top: 0px;
        width: 100%;
        margin-bottom: 0px;
    }

    .category-list-image-wrapper{
        height: 150px;
        flex: 2;
        flex-basis: 190px;
        position: relative;
        border-radius: 10px;
    }

    .category-back-title{
        top: 0px;
        margin-top: 0px;
        padding: 15px;
        box-shadow: 0px 3px 6px #00000029;
    }

    .category-list-product-outer-card{
        border: none;
    }

    .category-cart-icon{
        position: fixed;
        bottom: 0px;
        left: 0px;
        z-index: 11;
        display: block;
        right: 0px;
        display: block;
        width: 100%;
        margin-bottom: 0px;
    }    

    .category-list-product-card{
        display: flex;
        flex-grow: row wrap;
        padding: 12px 15px;
        gap: 10px;
        max-width: 100%;
        box-shadow: 0px 3px 6px #00000029;
        border: none;
        border-radius: 10px;
    }

    .category-trash-icon{
        height: 36px;
        width: 36px;
        background-color: $red;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    .category-list-product-outer-active-card{
        // box-shadow: 5px 5px 5px $blue;
        box-shadow: none;
        border: 3px solid $blue;
     }

    .category-products-div{
       grid-template-columns: 1fr;
       gap: 10px;
    }
}