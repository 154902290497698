.food-main-div{
    position: relative;
    width: 100%;
    display: block;
    max-width: 100%;
}

.food-main-card{
    background-color: $white;
   // min-height: 100vh;
    margin-top: 2em;
    margin-right: 1.5em;
}

.food-category-image{
    height: 80px;
    width: 80px;
    margin-bottom: 12px;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.food-restaurants-div{
   padding: 0px 15px;
   display: block;
}

.food-download-app-div{
    background: #0F0F2D 0% 0% no-repeat padding-box;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 100px;
}

.food-restaurant-card{
    margin-bottom: 1.3em;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #32335E26;
    border: 1px solid #D5D5D5;
    border-radius: 10px;
    opacity: 1;
    position: relative;
}

.food-restaurant-topslide-card{
    margin-bottom: 1.3em;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 10px;
}

.food-restaurant-image{
    height: 200px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.shop-food-restaurant-image{
    height: 150px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.food-restaurant-topslide-div{
    border-radius: 0px;
    width: 100%;
}

.food-restaurant-topslide{
    position: relative;
    width: 100%;
}

.food-font-1{
    padding: 1.2em 1em 1.5em 1.5em;
    font-family: 'poppins-bold';
    font-size: 16px;
}

.food-font-2{
    font-family: 'poppins-medium';
    font-size: 16px;
}

.food-font-3{
    font-family: 'lato-bold';
    font-size: 12px;
}

.food-font-4{
    font-family: 'lato-regular';
    font-weight: 300;
    font-size: 12px;
    color: #979EA4;
}

.food-font-5{
    color: #3E7EFF;
    font-size: 14px;
    font-family: 'poppins-semibold';
}

.food-font-6{
    font-size: 18px;
    font-family: 'poppins-medium';
    color: $white;
}

.restaurant-safe-tag{
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 6px 10px;
    border-radius: 8px 0px 10px 0px;
    background-color: $white;
    z-index: 2;
}

.restaurant-name-info{
   padding: 10px 15px 20px 15px;
}

.food-location-div{
    display: flex;
    //width: calc( 100% - 350px);
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    padding:0px 20px;
    position: relative;
}

.food-search-location-div{
    max-width: 55%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.restaurant-cuisines-text{
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-all;
  //  white-space: nowrap;
   // max-width: 120px;
}

.food-search-bar-div{
    display: block;
    //width: 100%;
    width: 350px;
}

.food-category-div{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: rgb(231, 243, 247);
    padding: 20px 15px;
    gap: 15px;
    justify-items: center;
}

.food-google-play-icon{
    height: 50px;
    margin-right: 50px;
}

.food-appstore-icon{
    height: 50px;
}

@media (max-width: 600px){

    .food-main-card{
        margin-right: 0px;
        margin-top: 1.3em;
        background-color: transparent;
    }

    .food-font-1{
        font-size: 13px;
    }

    .food-font-2{
        font-size: 14px;
    }

    .food-download-app-div{
        display: none;
    }

    .food-search-location-div{
        margin-top: 1em;
        margin-bottom: 1em;
        max-width: 100%;
        display: block;
    }   

    .food-search-bar-div{
        display: block;
        width: 90%;
        margin: 0px auto;
    }
    
    .food-location-div{
        display: none;
    }

    .food-category-div{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-bottom: 1.5em;
  }
    
}

@media ( min-width: 600px) and (max-width: 1200px){

    .food-restaurants-div{
        padding: 0px 30px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        max-width: 1200px;
     }

     .food-download-app-div{
         display: none;
     }
}

@media (min-width: 600px) and (max-width: 980px){

    .food-search-location-div{
        margin-top: 1em;
        margin-bottom: 1em;
        max-width: 100%;
        display: block;
    }   

    .food-search-bar-div{
        display: block;
        //width: 100%;
        margin: 0px auto;
        width: 600px;
    }

    .food-location-div{
        display: none;
    }

    .food-main-card{
        margin-right: 0em;
        border-radius: 10px;
        margin-right: 0px;
    }
}

@media (min-width: 980px) and (max-width: 1200px){
    .food-search-location-div{
        max-width: 80%;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .food-main-card{
        margin-right: 0em;
        border-radius: 10px;
        margin-right: 10px;
    }
}


@media ( min-width: 1200px){

    .food-font-2{
        font-size: 15px;
    }

    .food-font-6{
        font-size: 22px;
    }

    .food-font-3,.food-font-4, .food-font-5{
        font-size: 18px;
    }

    .food-category-item{
        padding: 10px 15px;
        width: 100%;
    }

    .food-category-item:hover{
        background-color: $white;
        border-radius: 6px;
    }

    .food-category-div{
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
          margin-bottom: 1.5em;
    }

    .food-main-card{
        border-radius: 10px;
        padding-bottom: 1px;
    }

    .food-font-1{
        font-size: 18px;
    }

    .food-restaurant-card{
        border: none;
    } 

    .shop-food-restaurant-image{
        height: 170px;
    }

    .food-restaurants-div{
        padding: 0px 30px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        max-width: 1200px;
     }

     .food-download-app-div{
        position: relative;
        margin: 20px 30px 30px 30px;  
        backdrop-filter: blur(10px);
     }
     
    .border-gradient {
       border: 10px solid;
       border-image-slice: 1;
       border-width: 3px;
       border-radius: 5px;
     }
  
     .border-gradient-purple {
        border-image-source: linear-gradient(98deg, #1B00E8 0%, #FF0000 100%);
        backdrop-filter: blur(30px);
        border-radius: 5px;
      }
}
