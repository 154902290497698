.new-post-main-div{
    display: block;
    min-height: 100vh;
    background-color: white;
    position: relative;
}

.modal-title-div{
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;    
    box-shadow: 0px 3px 6px #32335E26;
}

.modal-content-div{
    padding: 40px 40px;
}

.post-form-input-div{
    margin-bottom: 25px;
}


.news-post-red-close-icon{
    height: 20px;
    width: 20px;
    cursor: pointer;
}
.news-post-img-close{
    position: absolute;
    top: -10px;
    right: -10px
}

.modal-close-img{
    display: none;
}

.select-gallery-div{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    position: relative;
}

.new-post-upload-btn{
    background: #cfdaf1 0% 0% no-repeat padding-box;
    border-radius: 29px;
    padding: 10px 20px;
    color: #3E7EFF;
    margin: 0px auto;
    margin-top: 3em;
    display: block;
}

.new-post-gallery-img{
    display: block;
    margin: 0px auto;
    height: 50px;
    width: 50px;
    margin-bottom: 1em;
}

.new-post-gallery-img-2{
    margin: 0px auto;
    height: 25px;
    width: 25px;
    margin-right: 10px;
}

.modal-title-1{
    font: poppins-semibold;
    font-size: 16px;
    color: #0F0F2D;
    text-transform: capitalize;
}

.modal-title-2{
    font: poppins-medium;
    font-size: 14px;
    color: #232629;
}

.modal-title-3{
    font: poppins-light;
    font-size: 14px;
}

.modal-title-4{
    font-family: Poppins;
    font-size: 21px;
    font-weight: 600px;
}

.modal-back-img{
    display: none;
}

.newpost-image-preview-div{
   display: flex;
   flex-flow:row wrap;
   max-width: 100%;
   gap: 20px;
}

.newpost-preview-img{
    position: relative;
    background: transparent 0% 0% no-repeat padding-box;
    background-position: center;
    border-radius: 10px;
    background-size: contain;
    opacity: 1;
    width: 130px;
    height: 100px;
    flex: 1 130px;
    background-color: black;
}
.new-post-title-2{
    
}

@media (max-width: 600px){

    .modal-title-div{
        padding: 10px 10px;
    }

    .news-post-red-close-icon{
        height: 20px;
        width: 20px;
    }

    .select-gallery-div{
        min-height: calc(90vh - $topbarheight - 100px);
    }

    .new-post-main-div{
        min-height: 100vh;
    }

    .post-form-input-div{
        margin-bottom: 21px;
    }
    
    .newpost-preview-img{
        height: 150px;
        width: 150px;
        background-color: black;
        flex: 1 120px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-back-img{
        display: inline-block;
        height: 24px;
        width: 24px;
        margin-right: 10px;
    }

    .newpost-image-preview-div{
        gap: 10px;
     }

     .modal-content-div{
        padding: 30px 10px;
     }
}

@media (min-width: 600px) and (max-width: 1200px) {
    
}

@media (min-width: 1200px){

    .modal-close-img{
        display: block;
        height: 30px;
        width: 30px;
    }

    .modal-title-1{
        font-size: 20px;
    }

    .modal-title-2{
        font-size: 16px;
    }

    .modal-title-3{
        font-size: 16px;
    }


    .new-post-main-div{
        min-height: 50vh;
    }
}