.cart-outer-div{
    background-color: $white;
    height: 100%;
    display: block;
    position: relative;
    z-index: 10;
}

.cart-product-card{
    display: flex;
    column-gap: 30px;
}


.cart-product-card{
    padding: 0px 15px;
}

.cart-image{
   height: 100px;
   width: 130px;
   border-radius: 10px;
   object-fit: contain;
}

.my-cart-div{
   // width: 80%;
}

.cart-product-discard-icon{
    position: absolute;
    left: -10px;
    top: -10px;
}


.cart-empty-div{
    height: calc(100vh - $topbarheight - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 600px){

    .cart-outer-div{
        display: block;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        overflow-y: scroll;
        height:100vh;
        z-index: 100;
    }

    .my-cart-div{
        width: 100%;
    }

    .cart-product-card{
        padding: 0px 5px;
    }

    .cart-product-card{
        column-gap: 21px;
    }

    .cart-image{
        height: 100px;
        width: 120px;
        border-radius: 10px;
     }
    
}

@media (min-width: 600px){
    .my-cart-outer-div{
        margin-right: 20px;
        border-radius: 10px;    
    }
}