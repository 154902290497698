
.timeline-circle{
    display: none;
}

.news-posts-list-container{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(25vh,auto) minmax(25vh,auto) minmax(25vh,auto) minmax(25vh,auto);
    gap: 25px;
    column-gap: 0px;
    padding-left: 10px;
    position: relative;
    border: 1px solid blue;
 }

 .posts-category-list-card-outer-div{
    // max-width: 100vw;
     position: relative;
     animation: slide-in 1s ease-in;
 }

 @keyframes slide-in {
     0%{
        transform: scale(0.8);
     }
     100%{
        transform: scale(1);
     }
 }

 .np-rwc-adv-image-div{
    margin-right: 10px;
    margin-left: 10px;
}

 .mob-adv-item{
    margin-bottom: 14px;
}
 

.mr-5{
   margin-right: 5px;
}

.ml-5{
    margin-left: 5px;
}

.loc-black-icon{
    width: 25px;
    height: 25px;
 }  
 
 .arrow-right-icon{
    height: 24px;
    width: 24px;
 }

 .post-image{
    position: relative;
    width: 100%;
    height: 100%;
  //  height: 150px;
  //  max-height: 150px;
    border-radius: 5px;
    object-fit: cover;  
}

.post-title-text{
  //  font: normal normal 600 16px/23px $poppins;
    color: $black;
    font-family: 'poppins-medium';
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
}

.post-timeline-text{
    font: normal normal 500 16px/25px $poppins;
    color: #979EA4;
    font-size: 12px;
    line-height: 1.3em;
}

.post-author-img{
    height:35px;
    width: 35px;
}

.np-rwc-adv-image{
    border-radius: 10px;
}

.next-adv-arrow-div{
    display: none;
}

.post-author-img-wrapper{
    display: none;
}

.next-arrow-div{
   display: none;
}

.prev-arrow-div{
    display: none;
 }

.posts-category-list-card{
    background: #FFFFFF 0% 0% no-repeat padding-box;    
 //   border-radius: 10px;
    padding:10px 0px 10px 10px;
    display: block;
    position: relative;
    min-height: 200px;
    margin: 15px 0px;
}

.posts-category-adv-card{
    display: block;
    position: relative;
    max-height: 90vh;
}

.posts-list-font-1{
  //  font: normal normal 600 16px $poppins;
    font-family: 'poppins-bold';
    font-size: 16px;
}

.posts-list-font-2{
    font: normal normal 14px Poppins;
    color: #3E7EFF;
    font-size: 14px;
}

.post-card-top{
    padding-right: 15px;
    padding-left:10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.news-posts-carousel-mobile-div{
    display: block;
    position: relative;
    width: 100%;
    overflow: scroll;
 }

 .news-posts-carousel-div{
    position: relative;
    margin-top: 10px;
    display: block;
    overflow: hidden;
 }

 .np-adv-carousel-div{
     margin-top: 0px;
     padding-top: 0px;
 }

 
 .news-posts-carousel-div::-webkit-scrollbar {
    display: none;
  }
   
  .news-posts-carousel-div::-webkit-scrollbar-track {
  //  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  display: none;
  }
   
  .news-posts-carousel-div::-webkit-scrollbar-thumb {
  //  background-color: darkgrey;
  //  outline: 1px solid slategrey;
  display: none;
  }

 .news-posts-mobile-carousel-div{
     display: block;
     position: relative;
     max-width: 100%;
     overflow: hidden;
     height: 100%;
 }

 .news-posts-mobile-carousel{
     display: flex;
     width: 100vw;
     overflow: scroll;
     height: 100%;
 }

 .news-posts-mobile-card{
     display: block;
     position: relative;
     min-width: 40vw;
     margin-right: 10px;
     max-height: 100%;
 }

 .news-posts-mobile-image{
    width:100%;
    height: 40%;
    object-fit: cover;
} 

div.news-post-ind-card{
    margin-right: 10px
 }

 .post-text-wrapper-div{
    padding: 0px 5px;
 }
/*
.news-posts-carousel-div{
    display: none;
}*/

.video-btn-div{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    background: transparent;
    z-index: 20;
}

.video-btn{
    height: 40px;
    width: 40px;
}

.post-image-div{
     position: relative;
     background-color: #F4F3F8;
   //  min-height: 100px;
   //  min-width: 100%;
 }


 @media (max-width: 600px) {


    .post-image-div{
        position: relative;
        width: 100%;
        overflow: hidden;
        background-color: #F4F3F8;
        padding-top: 69%;
      //  height: 69%;
      //  max-height: 69%;
      //  display: flex;
      //  justify-content: center;
      //  align-items: center;
    }

    .post-image{
        position: absolute;
        top: 0px;
      //  width: 100%;
      //  height: auto;
      //  height: 150px;
      //  max-height: 150px;
        border-radius: 5px;
        object-fit: contain;  
        display: block;
    }
    
  /*  .post-image-div::before{
      content:"";
      display: block;
      padding-top: 56.25%;
    }
    */

    .post-image-content{
       position: absolute;
       top:0;
       left: 0;
       bottom: 0;
       right: 0;
       background-position: center;
       background-size: 100% 100%;
       background-size: cover;
    }
    
    
  /*  .post-image{
        position: relative;
        width: 100%;
        height: 100%;
      //  height: 200px;
      //  max-height: 12vh;
        object-fit: cover;  
     } 
    
      .post-image-div{
        position: relative;
        background-color: #F4F3F8;
        height: 100px;
     //   max-height: 12vh;
     //   min-height: 12vh;
    //    min-width: 100%;
    }
      */

    .post-card-top-name{
        max-width: 70%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
 }

 




@media (min-width: 600px) {
    .video-btn{
        height: 60px;
        width: 60px;
    }

    .timeline-circle{
        display: block;
    }

    .news-posts-mobile-carousel-div{
        display: none;
    }


    .posts-category-list-card-outer-div{
      //  max-width: ;
     }

.news-posts-list-container{
   display: grid;
   grid-template-columns: 1fr;
//   grid-template-rows: minmax(40vh,auto) minmax(40vh,auto) minmax(40vh,auto);
   grid-template-rows: minmax(150px,auto) minmax(150px,auto) minmax(150px,auto) minmax(150px,auto);
   gap: 25px;
   position: relative;
   width: 100%;
   border: 1px solid blue;
  // margin-bottom: 0.2em;
}

/*
.post-image-div{
   // min-height: 150px;
    width: 100%;
    height: 100%;
    max-height: 200px;
    overflow: hidden;
    border: 1px solid red;
}*/

.post-image-div{
     position: relative;
     width: 100%;
     overflow: hidden;
 }

 /*.post-image-div::before{
   content:"";
   display: block;
   padding-top: 56.25%;
} */

.post-image-content{
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-size: 100% 100%;
    background-size: cover;
}



 .news-posts-carousel-div{
     display: block;
     position: relative;
     overflow: scroll;
     margin-top: 10px;
 }

 div.news-post-ind-card{
    margin-left: 10px;
    margin-right: 0px;
 }

 .post-author-img-wrapper{
    display: block;
    height:45px;
    width: 45px;
}

.post-author-img{
    display: block;
    min-height:35px;
    min-width: 35px;
    max-width: 35px;
    border-radius: 50%;
    object-fit: cover;
}

.post-footer-div{
    position: relative;
    display: flex;
    max-width: 100%;
}

.next-arrow-icon{
    height: 50px;
    width: 50px;
}

.next-arrow-div{
    display: block;
}

.next-adv-arrow-div{
    display: block;
    position: absolute;
    top: calc( 50% - 25px );
    right: 0px;
    z-index: 5;
}

.prev-arrow-div{
    display: block;
    position: absolute;
    top: 50%;
    left: 20px;
    z-index: 5;
}

.np-rwc-adv-image-div{
    margin-right: 10px;
}

.posts-category-adv-card{
   // min-height: 500px;
}



.opacity-div{
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 50px;
    height: 100%;
    border-radius: 0px 10px 10px 0px;
    z-index:3;
    background: transparent linear-gradient(270deg, #FFFFFF 0%, #FFFFFF 17%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
}

.opacity-adv-div{
    opacity: 0.8;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 20%;
    min-height: 80%;
    z-index:3;
    background: transparent linear-gradient(270deg, #FFFFFF 0%, #FFFFFF 17%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
}

  .left-opacity-div{
    opacity: 0.8;
    position: absolute;
    top: 18%;
    bottom: 0px;
    left: 0px;
    width: 20%;
    min-height: 80%;
    border-radius: 10px;
    z-index:3;
  //  background-color: $white;
    background: transparent linear-gradient(270deg, #FFFFFF00 0%, #FFFFFF 80%,#FFFFFF 100%) 0% 0% no-repeat padding-box;
  }

  .post-timeline-text{
    font: normal normal 300 13px $poppins;
    color: #979EA4;
    display: flex;
    align-items: center;
}

.post-title-text{
  //  font-family: 'poppins-medium';
    font-family: $poppins;
    font-size: 15px;
    font-weight: 550;
    box-sizing: border-box;
    overflow: hidden;
}

.posts-list-font-1{
    font-size: 18px;
}

.posts-list-font-2{
    font-size: 14px;
}

.post-card-top{
    padding-left: 10px;
    padding-right: 40px;
 }

 .posts-category-list-card{
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 20px;
    min-height: 280px;
    margin: 20px 0px;
 }

 .loc-black-icon{
    width: 35px;
    height: 35px;
 } 

 .mob-adv-item{
    display: none;
}

.post-text-wrapper-div{
    padding:0px;
    width: calc(100% - 45px);
}

.timeline-circle{
    height: 10px;
    line-height: initial;
    width: 10px;
    border-radius: 100%;
    display: block;
    background-color: #979EA4;
    margin: 2px 5px;
}
}


/*
.post-image-div{
    position: relative;
    width: 100%;
    overflow: hidden;
} */

/*.post-image-div::before{
  content:"";
  display: block;
  padding-top: 69.25%;
} */

.post-image-content{
   position: absolute;
   top:0;
   left: 0;
   bottom: 0;
   right: 0;
   background-position: center;
   background-size: 100% 100%;
   background-size: cover;
}

.post-image-div{
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: #F4F3F8;
    padding-top: 69%;
    border-radius: 5px;
  //  height: 69%;
  //  max-height: 69%;
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
}

.post-image-blur{
    z-index: 1;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    filter: blur(10px);
    border-radius: 5px;
}
.post-image{
    z-index: 10;
    position: absolute;
    top: 0px;
  //  width: 100%;
  //  height: auto;
  //  height: 150px;
  //  max-height: 150px;
    border-radius: 5px;
    object-fit: contain;  
    display: block;
}

