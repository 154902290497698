
@import "./variables.scss";
@import "./fonts.scss";
@import "./typography.scss";
@import "./common-styles.scss";

@import "./custom/structure/topbar.scss"; 
@import "./custom//structure/layout.scss";
@import "./custom/structure/sidebar.scss";
@import "./custom/structure/content-area.scss";

@import "./custom/shared/search.scss";

@import "./custom/pages/news/news-home.scss";
@import "./custom/pages/news/topslide-people-page.scss";
@import "./custom/pages/news/posts-page.scss";
@import "./custom/pages/news/all-posts.scss";
@import "./custom/pages/news/new-post-card.scss";
@import "./custom/pages/news/followers-list.scss";
@import "./custom/pages/news/featured-stories.scss";

@import "./custom/pages/skeleton/posts-skeleton.scss";
@import "./custom/pages/skeleton/all-posts.scss";
@import "./custom/pages/skeleton/food.scss";

@import "./custom/account/login.scss";

@import "./custom/pages/common/profile-info.scss";
@import "./custom/pages/common/verify-otp.scss";
@import "./custom/pages/common/change-password.scss";
@import "./custom/pages/common/share.scss";
@import "./custom/pages/common/common.scss";
@import "./custom/pages/common/checkout.scss";
@import "./custom/pages/common/success.scss";
@import "./custom/pages/common/address-list.scss";
@import "./custom/pages/common/cart-minimum.scss";
@import "./custom/pages/common/cart.scss";
@import "./custom/pages/common/new-post.scss";
@import "./custom/pages/common/notification.scss";
@import "./custom/pages/common/like-reactions.scss";

@import "./custom//pages/food/food-home.scss";
@import "./custom/pages/food/food-cart.scss";

@import "./custom//pages/shop/shop-home.scss";
@import "./custom/pages/shop/category-list.scss";
@import "./custom/pages/shop/product-detail.scss";

@import "./custom/pages/author/author-profile.scss";

.margin-right-10{
    margin-right: 10px;
}

.mt-px-70{
    margin-top: 70px;
}

.text-danger{
    font: normal normal normal 15px Poppins;
    color: $red;
}

.text-success{
    font: normal normal normal 15px Poppins;
    color: $green;
}

.mt-px-40{
    margin-top: 40px;
}

.ml-px-25{
    margin-left: 25px;
}

.margin-left-10{
    margin-left: 10px;
}

.margin-top-10{
    margin-top: 10px;
}

.mb-em-1{
    margin-bottom: 1em;
}

.mb-px-10{
    margin-bottom: 10px;
}

.mb-px-20{
    margin-bottom: 20px;
}

.mr-px-10{
    margin-right: 10px;
}

.mb-em-3{
    margin-bottom: 3em;
}

.mb-em-2{
    margin-bottom: 2em;
}

.mt-em-1{
    margin-top: 1em;
}

.mb-37{
    margin-bottom: 2em;
}

.mr-20{
    margin-right: 20px;
}

.mr-15{
    margin-right: 1em;
}

.flex-d{
    display: flex;
}

.flex-1{
    flex:1;
}

.flex-2{
    flex: 2;
}

.flex-1p5{
    flex: 1.5;
}

.flex-3{
    flex: 3;
}

.text-center{
    text-align: center;
}

.align-center{
    align-items: center;
}

.justify-center{
    justify-content: center;
}

.space-between{
    justify-content: space-between;
}

.desktop-only{
    display: none;
}

.text-underline{
   text-decoration: underline;
}

.text-black{
    color: #0F0F2D;
}

.text-blue{
    color: #3E7EFF;
}

.no-btn{
    border: none;
    outline: none;
    background-color: none;
}

.no-btn:focus, .no-btn:active{
    border: none;
    outline: none;
    background-color: none;
}

.text-danger-2{
    font: normal normal bold 15px Poppins;
    color: #FF5858;
}

.f-16{
    font-size: 1em;
}

.c-text-eclipse{
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}

.c-text-eclipse-2{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
 }

 .no-mobile{
     display: none;
 }

 .dum-input-div{
     position: absolute;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .dum-input{
     outline: none;
     border: none;
 }

 .dum-input:focus{
    outline: none;
    border: none;
}

.blue-btn{
    background: #3E7EFF 0% 0% no-repeat padding-box;
    border-radius: 29px;
    font-family: 'poppins-medium';
    font-size: 14px;
    color: $white;
    padding: 6px 15px;
}

.input-no-border{
    display: block;
    border: 1px solid $grey;
    width: 100%;
    padding: 6px 15px;
    border-radius: 25px;
}

.input-no-border:focus{
    outline: none;
    border: 1px solid $grey;
}

.space-between{
    justify-content: space-between;
}
.text-eclipse{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
}
.cancel-btn{
    background: #F4F3F8 0% 0% no-repeat padding-box;
    border-radius: 29px;
    font-family: 'poppins-medium';
    font-size: 14px;
    color: #0F0F2D;
    padding: 6px 15px;
}
 
@media (min-width: 1200px) {
    .desktop-only{
        display: initial;
    }

    .no-mobile{
        display: block;
    }

    .c-text-eclipse{
        max-width: 55%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
     }
     
     .c-text-eclipse-2{
        max-width: 30%;
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
      }
}
