
.like-reactions-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $darkblue;
    padding: 0px 20px;
    border-radius: 48px;
}

.like-reactions-logo{
    height: 22px;
    width: 30px;
}

.like-reactions-logo-rm{
    margin-right: 0.6em;
}

@media (min-width: 1200px) {

    .like-reactions-div{
        padding: 10px 15px;
    }

    .like-reactions-logo{
        height: 26px;
        width: 30px;
    }

    .like-reactions-logo-rm{
        margin-right: 1em;
    }
    
}

.like-reactions-logo-rm-2{
        margin-right: 6px;
    }