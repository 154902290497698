.cp-input-text{
    font-family: 'poppins-light';
    font-size: 16px;
}

.cp-input-text-2{
    font-family: 'poppins-medium';
    font-size: 16px;
}

.cp-input{
    margin-bottom: 20px;
}

.cp-input-div{
    width: 80%;
    margin: 30px auto;
}

.cp-input-submit-btn{
    margin:40px auto;
}