.notification-details-div{
    position: fixed;
    display: block;
    top: 112px;
    right: 0px;
    padding-right: 10px;
    bottom: 0px;
    width: 100%;
    border-radius: 0px;
    padding: 15px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    z-index: 100;
}

.notification-main-div{
    max-height: 80vh;
    overflow-y: scroll;
}

  .notification-main-div::-webkit-scrollbar {
    display: none;
  }
   
  .notification-main-div::-webkit-scrollbar-track {
  //  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  display: none;
  }
   
  .notification-main-div::-webkit-scrollbar-thumb {
  //  background-color: darkgrey;
  //  outline: 1px solid slategrey;
  display: none;
  }

.notification-desc{
    margin-top: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #D5D5D5;
}

.not-title{
    font: normal normal bold 1.2em Poppins;
    color: #140F26;
}

.not-unread-text{
    font: normal normal bold 1em Lato;
    color: #0F0F2D;
}

.not-list-image{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-left: 1em;
}

.not-list-image-2{
    height: 70px;
    width: 70px;
    object-fit: contain;
}

.not-list-image-div{
    margin-right: 15px;
}

.not-title-text{
    font: normal normal medium 20px/14px Lato;
    color: #140F26;
}

.not-timeline-text{
    font: normal normal 600 16px/14px Lato;
    color: #979EA4;
    margin-top: 8px;
}

.not-follow-btn{
    background: #3E7EFF 0% 0% no-repeat padding-box;
    border-radius: 24px;
    font: normal normal medium 20px/9px Poppins;
    letter-spacing: 0.8px;
    color: #FFFFFF;
    border: none;
    padding: 6px 20px;
    margin-left: 1em;
}

@media (max-width: 500px) {
  .notification-main-div{
    min-height: 100vh;
  }
}

@media (min-width: 1200px) {
    .notification-details-div{
        top: 65px;
        right: 10px;
        padding-right: 10px;
        height: 80vh;
        width: 35%;
        border-radius: 15px;
        border: 1px solid #D5D5D5;
    }

    .notification-main-div{
        max-height: 75vh;
    }
    
}
