@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;1,500&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

//colors 
$grey-faded: #F4F3F8;
$white: #FFFFFF;
$blue: #3E7EFF;
$grey: #979EA4;
$darkblue: #0F0F2D;
$red:  #FF5858;
$grey-200: #D5D5D5;
$purple-faded: #B293FF;
$purple: #7C58D6;
$black: #232629;
$grey-100 : #32335E26;
$grey-50 :#00000017;
$black-200: #333333;
$black-3: #0F0F2D;
$green: green;

$mr-40: 40px;
$mr-30: 30px;


$topbarheight: 70px;
$menuwidth: 250px;

$logo-small-height: 24px;
//font styles
$poppins: Poppins;
$lato: Lato;


//font sizes
$font-20: 20px;
$font-18: 18px;


//carousel

$carousel-control-color:             #7C58D6;
$carousel-control-width:             15%;
$carousel-control-opacity:           .5;
$carousel-control-hover-opacity:     .9;
$carousel-control-transition:        opacity .15s ease;

$carousel-indicator-width:           30px;
$carousel-indicator-height:          3px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer:          3px;
$carousel-indicator-opacity:         .5;
$carousel-indicator-active-bg:       #7C58D6;
$carousel-indicator-active-opacity:  1;
$carousel-indicator-transition:      opacity .6s ease;

$carousel-caption-width:             70%;
$carousel-caption-color:             $white;
$carousel-caption-padding-y:         1.25rem;
$carousel-caption-spacer:            1.25rem;

$carousel-control-icon-width:        2rem;

$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");

$carousel-transition-duration:       .6s;
$carousel-transition:                transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

$carousel-dark-indicator-active-bg:  $black;
$carousel-dark-caption-color:        $black;
$carousel-dark-control-icon-filter:  invert(1) grayscale(100);
$yellow1 : rgba(255, 172, 26, 1);
$yellow2 : rgba(255, 186, 64, 1);
$yellow3 : rgba(255, 200, 102, 1);
$yellow4 : rgba(255, 214, 141, 1);
$yellow5 : rgba(255, 227, 179, 1);
$yellow6 : rgba(255, 241, 217, 1);
$yellow_shadow : 0px 10px 10px rgba(255, 241, 217, 0.05);


$red1 : rgba(229, 42, 52, 1);
$red2 : rgba(233, 78, 86, 1);
$red3 : rgba(238, 113, 120, 1);
$red4 : rgba(242, 148, 153, 1);
$red5 : rgba(246, 184, 187, 1);
$red6 : rgba(251, 220, 221, 1);
$red_shadow : 0px 10px 10px rgba(229, 42, 52, 0.05);

$blue1 : rgba(22, 94, 219, 1);
$blue2 : rgba(51, 118, 235, 1);
$blue3 : rgba(92, 146, 239, 1);
$blue4 : rgba(133, 173, 243, 1);
$blue5 : rgba(174, 200, 247, 1);
$blue6 : rgba(214, 228, 251, 1);
$blue_shadow : 0px 10px 10px rgba(22, 94, 219, 0.05);
/*
$green1 : rgba(51, 175, 83, 1);
$green2 : rgba(72, 202, 105, 1);
$green3 : rgba(108, 212, 135, 1);
$green4 : rgba(145, 223, 165, 1);
$green5 : rgba(182, 234, 195, 1);
$green6 : rgba(218, 244, 225, 1);
$green_shadow : 0px 10px 10px rgba(51, 175, 83, 0.05);

*/

$black1 : rgba(35, 38, 41, 1);
$black2 : rgba(60, 65, 70, 1);
$black3 : rgba(85, 92, 100, 1);
$black4 : rgba(110, 119, 129, 1);
$black5 : rgba(138, 147, 155, 1);
$black6 : rgba(167, 174, 180, 1);
$black7 : rgba(196, 201, 205, 1);
$black8 : rgba(226, 228, 230, 1);
$black_shadow : 0px 10px 10px rgba(35, 38, 41, 0.05);
$black_shadow_up : 0px -10px 10px rgba(35, 38, 41, 0.05);
$white : rgba(255, 255, 255, 1);
$shadow_surround : 0px 0px 10px rgba(22, 94, 219, 0.10196078431372549);
$home_background : rgb(247, 249, 251);

// Typography

$h0 : 60px;
$h1 : 40px;
$h2 : 32px;
$h3 : 24px;
$h4 : 18px;
$h5 : 16px;
$h6 : 14px;
$h7 : 12px;
$h8 : 10.5px;



@font-face {
  font-family: 'Lato-Bold';
  font-style: normal;
  font-weight: bold;
  src: url('/assets/fonts/Lato-Bold.ttf');
}

@font-face {
  font-family: 'Lato-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Lato-Black';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/Lato-Black.ttf');
}

@font-face {
  font-family: 'WorkSans-ExtraBold';
  font-style: normal;
  font-weight: bold;
  src: url('/assets/fonts/WorkSans-ExtraBold.ttf');
}

@font-face {
  font-family: 'WorkSans-Bold';
  font-style: normal;
  font-weight: bold;
  src: url('/assets/fonts/WorkSans-Bold.ttf');
}

@font-face {
  font-family: 'WorkSans-Medium';
  font-style: normal;
  font-weight: medium;
  src: url('/assets/fonts/WorkSans-Medium.ttf');
}

@font-face {
  font-family: 'WorkSans-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/WorkSans-Regular.ttf');
}

@font-face {
  font-family: 'WorkSans-semiBold';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/WorkSans-SemiBold.ttf');
}

.text-c-warning{
  color: red;
}
/*
@font-face {
  font-family: 'Courier-Bold';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/Courier-Bold.ttf');
}

@font-face {
  font-family: 'Courier-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/Courier-Regular.ttf');
}

:host {
  --color : rgb(32, 52, 66) ;
  color : rgb(32, 52, 66) ;
}

*{
  --color : rgb(32, 52, 66) ;
  color : rgb(32, 52, 66) ;
  line-height: 1.4;
}



.button-normal{
  min-width: 120px;
  color: #fff;
  border-radius: 5px;
  --border-radius: 5px;
  background: $blue1;
  --background: $blue1;
  font-family: WorkSans-SemiBold;
  font-size: $h6;
  --padding-start: 16px;
  --padding-end: 16px;
  --padding-top: 16px;
  --padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-height: 42px;
  --background-activated: none;
  outline: none;
  --box-shadow: none !important;
  box-shadow: none !important;
}

.tiny-button{
  color: #fff;
  border-radius: 5px;
  --border-radius: 5px;
  background: $blue1;
  --background: $blue1;
  font-family: WorkSans-SemiBold;
  font-size: $h7;
  --padding-start: 14px;
  --padding-end: 14px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-height: 42px;
  --background-activated: none;
  outline: none;
  --box-shadow: none !important;
  box-shadow: none !important;
}

.naked-button {
  background: none;
  color: $blue1;
}

.ghost-button{
  background: $white;
  color: $blue1;
  border: 2px solid $blue1;
}

.header-back{
  margin-right: 20px;
}

.header-title{
  font-size: $h4;
  font-family: WorkSans-Medium
}


.normal-card{
  box-shadow: $black_shadow;
}

.normal-card-pad{
  box-shadow: $black_shadow;
      border-radius: 5px;
      padding: 20px;
}
*/