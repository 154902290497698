
.product-detail-outer-div{
    background-color: white;
    padding: 30px 40px;
    min-height: calc(100vh - $topbarheight - 30px);
    position: relative;
} 

.product-detail-div{
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    gap: 40px;
}

.product-detail-info-div{
}

.product-detail-image{
    border-radius: 10px;
    width: 100%;
    max-height: 75vh;
    object-fit: cover;
}

.product-detail-title{
    font-family: poppins-bold;
    font-size: 20px;
    font-weight: 600;
    margin-top: 1.2em;
}

.product-tag{
    padding: 10px 20px;
}

.product-detail-seller-card{
   column-gap: 25px;
   margin-top: 1.5em;
}

.product-detail-seller-image{
    height: 100px;
    width: 100px;
    object-fit: cover;
    border: 1px solid #F4F3F8;
    border-radius: 15px;
}

.product-detail-font-1{
    font-size: 18px;
}

.product-detail-font-2{
    font-size: 17px;
    font-weight:lighter;
}

.product-tag{
    background-color: #43B978;
    border-radius:  20px;
    color: $white;
}

.product-detail-description{
    font-family: lato-regular;
    font-size: 18px;
    margin-top: 1.5em;
}

.product-detail-description-min{
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
   -webkit-box-orient: vertical;
}

.product-detail-description-max{
    height: auto;
}


.product-detail-read-more{
    font-family: poppins-light;
}

.product-detail-tags-div{
   display: flex;
   flex-flow: row wrap;
   margin-top: 1.5em;
   row-gap: 10px;
}

.product-tag-outline{
    border: 1px solid #979EA4;
    border-radius: 16px;
    background-color: $white;
    font-family: 'lato-regular';
    font-size: 16px;
    padding: 5px 15px;
}

.product-tag-outline-selected{
    border: 2px solid greenyellow;
    border-radius: 16px;
    background-color: $white;
    font-family: 'lato-regular';
    font-size: 16px;
    padding: 5px 15px;
}

.product-detail-cart-btn{
    background: #FF5858 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 50px #FFFFFF;
    border-radius: 10px;
    color: $white;
    width: 40%;
    padding: 10px 20px;
}

.product-detail-wishlist-btn{
    background: #F4F3F8 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 50px #FFFFFF;
    border-radius: 10px;
    color: #0F0F2D;
    width: 40%;
    padding: 10px 20px;
}

.product-detail-btn-div{
   display: flex;
   margin: 3em 0px 60px;
}

.product-divider-line{
    width:90%;
}

.product-detail-image-div{
    background-color: $white;
}

.back-detail-main-div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
}

@media (max-width: 600px){

    .back-detail-main-div{
        display: none;
    }

    .product-detail-outer-div{
          position: fixed;
        //  top:calc(-1 * ($topbarheight + 35px));
          display: block;
          z-index:100;
          width: 100vw;
          max-width: 100vw;
          height: 100vh;
          max-height: 100vh;
          overflow-y: scroll;
          padding-bottom: 0px;
          top: 0px;
        padding: 30px 10px 50px 10px;
    } 

    .product-detail-image-div{
        margin-bottom: 2em;
    }

    .product-detail-div{
        display: block;
    }

    .product-detail-btn-div{
        display: block;
     } 
     
     .product-detail-cart-btn{
        width: 100%;
        display: block;
    }

    .product-detail-wishlist-btn{
        width: 100%;
        display: block;
        margin-top: 1.2em;
    }

    .product-detail-title{
        font-size: 18px;
    }
}

@media (min-width: 1200px){
    .product-detail-title{
        font-size: 25px;
    }
}