

.new-post-detail-card{
    background: #FFFFFF;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    min-height: 300px;
    padding: 35px 24px 10px 40px;
  //  max-width: 600px;
}

.new-post-card-preheader{

}

.new-post-rejected-overlay{
     position: absolute;
     display: flex;
     align-items: center;
     justify-content: center;
     top: 0px;
     left: 0px;
     right: 0px;
     bottom: 0px;
     z-index: 2;
     background-color: rgba(black, 0.5);
     border-radius: 10px;
}

.new-post-card-font-1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-transform: capitalize;
}

.new-post-rejected-overlay-center{
    background-color: white;
    border-radius: 12px;
    padding: 60px 30px;
}

.new-post-card-font-2{
  //  font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
   // text-transform: capitalize;
}



.new-post-card-font-3{
 //   font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.new-post-card-font-4{
  //  font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.new-post-card-font-5{
  //  font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.new-post-card-color-1{
    color: #000000;
}

.new-post-card-comment{
    margin: 16px 0px;
}

.new-post-card-reaction-div{
    margin: 16px 0px;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.new-post-card-color-2{
    color: #3E7EFF;
}

.new-post-card-color-3{
    color:#696969;
}

.new-post-card-name-div{
    display: flex;
    column-gap: 20px;
    align-items: center;
    margin-bottom: 24px;
    position: relative;
}

.new-post-card-more-div{
   display: none;
   position: absolute;
   top: 0px;
   right: -5px;
}

.new-post-card-circle{
    height: 4px;
    width: 4px;
    background: #000000;
    border-radius: 50%;
}

.new-post-card-margin{
    border: 1px solid #D9D9D9;
    margin: 8px 20px 16px 0px;
}

.new-post-card-margin-2{
    border: 1px solid #D9D9D9;
    margin: 16px 10px 16px 0px;
}

.new-post-card-comment-div{
    margin-top: 24px;
}

.new-post-card-title-div{
    margin: 30px 0px 16px;
}

.new-post-card-views-div{
    margin: 14px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-post-card-image-div{
    height: 48px;
    width: 48px;
    min-height: 48px;
    min-width: 48px;
    background-color: #EEEEEE;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid #D9D9D9;
}

.new-post-card-icon{
    height: 24px;
    width: 24px;
    object-fit: contain;
}

.new-post-reaction-series-div{
    position: relative;
}

.overlap-icon-1{
    position: relative;
    left: 0px;
    top: 0px;
}

.overlap-icon:not(:first-child){
    position: relative;
    margin-left: -6px;
}

.new-post-card-icon-2{
    height: 16px;
    width: 16px;
}

.new-post-card-icon-3{
    height: 22px;
    width: 22px;
}

.icon-flex{
    display: flex;
    gap: 8px;
    align-items: center;
}

.new-post-card-comment{
    display: flex;
    column-gap: 8px;
    padding-right: 10px;
    position: relative;
}

.new-post-delete-comment-icon{
    position: absolute;
    top: 6px;
    right: 6px;
}

.new-post-card-comment-text-div{
    background: #EEEEEE; 
    border-radius: 5px;
    padding: 16px;
    justify-self: stretch;
    position: relative;
    width: calc(100% - 48px);
}

.new-post-card-comment-name{
    margin-bottom: 4px;
    align-items: flex-end;
}

.new-post-card-timestamp{
    margin-top: 5px;
    gap: 10px;
}

.new-text-eclipse-1{
     max-width: 250px;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
}

.new-text-eclipse-2{
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.new-post-card-input{
    background: #F3F3F3;
    border-radius: 72px;
    padding: 10px 15px;
    padding-right: 42px;
    width: 100%;
    justify-self: stretch;
    height: 48px;
    outline: none;
}

.new-post-card-input-parent{
    width: 100%;
    position: relative;
}

.new-post-card-comment-input-div{
    display: flex;
    gap: 16px;
    margin: 24px 0px;
}

.new-post-card-send-button-div{
    position: absolute;
    top: 12px;
    right:12px;
}




@media (max-width: 600px){
    .new-post-detail-card{
        background: #FFFFFF;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        min-height: 300px;
        padding: 15px;
        padding-bottom: 1px;
        margin-bottom: 16px;
    }

    .new-post-card-comment{
        padding-right: 0px;
    }

    .new-post-card-font-3{
        font-size: 12px;
        line-height: 18px;
    }
    
    .mobile-font-1{
        font-size: 12px;
        line-height: 18px;
    }

    .new-post-card-font-5{
     //   font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 21px;
    }

    .new-post-card-comment-text-div{
        padding: 8px;
        padding-right: 20px;
        position: relative;
        justify-self: stretch;
    }

    .new-post-card-comment{
        padding-right: 0px;
    }

    .new-post-card-timestamp{
        margin-top: 2px;
    }

    .new-text-eclipse-1{
        max-width: 180px;
   }
   
   .new-text-eclipse-2{
       max-width: 100px;
   }
   
}

.new-headline-font{
    font-size: 18px;
    line-height: 24px;
}

.new-description-font{
    font-size: 16px;
    line-height: 25px;
}

@media (max-width: 900px){
    .new-headline-font{
        font-size: 17px;
        line-height: 24px;
    }
    
    .new-description-font{
        font-size: 15px;
        line-height: 26px;
    }
}