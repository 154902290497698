

.content-flex{
  position: relative;
  display: block;
}

.content-area-div{
  position: relative;
  display: block;
  //top: 105px;
  top: 170px;
  left: 0;
  right: 0;
  //min-height:calc(100vh + 2*$topbarheight);
}

@media (max-width: 600px){
  .content-area-div{
    top: 0px ;
  }
}

.content-div{
 background-color: #F4F3F8;
 padding-top: 10px;
 position: relative;
 height: auto;
 padding-bottom: 0px;
}

@media (min-width: 600px) and (max-width: 980px){
  .content-area-div{
    max-width: 600px;
    top: 0px;
    padding-top: 105px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

}

@media (min-width: 980px) and (max-width: 1200px){
  .content-area-div{
    width: 100%;
    top: 0px;
    padding-top: 105px;
    padding-left:10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

}

@media (min-width: 1200px) {
  .content-div{
    padding:30px 10px 0px 30px;
  }
}

@media (min-width: 1200px) {
.content-area-div{
    position: relative;
    display: block;
  //  top: calc($topbarheight - 3px);
    top: 175px;
    left: 0;
    right: 0;
    //height: calc(90vh - $topbarheight);
    min-width:100%;
}

.content-flex{
  display: grid;
  grid-template-columns: 250px 1fr;
  height: auto;
 //  grid-template-columns: 0px 1fr;

}

.menu-flex{
    height: calc(100vh - $topbarheight - 3px);
    top: calc( $topbarheight);
    position: sticky;
    background-color: $white;
}

.content-div{
  background-color: #F4F3F8;
  padding:30px 10px 0px 30px;
  grid-row: 1;
  grid-column:2;
}

@media (min-width: 1200px) {

  .content-div{
    padding-right:0px;
  }
}

}
