.success-div{
    display: flex;
    min-height: 50vh;
    align-items: center;
    justify-content: center;
    background-color: #43B978;
    padding: 30px 20px;
}

.success-image-div{
    position: relative;
    display: block;
    margin: 0px auto;
    margin-top: 30px;
    height: 136px;
    width: 204px;
}

.success-font-1{
    font-size: 21px;
}

.success-tick-icon{
    position: absolute;
    height: 70px;
    width: 70px;
    right: 67px;
    top: 31px;
}

.success-tick-spread-icon{
    height: 136px;
    width: 204px;
}


@media (max-width: 600px){
    .success-div{
        height: 80vh;
    }
}