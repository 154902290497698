
.follower-details-list{
    margin-top: 1.5em;
    padding: 0px 15px;
}

.follower-details-desc{
    margin-bottom: 1em;
}

.follower-image{
    height: 45px;
    width: 45px;
    border-radius: 50%;
}

.list-close-btn{
    display: none;
}

.react-name-text{
    font: normal normal normal 1em Poppins;
    letter-spacing: 0px;
    color: #0F0F2D;
}

.followers-follow-btn{
    background: #3E7EFF 0% 0% no-repeat padding-box;
    border-radius: 22px;
    font: normal normal medium 1em Poppins;
    letter-spacing: 0.8px;
    color: #FFFFFF;
    border: none;
    padding: 5px 22px;
}

.reaction-type-image{
     height:20px;
     width: 20px;
     border-radius: 50%;
     position: absolute;
     bottom: 0px;
     right: 0px;
     background: #0F0F2D 0% 0% no-repeat padding-box;
     border: 1px solid #FFFFFF;
     padding: 5px;
}

.react-image-div{
    position: relative;
    margin-right: 0.8em;
}

.reaction-list-back-div{
    display: block;
    margin-right: 1.3em;
}

.reaction-list-back-icon{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 0.5em;
}

.mobile-only{
    display: block;
}

.react-cust-title{
    padding: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
}

@media (min-width: 900px) {

    .mobile-only{
        display: none;
    }

    .react-image-div{
        margin-right: 1.5em;
    }

    .follower-details-desc{
        margin-bottom: 1em;
    }

    .reaction-list-back-div{
        display: none;
    }

    .list-close-btn{
        display: block;
    }    

    .no-mobile{
        display: block;
    }

    .follower-details-list{
        margin-top: 1.5em;
        padding: 0px 60px;
    }

    .react-cust-title{
        justify-content: space-between;
    }

    .follower-image{
        height: 50px;
        width: 50px;
    }

    .react-cust-title{
        padding: 30px 60px 10px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: none;
        display: flex;
    }

    .reaction-list-back-icon{
        height: 40px;
        width: 40px;
        margin-right: 1.2em;   
    }
    
    .react-name-text{
        font-size: 1.3em;
    }
    
    .followers-follow-btn{
        font-size: 1em;
        padding: 6px 24px;
    }
}