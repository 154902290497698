.login-wrapper{
   position: relative;
   min-height: 100vh;
   padding: 10px;
   display: block;
   max-width: 1600px;
   margin: 0 auto;
}

.login-main-div{
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas: "login-mobile-card";
    width:100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.modal-login-main-div{
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas: "login-mobile-card";
    width:100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.login-mobile-card{
    position: relative;
}

.desktop-card{
    display: none;
}

.login-3km-logo-div{
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.login-3km-logo-image{
    width: 100px;
    height: 90px;
}

.login-text{
    color: #007FFF;
}

.login-text-2{
    font: normal normal bold 16px/25px Poppins;
    color: #232629;
}

.login-text-3{
    font: normal normal bold 16px/25px Poppins;
    color: #FFFFFF;
}

.login-text-4{
    font: normal normal normal 16px/25px Poppins;
    color: #232629;
}

.login-text-5{
    font: normal normal 500 16px/9px Poppins;
    letter-spacing: 0.64px;
    color: #3E7EFF;
}

.login-text-6{
    text-decoration: underline;
    font: normal normal 600 12px/18px Poppins;
    letter-spacing: 0px;
    color: #0F0F2D;
}

.login-text-7{
    text-decoration: underline;
    font: normal normal 600 16px/25px Poppins;
    color: #3E7EFF;
}

.login-text-8{
    font: normal normal 600 14px Poppins;
    color: #0F0F2D;
    text-align: center;
}

.login-text-9{
    font: normal normal bold 16px Poppins;
}

.login-text-11{
    font: normal normal normal 14px Poppins;
    color: #0F0F2D;
}

.login-text-12{
    font: normal normal normal 14px Poppins;
    color: #0F0F2D;
}

.step-1-div{
    margin: 22px auto 50px auto;
}

.step-2-div{
    margin: 22px auto 60px auto;
}

.reset-password-2-form{
    margin: 20px 20px;
}

.text-login-blue{
    color: #3E7EFF;
}

.login-otp-div{
    padding: 60px 40px;
}

.otp-phone-no{
   display: flex;
   justify-content: space-around;
}

.otp-phone-no-digit-filled{
     min-width: 20%;
     max-width: 65px;
     height: 65px;
     background: #F4F3F8 0% 0% no-repeat padding-box;
     border: 2px solid #3E7EFF;
     border-radius: 10px;
     padding: 5px;
     font: normal normal bold 30px Poppins;
     letter-spacing: 0px;
     text-align: center;
     color: #3E7EFF;
}

.otp-phone-no-digit{
    border: 1px solid var(---d5d5d5);
    background: #F4F3F8 0% 0% no-repeat padding-box;
    border: 1px solid #D5D5D5;
    border-radius: 10px;
    padding: 5px;
    min-width: 20%;
    max-width: 65px;
    font: normal normal bold 30px Poppins;
    color: #D5D5D5;
    height: 65px;
    text-align: center;
}

.otp-phone-no-digit-wrong{
    background: #F4F3F8 0% 0% no-repeat padding-box;
    border: 2px solid #FF5858;
    border-radius: 10px;
    opacity: 1;
    padding: 5px;
    min-width: 20%;
    max-width: 65px;
    font: normal normal bold 30px Poppins;
    letter-spacing: 0px;
    color: #FF5858;
    text-align: center;
    height: 65px;
}

.otp-phone-no-digit-filled:focus, .otp-phone-no-digit:focus, .otp-phone-no-digit-wrong:focus{
    outline: 2px solid #3E7EFF;
}

.otp-phone-no-input{
    font: normal normal bold 40px Poppins;
    letter-spacing: 0px;
    color: #3E7EFF;
    max-width: 100%;
    max-height: 100%;
}

.wrong-otp-text{
    font: normal normal 600 14px Poppins;
    color: #FF5858;
    padding-top: 15px;
}

.login-otp-text-div{
   margin: 40px 0px;
   display: block;
}

.login-otp-text-div-2{
    margin: 25px 0px;
    display: block;
 }

 .login-otp-text-div-3{
    margin: 35px 0px 50px 0px;
    display: block;
 }

.ph-code{
    position: absolute;
    display: flex;
    align-items: center;
    font: normal normal normal 15px Poppins;
    color: #0F0F2D;
    left: 10px;
    height: 100%;
}

.sakal-bak-div{
    position: absolute;
    bottom: -50px;
    left: -50px;
    right: 0px;
    padding: 30px 10px;
}

.sakal-bak-logo{
    height: 50px;
}

.login-desk-footer-div{
    display: none;
}

.login-footer-div{
    position: absolute;
    bottom: -100px;
    right: 10px;
    left: 10px;
 }

.login-fb-image{
    height: 40px;
    width: 40px;
}

.login-fb-image-div{
    background-color: #FFFFFF;
    height: 100%;
    border-radius:10px 0px 0px 10px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.facebook-login-div{
    border: 1px solid #3F51B5;
    border-radius: 10px;
    width: 80%;
    display: flex;
    margin: 0px auto;
    align-items: center;
    height: 60px;
}

.login-fb-text{
   background-color: #3F51B5;
 //  border: 1px solid #3F51B5;
   height: 100%;
   border-radius:0px 9px 9px 0px;
   width: calc(100% - 60px);
   border-color: #3F51B5;
}

.login-google-text{
    background-color: #3E7EFF;
  //  border: 1px solid #3F51B5;
    height: 100%;
    border-radius:0px 9px 9px 0px;
    width: calc(100% - 60px);
    border-color: #3E7EFF;
 }

.google-login-div{
     margin-top: 30px;
     border-color: #3E7EFF;
}

.app-play-store-div{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.google-play-div{
    margin-right: 32px;
}

.google-play-logo{
    height: 55px;
}

.app-store-logo{
    height: 55px;
}

.login-divider{
    width: 85%;
    border: 1px solid #D5D5D5;
    display: block;
    margin: 30px auto;
}

.login-divider-2{
    width: 85%;
    border: 1px solid #D5D5D5;
    display: block;
    margin: 40px auto 50px;
}

.left-arrow{
    height: 34px;
    width: 34px;
    margin-right: 8px;
}
.login-input-div{
    width: 75%;
    display: block;
    margin: 30px auto;
}

.login-phno-input{
    background: #F4F3F8 0% 0% no-repeat padding-box;
    border: 1px solid #D5D5D5;
    border-radius: 37px;
    width: 100%;
    height: 45px;
    padding: 8px 50px;
    text-align: left;
    font: normal normal normal 15px Poppins;
    letter-spacing: 0px;
    color: #0F0F2D;
}

.login-phno-input-2{
    background: #F4F3F8 0% 0% no-repeat padding-box;
    border: 1px solid #D5D5D5;
    border-radius: 10px;
    width: 100%;
    height: 45px;
    padding: 8px 50px;
    text-align: left;
    font: normal normal normal 15px Poppins;
    letter-spacing: 0px;
    color: #0F0F2D;
}

.login-phno-input-3{
    background: #F4F3F8 0% 0% no-repeat padding-box;
    border: 1px solid #D5D5D5;
    border-radius: 10px;
    width: 100%;
    height: 45px;
    padding: 8px 50px 8px 20px;
    text-align: left;
    font: normal normal normal 15px Poppins;
    letter-spacing: 0px;
    color: #0F0F2D;
}

.pwd-eye{
    position: absolute;
    right: 0px;
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-password-input-div{
    margin-top: 22px;
    margin-bottom: 35px;
}

.title-2{
    margin-bottom: 45px;
}


.login-password-input-div-2{
    margin-top: 22px;
    margin-bottom: 35px;
}

.login-password-btn{
    width: 80%;
    display: block;
    margin: 40px auto 0px auto;
    background: #3E7EFF 0% 0% no-repeat padding-box;
    border: 1px solid #3E7EFF;
    border-radius: 29px;
    height: 45px;
    color: white
}

.login-password-form-div{
   width: 85%;
   display: block;
   margin: 35px auto;
}

.login-phno-input:focus, .login-phno-input-2:focus, .login-phno-input-3:focus {
    outline-color: #3E7EFF;
}

.login-pwd-divider{
   border: 1px solid #00000029;
   height: 1px;
}

.password-otp-btn{
    width: 40%;
    display: block;
    margin: 0px auto;
    margin-bottom: 40px;
}

.login-pwd-divider-div{
    display: flex;
    align-items: center;
    width: 80%;
    margin: 20px auto;
}

.login-otp-input{
    border: 1px solid var(---d5d5d5);
    background: #F4F3F8 0% 0% no-repeat padding-box;
    border: 1px solid #D5D5D5;
    border-radius: 37px;
    width: 100%;
    height: 45px;
    padding: 8px 20px;
    text-align: left;
    font: normal normal normal 15px Poppins;
    letter-spacing: 0px;
    color: #0F0F2D;
}

.login-btn{
    border: 1px solid #3E7EFF;
    border-radius: 29px;
    margin-top: 20px;
    width: 100%;
    background-color: white;
    height: 45px;
}

.otp-btn{
    border: 1px solid #3E7EFF;
    border-radius: 29px;
    width: 100%;
    background-color: white;
    height: 45px;
    margin: 20px auto;
    display: block;
}

.login-btn:hover, .otp-btn:hover{
    background-color: #3E7EFF;
    color:  white;
}

.otp-text{
    font: normal normal 600 14px Poppins;
    letter-spacing: 0px;
    color: #3E7EFF;
}

.otp-text:hover{
    text-decoration: underline;
}

.otp-timer{
     text-decoration: none;
}

.otp-timer:focus, .otp-timer:active{
    text-decoration: none;
}

.login-forg-btn-div{
    margin: 51px 0px 41px 0px;
}

@media (min-width: 900px){
    
    .login-main-div{
        display: grid;
        column-gap:50px;
        grid-template-columns: 2fr 1.2fr;
        grid-template-rows: auto;
        grid-template-areas: "desktop-card login-mobile-card-outer-div";
        width:100%;
        max-width: none;
        padding: 50px 60px 10px 60px;
    }

    .modal-login-main-div{
        display: grid;
        column-gap:50px;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        grid-template-areas: "login-mobile-card-outer-div";
        width:100%;
    }

    .desktop-card{
        display: flex;
        justify-content: center;
    }

     .login-mobile-card{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 20px #00000029;
        border-radius: 10px;
        padding: 30px 0px;
        position: relative;
        min-height: 50vh;
        max-width: none;
     }

     .login-3km-logo-div{
        margin-top: 40px;
    }

     .login-3km-logo-image{
        width: 200px;
        height: 210px;
    }

    .login-text{
           position: relative;
           top: -30px;
           font: normal normal 600 12px/18px Poppins;
    } 

    .login-text-2{
       font-size: 20px;
    }

    .get-app-div{
        margin-top: 100px;
    }

    .login-desk-footer-div{
        display: block;
        position: relative;
        padding-top: 30px;
    }

    .login-otp-text-div{
        margin: 80px 0px;
    }

    .login-otp-text-div-2{
        margin: 50px 0px 40px;
     }

     .login-otp-text-div-3{
        margin: 50px 0px 40px;
     }

     .title-2{
        margin-bottom: 45px;
    }
    
    .login-text-8{
        font-size: 20px;
        text-align: left;
    }
      
    .login-phno-input{
        font-size: 18px;
    }

    .login-phno-input-2, .login-phno-input-3{
        font-size: 18px;
    }

    .login-otp-input{
        font-size: 18px;
    }

    .ph-code{
        font-size: 18px;
    }

    .wrong-otp-text{
        font-size: 20px;
    }

    .otp-phone-no-digit-filled{
        font-size: 50px;
        height: 80px;
   }
   
   .otp-phone-no-digit{
        font-size: 50px;
        height: 80px;
   }

   .login-password-input-div{
    margin-top: 30px;
    margin-bottom: 40px;
}
   
.login-password-input-div-2{
    margin-top: 30px;
    margin-bottom: 35px;
}
   
   .otp-phone-no-digit-wrong{
       font-size: 50px;
       height: 80px;
   }

   .otp-text{
    font-size: 16px;
   }

   .login-password-form-div{
    margin: 50px auto;
    width: 70%;
 }

   .otp-btn{
    width: 50%;
   }

   .login-text-9{
       font-size: 20px;
   }

   .login-pwd-divider-div{
    margin: 30px auto;
   }

   .login-text-4{
    font-size: 18px;
   }

  .login-text-12{
    font: normal normal normal 18px Poppins;
    color: #0F0F2D;
   }

   .reset-password-2-form{
    margin: 20px 40px;
   }

   .login-text-11{
     font-size: 18px;
     line-height: 25px;
   }

}