.address-list-div{
    padding: 30px;
}

.address-list-card{
    border: 1px solid #D5D5D5;
    padding: 10px 10px;
    border-radius: 10px;
}

.address-list-active-card{
    border: 2px solid greenyellow;
    padding: 10px 10px;
    border-radius: 10px;
}

@media (max-width: 600px){
    .address-list-div{
        padding: 0px 10px;
    }
}