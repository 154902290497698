.left-side-menu{
    display: none;
  }
  
  .create-post-btn{
    font-size: 16px;
    display: flex;
    align-items: center;
    transition: 0.5s ease;
  }

  .create-post-btn:hover{
     transform: scale(1.05);
  }

  @media(min-width: 600px) and (max-width: 1200px){
    .create-post-btn{
      display: block;
      height: 50px;
      border-radius: 21px;
      width: 300px;
      background-color: #3E7EFF;
      border: none;
      color: $white;
      background: var(---3e7eff) 0% 0% no-repeat padding-box;
      background: #3E7EFF 0% 0% no-repeat padding-box;
      margin: 20px auto 10px;
      filter: blur(-1px);
      z-index:2;
  }
  
  .btn-below{
     display: block;
     position: relative;
     top:-20px;
     margin: 0 auto;
     filter: blur(15px);
     height:30px;
     width:200px;
     background-color: $blue;
     z-index:0;
  } 

  .mr-8{
    margin-right: 8px;
  }
  
  .menu-icon{
      height: 25px;
      width: 25px;
  }
  
  .nav-top-margin{
      margin-top: 24px;
  }
  
  .menu-items-top-margin{
      margin-top: 28px;
  }
  
  .menu-items-bottom-margin{
      margin-top: 30px;
  }
  
  .side-menu-div li{
      list-style: none;
      list-style-position: inside;
      margin-left:0px;
  }
  
  
  
  ul .side-menu-list li{
     list-style: none;
     list-style-position: inside;
     margin-left:0px;
     padding-left:0px;
  }
  
  .menu-item-text{
      font: normal normal normal 15px Poppins;
      letter-spacing: 0px;
      color: #0F0F2D;
  }
  
  .menu-divider{
      border: 1px solid #F4F3F8;
      margin: 30px 0px 0px 0px;
  }
  }

  @media (max-width: 600px){
    .create-post-btn{
      display: block;
      height: 50px;
      border-radius: 21px;
      width: 80%;
      background-color: #3E7EFF;
      border: none;
      color: $white;
      background: var(---3e7eff) 0% 0% no-repeat padding-box;
      background: #3E7EFF 0% 0% no-repeat padding-box;
      margin: 20px auto 10px;
      filter: blur(-1px);
      z-index:2;
  }
  
  .btn-below{
     display: block;
     position: relative;
     top:-20px;
     margin: 0 auto;
     filter: blur(15px);
     height:30px;
     width:70%;
     background-color: $blue;
     z-index:0;
  }

  
  }

 


@media (max-width: 700px) { 
  .mr-8{
    margin-right: 8px;
  }
  
  .menu-icon{
      height: 25px;
      width: 25px;
  }
  
  .nav-top-margin{
      margin-top: 24px;
  }
  
  .menu-items-top-margin{
      margin-top: 28px;
  }
  
  .menu-items-bottom-margin{
      margin-top: 30px;
  }
  
  .side-menu-div li{
      list-style: none;
      list-style-position: inside;
      margin-left:0px;
  }
  
  
  
  ul .side-menu-list li{
     list-style: none;
     list-style-position: inside;
     margin-left:0px;
     padding-left:0px;
  }
  
  .menu-item-text{
      font: normal normal normal 15px Poppins;
      letter-spacing: 0px;
      color: #0F0F2D;
  }
  
  .menu-divider{
      border: 1px solid #F4F3F8;
      margin: 30px 0px 0px 0px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 

 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
   
 }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .left-side-menu{
          position: relative;
          display: block;
          max-width: $menuwidth;
          padding: 30px 20px 30px 10px;
          max-height: 100%;
          overflow-y: scroll;
      }

      .left-side-menu::-webkit-scrollbar {
        background-color: $white;
        width: 8px;
      }

      .left-side-menu::-webkit-scrollbar-thumb {
        background-color: transparent;
      }

      .left-side-menu:hover::-webkit-scrollbar {
        background-color: $white;
        width: 8px;
      }

      .left-side-menu:hover::-webkit-scrollbar-thumb {
        background-color: $grey-100;
        border-radius: 8px;
      }
      
      .create-post-btn{
           display: block;
           height: 50px;
           border-radius: 21px;
           width: 100%;
           background-color: #3E7EFF;
           border: none;
           color: $white;
           background: var(---3e7eff) 0% 0% no-repeat padding-box;
           background: #3E7EFF 0% 0% no-repeat padding-box;
           margin-top: 20px;
           margin-bottom: 10px;
           filter: blur(-1px);
           z-index:2;
      
      }
      
      .btn-below{
          display: block;
          position: relative;
          top:-20px;
          margin: 0 auto;
          filter: blur(15px);
          height:30px;
          width:70%;
          background-color: $blue;
          z-index:0;
      }
      
      .mr-8{
        margin-right: 8px;
      }
      
      .menu-icon{
          height: 25px;
          width: 25px;
      }
      
      .nav-top-margin{
          margin-top: 24px;
      }
      
      .menu-items-top-margin{
          margin-top: 28px;
      }
      
      .menu-items-bottom-margin{
          margin-top: 30px;
      }
      
      .side-menu-div li{
          list-style: none;
          list-style-position: inside;
          margin-left:0px;
      }
      
      
      
      ul .side-menu-list li{
         list-style: none;
         list-style-position: inside;
         margin-left:0px;
         padding-left:0px;
      }
      
      .menu-item-text{
          font: normal normal normal 15px Poppins;
          letter-spacing: 0px;
          color: #0F0F2D;
      }
      
      .menu-divider{
          border: 1px solid #F4F3F8;
          margin: 30px 0px 0px 0px;
      }
      
      
}