.sk-post{
    padding: 1.5em;
}

.sk-all-post-image{
    min-height: 35vh;
}

.food-category-sk-image:empty{
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin-bottom: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.list-sk-image{
    height: 80px;
    width: 80px;
    margin-bottom: 20px;
    display: block;
    margin-right: 20px;
}

.list-sk-body-1{
    height: 30px;
    margin-bottom: 20px;
    display: block;
}


.food-category-sk-name{
    min-height: 20px;
}

.restaurant-safe-tag:empty{
    min-height: 20px;
    min-width: 60px;
}

.sk-all-post-image:empty, .food-category-sk-image, .food-category-sk-name, img .food-restaurant-image:empty,div .food-restaurant-image:empty,.restaurant-safe-tag:empty, .list-sk-image, .list-sk-body-1{
    animation: loading 1.1s infinite linear;

    background: #dedfe1;
    
    background-image: -webkit-linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
    
    background-image: linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
    
    background-repeat: no-repeat;
}