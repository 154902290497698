.news-home-grid{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-template-areas: 
    "adv-item"
     "news-post-item";
    width: 100%;
    position: relative;}

.adv-item{
 //   display: none;
    grid-area: adv-item;
    margin-bottom: 14px;
    position: relative;
}

.featured-stories-div{
    display: none;
}

.news-post-item{
    grid-area: news-post-item;
}

.search-location-div{
    display: none;
    position: relative;
}

.location-mob-div{
    display: flex;
    align-items: center;
    padding:11px 15px;
}

.search-bar-div{
    display: none;
}

.location-text{
    font: normal normal 500 0.8em $poppins;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.location-icon{
    margin-right: 5px;
    height: 18px;
    width: 18px;
}

.topslide-text{
    font: normal normal normal 1em Poppins;
    color: #0F0F2D;
}

.suggestion-profile-image{
    height:60px;
    width: 60px;
    margin-bottom: 8px;
    border-radius: 50%;
}

.sugg-username-text{
    font: normal normal 500 1.1em Poppins;
    letter-spacing: 0px;
    color: #0F0F2D;
}

.sugg-type-text{
    font: normal normal bold 1.1em Lato;
    letter-spacing: 0px;
    color: #979EA4;
}


@media (min-width: 980px) {
.news-home-grid{
    display: grid;
    column-gap:15px;
    grid-template-columns: 2fr 1.4fr;
    grid-template-rows: auto;
    grid-template-areas: "news-post-item adv-item";
    width:100%;
    padding-top: 10px;
}

.featured-stories-div{
    display:block;
}

.location-div{
    display: flex;
    align-items: center;
    padding:10px 15px;
    position: relative;
}

.location-icon{
    height: 30px;
    width: 30px;
}

.location-mob-div{
    display: none;
}

.search-location-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-overflow: ellipsis;
    position: relative;
    max-width: 100%;
}

.location-div{
    display: flex;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    padding:0px 20px;
    position: relative;
}

.news-post-item{
    min-height: 100px;
    max-width: 100%;
}

.adv-item{
    display: block;
    position: sticky;
    top: calc($topbarheight + 30px);
    height: calc(100vh - $topbarheight - 50px);
    overflow-y: scroll;
    background-color: $white;
}

  .adv-item::-webkit-scrollbar {
    background-color: $white;
    width: 8px;
  }

  .adv-item::-webkit-scrollbar-thumb {
    background-color: $white;
  }

  .adv-item:hover::-webkit-scrollbar {
    background-color: $white;
    width: 8px;
  }

  .adv-item:hover::-webkit-scrollbar-thumb {
    background-color: $grey-100;
    border-radius: 8px;
  }

.adv-people-card{
    background-color: $white;
    padding: 20px 10px 20px 10px;
}

.news-posts-card{
    margin-top: 2em;
    background-color: $white;
}

.search-bar-div{
    display: block;
    width:50%;
}

.adv-image{
    max-width: 100%;
}

.people-suggestion-div{
    margin-top: 3em;
    text-align: center;
}

.people-recommendations-div{
     display: grid;
     grid-template-columns: 1fr 1fr;
     grid-template-rows: minmax(150px,auto) minmax(150px,auto);
     gap:30px;
     margin: 2em;
  
}

.people-individual-card{
    box-shadow: 0px 3px 6px #32335E26;
    border-radius: 15px;
    padding: 10px;
    display: block;
}

.dot-vertical-div{
    position: fixed;
    top: 0px;
    width:25px;
    height:25px;
}

.suggestion-profile-image{
    height:60px;
    width: 60px;
    margin-bottom: 8px;
}

.sugg-username-text{
    font: normal normal 500 1.2em Poppins;
    letter-spacing: 0px;
    color: #0F0F2D;
}

.sugg-type-text{
    font: normal normal bold 1em Lato;
    letter-spacing: 0px;
    color: #979EA4;
}

.cust-follow-btn{
    background: #3E7EFF 0% 0% no-repeat padding-box;
    border-radius: 18px;
    width:100px;
    height:32px;
    border: none;
    color: $white;
    font-size: 15px;
    margin-top: 12px;
}

.news-category-list-margin{
    margin-top: 2em;
}

.location-text{
   font-size: 16px;
}
}