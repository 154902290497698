
.sk-search-bar{
    min-height: 30px;
    width: 100%;
}

.sample-skeleton{
    min-height: 40vh;
    min-width: 200px;
}

.sk-posts-category-div{
    min-width: 100%;
    min-height: 30vh;
    display: flex;
    gap: 1em;
    margin-bottom: 3em;
}

.sk-post-image{
    min-height: 150px;
}

.sk-circle{
    min-height: 40px;
    min-width: 40px;
    border-radius: 50%;
}

.sk-post-sample-1{
   flex:1;
   display: block;
   position: relative;
}

.sk-rect-div{
    min-width: calc( 100% - 60px);
}
.sk-post-sample-2{
    flex:1;
}

.sk-post-sample-3{
    flex:0.5;
}

.sk-sm-rect{
     display: block;
     position: relative;
     min-height: 10px;
}

.sk-featured-story-card{
    display: block;
    position: relative;
    min-height:80px;
}

.sk-topslide-page-adv-card{
    display: block;
    min-height: 200px;
}

.sk-ps-div-1, .sk-ps-div-2, .sk-ps-div-3{
     min-height: 200px;
     margin: 20px;
}

.sk-search-bar:empty, .sample-skeleton:empty ,
 .sk-post-image:empty, .sk-circle:empty ,.sk-ps-div-1, .sk-ps-div-2, .sk-ps-div-3, .sk-sm-rect:empty, .sk-featured-story-card:empty, .sk-topslide-page-adv-card:empty{
    animation: loading 1.1s infinite linear;

    background: #dedfe1;
    
    background-image: -webkit-linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
    
    background-image: linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
    
    background-repeat: no-repeat;
}

@keyframes loading {

    0% {
    
    background-position: -100px;
    
    }
    
    100% {
    
    background-position: 500px;
    
    }
    
    }

    @media (max-width: 900px) {
        .sk-circle{
            display: none;
        }

        .sk-posts-category-div{
            min-width: 100%;
            min-height: 15vh;
            display: flex;
            gap: 1em;
            margin-bottom: 2em;
            background-color: white;
            padding: 10px;
        }

        .sk-post-image{
            min-height: 10vh;
        }

        .sk-rect-div{
            min-width: 100%;
        }

        .sk-search-bar{
            min-height: 20vh;
        }

        .sk-topslide-page-adv-card{
           min-height: 150px;
        }
    }
