.featured-story-card{
    display: flex;
  //  margin-bottom: 1.2em;
}

.featured-story-image-div{
    flex:1;
}

.featured-story-text{
    font-family: 'poppins-medium';
    font-size: 14px;
    color: $black;
}

.featured-story-text-2{
    font-family: 'poppins-medium';
    font-size: 12px;
    color: #979EA4;
    margin-top:0.2em;
}

.featured-story-content-div{
    flex:3;
    padding: 0px 10px 0px 10px;
}

.featured-story-image{
    width: 90px;
    min-height: 60px;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.download-app-div{
  //  border: 2px solid rgb(126, 22, 126);
    width: 85%;
    margin: 20px auto;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px 3px #E9E9EB;
}

.download-app-div:hover{
    box-shadow: 0 0 5px 3px rgb(177, 54, 177);
}
.fs-icons-div{
    margin-top: 1.2em;
}

.fs-google-play{
    height: 40px;
    margin-right: 10px;
}

.fs-app-store{
    height: 40px;
}

.fs-timeline-circle{
    height: 6px;
    width: 6px;
}

.divider-line-4{
    border: 1px solid #E9E9EB;
    margin: 25px 0px;
}