.ps-skeleton-div-1{
    height: 100px;
    width: 100%
}

.ps-skeleton-div-2{
    height: 10px;
    width: 100%;
    margin: 10px 0px;
}

.ps-skeleton-div-3{
    height: 20px;
    width: 60%;
    margin: 10px 0px;
}

.ps-skeleton-div-4{
    height: 40px;
    width: 100%;
    margin: 10px 0px;
}

.ps-skeleton{
    margin-top: 20px;
}


.od-sk-left{
     height: 500px;
     border: 1px solid #D5D5D5;
}

.od-sk-right-div-1{
    height: 140px;
    margin: 20px 0px;
    border: 1px solid #D5D5D5;
}


.ps-skeleton-mobile
    {
        display:none;
    }

    .ps-skeleton-desktop
    {
        display:none;
    }
@media (max-width: 600px){
    .ps-skeleton-mobile
    {
        display:block;
        margin-top: 20px;
    }

    .ps-skeleton-desktop
    {
        display:none;
    }
}

@media (min-width: 600px){
    .ps-skeleton-desktop
    {
        display:flex;
        margin-top: 30px;
    }

    .ps-skeleton-mobile
    {
        display:none;
    }
}

.order-sk-card{
    margin: 20px 0px;
    min-height: 200px;
    border: 1px solid #D5D5D5;
    border-radius: 6px;
}

.order-sk-card-top{
    min-height: 60px;
    border: 1px solid #D5D5D5;
    border-radius: 6px;

}

.order-sk-card-detail{
    min-height: 140px;
}

.ps-skeleton-div-1,.ps-skeleton-div-2,.ps-skeleton-div-3,.ps-skeleton-div-4,.order-sk-card-top, .order-sk-card-detail, .od-sk-left, .od-sk-right-div-1{
    animation: loading 1.1s infinite linear;

    background: #dedfe1;
    
    background-image: -webkit-linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
    
    background-image: linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
    
    background-repeat: no-repeat;
}

.ps-skeleton-div-2,.order-sk-card-top{
    animation-delay: 0.7s;
}

.ps-skeleton-div-3{
    animation-delay: 1s;
}

.ps-skeleton-div-4{
    animation-delay: 1.3s;
}