.shop-category-image{
    height: 80px;
    width: 80px;
    margin-bottom: 12px;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.shop-main-card{
    display: block;
    border-radius: 10px;
}

.red-font{
    color: #FF5858;
}

.shop-topslide-div{
    background-color: #0F0F2D;
    min-height: 250px;
    display: block;
    margin-bottom: 2em;
    padding: 20px 20px;
}

.shop-topslide-image{
    display: block;
    width: 100%;
    height: auto;
    border-radius: 15px;
}

.shop-category-title-div{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 1em 15px;
    max-width: 1200px;
    margin-bottom: 6px;
  //  align-items: center;
}

.shop-category-title{
    font-family: 'poppins-bold';
    font-size: 14px;
  //  margin-bottom: 20px;
}

.shop-product-card{
        margin-bottom: 1.3em;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #D5D5D5;
        border-radius: 10px;
        position: relative;
}

.topslide-gradient{
    background: transparent linear-gradient(103deg, #17EAD9 0%, #6078EA 100%) 0% 0% no-repeat padding-box;
}

.topslide-grey{
    background: #D5D5D5 0% 0% no-repeat padding-box;
}
.shop-red-arrow{
     height: 16px;
     width: 16px;
     margin-left: 5px;
}

.shop-category-title-2{
    font-size: 13px;
    font-family: 'lato-bold';
    display: flex;
    align-items: center;
}

.shop-product-image{
    width: 100%;
    height:150px;
    object-fit: contain;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.shop-product-image-wrapper{
    min-width: 100%;
    min-height:150px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.shop-products-div{
    padding: 0px 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 40px;
}

.shop-category-div{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-radius: 10px;
    padding: 60px 15px;
    gap: 15px;
    justify-items: center;
    background-image: linear-gradient(white,rgb(248, 248, 180),rgb(166, 247, 202),rgb(225, 248, 244),white);
   // backdrop-filter: blur(100px);
   // -webkit-backdrop-filter: blur(50px);
    //background: linear-gradient(white,rgb(255, 243, 220),rgb(255, 243, 220),rgb(232, 255, 232),rgb(201, 245, 238),white);
}

@media (max-width: 600px){
   
}

@media (min-width: 1200px){

    .shop-category-image{
        height: 100px;
        width: 100px;
    }

    .shop-category-div{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        margin-bottom: 1.5em;
    }

    .shop-topslide-div{
       padding: 20px 40px;
    }

    .shop-product-image{
        height:170px;
    }
    

    .shop-topslide-image{
        display: block;
        height:300px;
        border-radius: 15px;
        margin: 0px auto;
        width: auto;
    }

    .shop-products-div{
            padding: 0px 30px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 20px;
            max-width: 1200px;
    }

    .shop-category-title-div{
        padding: 1em 30px;
        margin-bottom: 12px;
    }

    .shop-product-image-wrapper{
        min-height:170px;
    }

    .shop-category-title{
        font-size: 21px;
    }

    .shop-category-title-2{
        font-size: 18px;
    }

    .shop-products-div{
        margin-bottom: 50px;
    }

    .shop-red-arrow{
        height: 24px;
        width: 24px;
   }
}