.input-box{
    background: #F4F3F8 0% 0% no-repeat padding-box;
    border: 1px solid #D5D5D5;
    border-radius: 10px;
    opacity: 1;
    height: 40px;
    padding: 5px 20px;
    width: 100%;
    box-sizing: border-box;
}

.input-box:active, .input-box:focus{
    border: 1px solid $blue;
    outline: none;
}

.text-capitalize{
     text-transform: capitalize;
}

.btn-blue-1{
    background: #3E7EFF 0% 0% no-repeat padding-box;
    border: 1px solid #3E7EFF;
    border-radius: 29px;
    color: $white;
}

.btn-white{
    background: $white 0% 0% no-repeat padding-box;
    border: 1px solid #3E7EFF;
    border-radius: 29px;
    color: #3E7EFF;
}

.btn-grey-1{
    background: #F4F3F8 0% 0% no-repeat padding-box;
    border-radius: 29px;
    opacity: 1;
    color: $black;
    outline: none;
    border: none;
}

.btn-blue-1:active, .btn-grey-1:active{
  outline: none;
  border: none;
}

.border-1{
    border: 1px solid #D5D5D5;
    border-radius: 10px;
}

.g-card{
   background-color: $white;
   padding: 35px 40px;
}

.column-gap-20{
    column-gap: 20px;
}

.count-controller{
   display: flex;
   box-shadow: 0px 10px 50px #FFFFFF; 
   border: 1px solid #D5D5D5;
   border-radius: 10px;
   padding: 6px 10px;
   justify-content: space-between;
   align-items: center;
   max-width: 150px; 
}

.line-through{
    text-decoration: line-through;
}

.space-around{
    justify-content: space-around;
}

.justify-flex-end{
    justify-content: flex-end;
}

.no-border{
    border: none;
}

.mt-px-10{
    margin-top: 10px;
}

.mb-px-10{
    margin-bottom: 10px;
}

.mt-px-20{
    margin-top: 20px;
}

.flex-auto{
    flex: auto;
}

.relative{
    position: relative;
}

.new-desktop-only{
    display: block;
}

.new-mobile-only{
    display: none;
}

@media (max-width: 900px) {
    .new-desktop-only{
        display: none;
    }
    
    .new-mobile-only{
        display: block;
    }
}

@media (max-width: 600px){
    .g-card{
        padding: 30px 10px;
    }

    .btn-blue-1{
       display: block;
       width: 100%;
    }
}


