
.navbar-custom{
    background-color: $white;
    position: fixed;
    left: 0;
    right: 0;
    top:0px;
    z-index: 100;
    padding: 0px 0px;
    width:100%;
    outline: none;
}   

.nav-options-logo-div{
    transition: 0.3s;
}



@media (max-width: 600px){
    .navbar-custom{
        position: sticky;
        left: 0;
        right: 0;
        top:-60px;
        z-index: 100;
    }   

    .nav-bar-scroll-up{
    top: 0px;
}
}



.mobile-nav-top{
    display: flex;
    align-items: center;
}

.mobile-only-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0px 20px;
}

.mobile-only-nav{
    display: flex;
}

.logo-div{
}

.tkm-top-logo{
    height: 50px;
    width: 50px;
    transition: 0.5s;
}

.tkm-top-logo:hover{
     transform: scale(1.1);
}

.top-nav-options{
   // display: inline-block;
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    overflow-x: auto;
    gap: 0px;
}

@media(min-width: 600px) and (max-width: 980px){
     .top-nav-options{
        max-width: 600px;
     }
}


@media(min-width: 980px) and (max-width: 1200px){
    .top-nav-options{
        max-width: 600px;
     }
}

.user-profile-logo{
   display: none;
}

.active-link-div{
    display: flex;
}

.nav-options-logo-2{
  height: 24px;
  width: 24px;
  margin-right: 5px;
}


  .nav-options-logo-div{
    flex:1;
    justify-content: stretch;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 8px;
    outline: none;
    border-bottom: 8px solid #F4F3F8;
  }

  .active-border-bottom{
    border-bottom: 6px solid $blue;
}


  .shop-logo-div{
    flex:1 ;
  }

  .business-logo-div{
    flex:1 ;
  }


.cursor{
    cursor: pointer;
}

.nav-options-text{
    font: normal normal 200 $font-18 $poppins;
    font-family: 'Poppins', Roboto;
    color: #979EA4;
    font-size: 16px;
}

.username-text{
    font: normal normal 300 $font-18 $poppins;
    font-family: 'Poppins', Roboto;
    color: $black;
}

.active-font-color{
    color: $blue;
}



.small-icon{
    height: $logo-small-height;
    width: $logo-small-height;
}

.mr-40{
    margin-right:$mr-40;
}

.mr-30{
    margin-right: 30px;
}

.right-div-flex{
    padding-top:25px;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (max-width: 600px) { 
   .nav-options-logo-div{
     //  min-width: 24%;
       text-align: center;
       padding: 5px 10px;
   }

   .nav-options-logo-2{
    margin-right: 0px;
  }
}

 @media (max-width: 1200px) { 

    .username-text{
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    }
 }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

    
.nav-options-logo-div:hover{
    background-color: #F4F3F8;
  //  border-top: 6px solid #F4F3F8;
    border-bottom: 3px solid #F4F3F8;
    transition-property: background-color;
    border-radius: 10px 10px 0px 0px;
    transition: 0.6s ease-out;

    a{
        letter-spacing: 1px;
        transition: 0.6s;
    }
}

@keyframes hover-menu {
    0%{
       background-color: white;
    }
    100%{
        background-color: #F4F3F8;
    }
}

    .mobile-nav-top{
        display: block;
    }

    .navbar-custom{
        display: flex;
        height : $topbarheight;
    }

    .right-div{
        display: inline-block;
        height: 100%;
        width: calc(100% - $menuwidth);
        padding:0px 30px 0px 0px;
    }

    .logo-div{
        width: $menuwidth;
        display: flex;
        align-items: center;
        padding: 0px 30px;
    }

    .tkm-top-logo{
        height: 65px;
        width: 65px;
    }

    .nav-options-logo{
        height: 30px;
        width: 30px;
        margin-right: 8px;
      }

      .nav-options-profile-image{
        height: 30px;
        width: 30px;
        margin-right: 8px;
        object-fit: cover;
        border-radius: 50%;
        border: 2px solid $grey-faded;
      }

      .user-profile-logo{
       // flex:1;
     }

     .user-profile-logo{
        display: flex;
        justify-content: flex-end;
        align-items: center;
       // margin-left:30px;
    }

    .right-div-flex{
        padding: 0px;
        height: 100%;
        gap: 36px;
      //  margin: 0px 50px 0px 50px;
    }

    .nav-options-text{
        font: normal normal 200 $font-18 $poppins;
    }

    .top-nav-options{
        // display: inline-block;
         display: flex;
         align-items: center;
         padding:0% 5px;
         flex: 1.4;
         justify-content: stretch;
         text-align: center;
         margin-right: 0px;
         height: 100%;
        // gap: 20px;
     }

     .nav-options-logo-div{
      //  flex:1 ;
        justify-content: stretch;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 6px solid $white;
        border-bottom: 3px solid $white;
        max-width: 150px;
        transition: 0.3s;
      }

      .active-border-bottom{
        border-bottom: 3px solid $blue;
    }

    @keyframes flash_border {
        0% {
          border-bottom-color: blue;
        }
        50% {
            border-bottom-color: blue;
        }
        100% {
            border-bottom-color: blue;
        }
      }
    
    .mobile-only-div{
        display: block;
        padding:0px;
    }
    
    .mobile-only-nav{
        display: none;
    }

    .nav-options-logo-2{
        height: 25px;
        width: 25px;
        margin-right: 5px;
      }
    
 }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1600px) { 
    .navbar-custom{
        padding-left: calc((100vw - 1600px)/2);
        padding-right: calc((100vw - 1600px)/2);
    }  
 }