.food-checkout-main-div{
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    position: fixed;
    background-color: white;
    padding: 30px 0px;
    z-index: 1000;
    max-height: 100vh;
    overflow-y: scroll;
}



.food-checkout-font-1{
    font-family: poppins-medium;
    font-weight: 600;
    font-size: 12px;
}

.food-checkout-font-2{
    font-family: poppins-medium;
    font-weight: 500;
    font-size: 20px;
}

.food-checkout-font-3{
    font-family: poppins-light;
    font-weight: 500;
    font-size: 12px;
}

.food-checkout-font-4{
    font-family: poppins-medium;
    font-weight: 400;
    font-size: 16px;
}

.food-checkout-font-5{
    font-family: lato-regular;
    font-weight: 400;
    font-size: 16px;
}

.food-checkout-font-6{
    font-family: poppins-medium;
    font-weight: 400;
    font-size: 16px;
    color: #93959F;
}

.food-checkout-inner-div{
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 50px;
}

.food-checkout-address-list-div{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}

.food-checkout-desktop-address-div{
    display: none;
    border-right: 3px solid #D5D5D5;
    padding-right: 50px;
}

.food-checkout-mobile-address-div{
    padding: 15px 0px;
}

.middle-gap{
    height: 25px;
    background-color: #F4F3F8;
}

.food-checkout-address-card{
        padding: 20px;
        border: 0.5px solid #000000;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
}

.food-checkout-mobile-address-card{
    margin: 20px 0px;
    padding: 15px;
}

.not-selected-address{
  background-color: #F9F7FF;
}

.selected-address{
    background-color: #70FF431C;
  //  background: rgba(112, 255, 67, 0.11);
}

.food-checkout-address-desktop-new-card{
       min-height: 250px;
}

.align-self-center{
    align-self: center;
}

.food-checkout-mobile-new-address-card{
    margin: 20px 15px;
    background-color: #F9F7FF;
    padding: 15px;
}

.d-flex{
    display: flex;
}

.title-1{
    margin: 40px 0px;
}

.fixed-cart-div{
    display: none;
}

.veg-symbol{
    height: 24px;
    width: 24px;
    border-radius: 2px;
    border: 1px solid #4CBA09;
    display: flex;
    align-items: center;
    justify-content: center;
}

.non-veg-symbol{
    height: 24px;
    width: 24px;
    border-radius: 2px;
    border: 1px solid #FF5858;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-title-info-div{
    display: flex;
    gap: 20px;
}

.veg-symbol-circle{
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #4CBA09;
}

.non-veg-symbol-circle{
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #FF5858;
}

.restaurant-menu-divider-line-2{
    border-bottom: 2px solid #D5D5D5;
    margin: 16px 0px;
}

.menu-title-info-div{
    display: flex;
    gap: 20px;
}

.btn-1{
    background-color:#3E7EFF;
    font-size: 14px;
    font-family: poppins-medium;
    color: white;
    padding: 12px 30px;
    border-radius: 12px;
    margin-top: 25px;
 }

 .btn-2-div{
 }

 .btn-2{
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    border-radius: 5px;
    font-size: 14px;
    font-family: poppins-medium;
    color: #4CBA09;
    padding: 10px 15px;
 }

 .btn-4{
    padding: 10px 15px;
    margin-top: 0px;
    border-radius: 20px;
}

.food-checkout-cart-list-div{
    margin-top: 20px;
}

.food-checkout-cart-div{
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
}

.address-select-btn{
    border: 1px solid #3E7EFF;
    border-radius: 4px;
    color: #3E7EFF;
    padding: 15px 40px;
    margin-top: 15px;
}

.address-selected-btn{
    border: 1px solid #3E7EFF;
    border-radius: 4px;
    color: white;
    background-color: #3E7EFF;
    padding: 15px 40px;
    margin-top: 15px;
}

button:focus, button:active, .toggle-btn:focus{
    outline: none;
    box-shadow: none;
}

.food-cart-add-new-address{
    position: fixed;
    top: 0px;
    left: 0px;
    right : 0px;
    bottom: 0px;
    width: 100vw;
    height: 100vh;
    background: rgba(19, 20, 24, 0.5);
    overflow: hidden;
    display: none;
    z-index: 1001;
}

.food-cart-add-new-address-card{
    width: 100vw;
    background-color: white;
    padding: 0px 10px;
    padding-bottom: 20px;
    height: 100%;
    overflow-y: auto;
    z-index: 1001;
 }

 .add-address-title-1{
     margin: 30px 0px 40px 0px;
 }


@media(max-width: 600px){

    .food-checkout-main-div{
           padding-bottom: 100px;
    }

    .add-address-title-1{
        margin: 20px 0px 20px 0px;
    }

    .fixed-cart-div{
        min-height: 80px;
        position: fixed;
        display: block;
        bottom: 0px;
        background-color: #0F0F2D;
        color: white;
        padding: 15px;
        left: 0px;
        right: 0px;
    }
}

@media (min-width: 1200px){

    .food-checkout-main-div{
        position: relative;
        background-color: white;
        min-height: 90vh;
        padding: 30px 20px 30px 40px;
        overflow: auto;
        max-height: none;
        z-index: 1;
        width: 100%;
    }

    .food-checkout-font-2{
        font-size: 22px;
    }

    .food-checkout-font-3{
        font-size: 16px;
    }

    .food-checkout-font-4{
        font-size: 16px;
    }
    
    .food-checkout-font-5{
        font-size: 16px;
    }

    .food-checkout-font-6{
        font-size: 15px;
    }

    .align-self-desktop{
        align-self: center;
    }

    .food-checkout-inner-div{
        display: grid;
        grid-template-columns: 2fr 1fr;
    }

    .food-checkout-desktop-address-div{
        display: block;
    }

    .food-checkout-address-div{
        border-right: 3px solid #D5D5D5;
        padding-right: 50px;
    }

    .food-checkout-cart-list-div{
        margin-top: 20px;
    }

    .food-checkout-cart-div{
        padding: 0px;
    }

    .btn-1{
        font-size: 16px;
        padding: 15px 30px;
        margin-top: 40px;
     }
    
     .btn-3{
        font-size: 16px;
        padding: 10px 30px;
        margin-top: 20px;
        display: block;
        width: 100%;
     }

     .food-cart-add-new-address{
        display: none;
        height: 100vh;
        top: 0vh;
        z-index: 1000 !important;
        background: rgba(black, 0.5);
        }

    .food-cart-add-new-address-card{
       width: 40vw;
       background-color: white;
       padding: 80px 60px;
       height: 100%;
       overflow-y: auto;
    }

    .food-cart-new-address-scroll::-webkit-scrollbar {
        background-color: white;
        width: 15px;
      }
    
      .food-cart-new-address-scroll::-webkit-scrollbar-thumb{
        background-color: #D5D5D5;
        border-radius: 8px;
      }
    
      .food-cart-new-address-scroll::-webkit-scrollbar{
        background-color: white;
        width: 15px;
      }
    
      .food-cart-new-address-scroll:hover::-webkit-scrollbar-thumb{
        background-color: #D5D5D5;
        border-radius: 8px;
      }
    

}

.food-cart-close-icon{
    margin-right: 10px;
}

@media (min-width: 1600px){

    .food-cart-add-new-address{
        left: calc((100vw - 1610px)/2);
        display: none;
    }

    .food-cart-add-new-address-card{
        width: 30vw;
    }
}