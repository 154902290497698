.verify-otp-main-div{

}

.p-verify-otp-div{
   padding:20px 40px;
}

.verify-otp-title{
    padding: 20px;
}

.modal-close-btn-2{
    position: absolute;
    right: 20px;
    top: 20px;
}

.vo-divider{
    width: 100%;
    border-top: 1px solid #D5D5D5;
    display: block;
    margin: 0px 0px 20px;
}

.verify-otp-text{
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: 20px;
}

.verify-otp-text-2{
    font-size: 15px;
}

.p-verify-btn-div{
    margin-top: 20px;
    margin-bottom: 20px;
}