/*@font-face {
  font-family: 'muli-bold';
  src: url('../fonts/Muli-Bold.ttf');
}

@font-face {
  font-family: 'muli-regular';
  src: url('../fonts/Muli.ttf');
}

@font-face {
  font-family: 'muli-light';
  src: url('../fonts/Muli-Light.ttf');
}

@font-face {
  font-family: 'muli-extralight';
  src: url('../fonts/Muli-ExtraLight.ttf');
}

@font-face {
  font-family: 'muli-semibold';
  src: url('../fonts/Muli-SemiBold.ttf');
}
*/

@font-face {
  font-family: 'poppins-semibold';
  src: url('../fonts/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'poppins-bold';
  src: url('../fonts/Poppins-Bold.ttf');
}


@font-face {
  font-family: 'poppins-medium';
  src: url('../fonts/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'poppins-light';
  src: url('../fonts/Poppins-Light.ttf');
}

@font-face {
  font-family: 'poppins-extrabold';
  src: url('../fonts/Poppins-ExtraBold.ttf');
}

@font-face {
  font-family: 'lato-bold';
  src: url('../fonts/Lato-Bold.ttf');
}

@font-face {
  font-family: 'lato-regular';
  src: url('../fonts/Lato-Regular.ttf');
}

.font-black{
  color: black;
}
