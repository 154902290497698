@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/** Colors variables */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
.sb-default .sb-wrapper {
  --sb-color: #fff;
  --sb-background: var(--button-color);
  --sb-border-radius: 4px;
  --sb-overflow: hidden;
}
.sb-default .sb-wrapper.sb-show-icon.sb-show-text {
  --sb-text-padding: 0 1em 0 0;
}
.sb-default .sb-wrapper.sb-show-icon.sb-show-text .sb-text {
  filter: none;
}

html, body {
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  position: relative;
}

.new-black-overlay {
  display: none;
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  top: -52px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}

.np-n-border {
  display: block;
  max-width: 100%;
}

.np-n-border-2 {
  display: block;
  max-width: 100%;
  max-height: 120px;
}

@media (max-width: 600px) {
  .np-n-border {
    display: block;
    max-width: 100vw;
    margin: 10px auto;
  }

  .np-n-border-2 {
    display: block;
    max-width: 100vw;
  }
}
input:active {
  border: none;
}

input:focus-within {
  border: none;
}

/* Importing Bootstrap SCSS file. */
/*!
 * Bootstrap v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*
$green1 : rgba(51, 175, 83, 1);
$green2 : rgba(72, 202, 105, 1);
$green3 : rgba(108, 212, 135, 1);
$green4 : rgba(145, 223, 165, 1);
$green5 : rgba(182, 234, 195, 1);
$green6 : rgba(218, 244, 225, 1);
$green_shadow : 0px 10px 10px rgba(51, 175, 83, 0.05);

*/
@font-face {
  font-family: "Lato-Bold";
  font-style: normal;
  font-weight: bold;
  src: url("/assets/fonts/Lato-Bold.ttf");
}
@font-face {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Lato-Regular.ttf");
}
@font-face {
  font-family: "Lato-Black";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Lato-Black.ttf");
}
@font-face {
  font-family: "WorkSans-ExtraBold";
  font-style: normal;
  font-weight: bold;
  src: url("/assets/fonts/WorkSans-ExtraBold.ttf");
}
@font-face {
  font-family: "WorkSans-Bold";
  font-style: normal;
  font-weight: bold;
  src: url("/assets/fonts/WorkSans-Bold.ttf");
}
@font-face {
  font-family: "WorkSans-Medium";
  font-style: normal;
  font-weight: medium;
  src: url("/assets/fonts/WorkSans-Medium.ttf");
}
@font-face {
  font-family: "WorkSans-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/WorkSans-Regular.ttf");
}
@font-face {
  font-family: "WorkSans-semiBold";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/WorkSans-SemiBold.ttf");
}
.text-c-warning {
  color: red;
}

/*
@font-face {
  font-family: 'Courier-Bold';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/Courier-Bold.ttf');
}

@font-face {
  font-family: 'Courier-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/Courier-Regular.ttf');
}

:host {
  --color : rgb(32, 52, 66) ;
  color : rgb(32, 52, 66) ;
}

*{
  --color : rgb(32, 52, 66) ;
  color : rgb(32, 52, 66) ;
  line-height: 1.4;
}



.button-normal{
  min-width: 120px;
  color: #fff;
  border-radius: 5px;
  --border-radius: 5px;
  background: $blue1;
  --background: $blue1;
  font-family: WorkSans-SemiBold;
  font-size: $h6;
  --padding-start: 16px;
  --padding-end: 16px;
  --padding-top: 16px;
  --padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-height: 42px;
  --background-activated: none;
  outline: none;
  --box-shadow: none !important;
  box-shadow: none !important;
}

.tiny-button{
  color: #fff;
  border-radius: 5px;
  --border-radius: 5px;
  background: $blue1;
  --background: $blue1;
  font-family: WorkSans-SemiBold;
  font-size: $h7;
  --padding-start: 14px;
  --padding-end: 14px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-height: 42px;
  --background-activated: none;
  outline: none;
  --box-shadow: none !important;
  box-shadow: none !important;
}

.naked-button {
  background: none;
  color: $blue1;
}

.ghost-button{
  background: $white;
  color: $blue1;
  border: 2px solid $blue1;
}

.header-back{
  margin-right: 20px;
}

.header-title{
  font-size: $h4;
  font-family: WorkSans-Medium
}


.normal-card{
  box-shadow: $black_shadow;
}

.normal-card-pad{
  box-shadow: $black_shadow;
      border-radius: 5px;
      padding: 20px;
}
*/
.poppins-16-normal {
  font: normal normal normal 16px Poppins;
}

.poppins-18-normal {
  font: normal normal normal 18px Poppins;
}

.poppins-20-normal {
  font: normal normal normal 20px Poppins;
}

.poppins-light {
  font-family: poppins-light;
}

.poppins-regular {
  font-family: poppins-regular;
}

.poppins-medium {
  font-family: poppins-medium;
}

.poppins-semibold {
  font-family: poppins-bold;
  font-weight: 600;
}

.lato-regular {
  font-family: lato-regular;
}

.heading-4 {
  font-family: poppins-medium;
  font-size: 18px;
  font-weight: 500;
}

.sub-header-1 {
  font-family: poppins-medium;
  font-size: 16px;
}

.input-label {
  font-family: poppins-light;
  font-size: 14px;
}

.modal-title {
  font: normal normal 600 21px Poppins;
  color: #0F0F2D;
  margin-bottom: 1.5em;
}

@media (max-width: 600px) {
  .modal-title {
    font-size: 16px;
  }

  .font-px-18 {
    font-size: 8px;
  }
}
.black-1 {
  color: #0F0F2D;
}

.blue {
  color: #3E7EFF;
}

.font-blue {
  color: #3E7EFF;
}

.font-black {
  color: black;
}

.font-grey {
  color: #979EA4;
}

.font-white {
  color: white;
}

.font-px-18 {
  font-size: 18px;
}

/*@font-face {
  font-family: 'muli-bold';
  src: url('../fonts/Muli-Bold.ttf');
}

@font-face {
  font-family: 'muli-regular';
  src: url('../fonts/Muli.ttf');
}

@font-face {
  font-family: 'muli-light';
  src: url('../fonts/Muli-Light.ttf');
}

@font-face {
  font-family: 'muli-extralight';
  src: url('../fonts/Muli-ExtraLight.ttf');
}

@font-face {
  font-family: 'muli-semibold';
  src: url('../fonts/Muli-SemiBold.ttf');
}
*/
@font-face {
  font-family: "poppins-semibold";
  src: url("../fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: "poppins-bold";
  src: url("../fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "poppins-medium";
  src: url("../fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: "poppins-light";
  src: url("../fonts/Poppins-Light.ttf");
}
@font-face {
  font-family: "poppins-extrabold";
  src: url("../fonts/Poppins-ExtraBold.ttf");
}
@font-face {
  font-family: "lato-bold";
  src: url("../fonts/Lato-Bold.ttf");
}
@font-face {
  font-family: "lato-regular";
  src: url("../fonts/Lato-Regular.ttf");
}
.font-black {
  color: black;
}

.input-box {
  background: #F4F3F8 0% 0% no-repeat padding-box;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
  height: 40px;
  padding: 5px 20px;
  width: 100%;
  box-sizing: border-box;
}

.input-box:active, .input-box:focus {
  border: 1px solid #3E7EFF;
  outline: none;
}

.text-capitalize {
  text-transform: capitalize;
}

.btn-blue-1 {
  background: #3E7EFF 0% 0% no-repeat padding-box;
  border: 1px solid #3E7EFF;
  border-radius: 29px;
  color: white;
}

.btn-white {
  background: white 0% 0% no-repeat padding-box;
  border: 1px solid #3E7EFF;
  border-radius: 29px;
  color: #3E7EFF;
}

.btn-grey-1 {
  background: #F4F3F8 0% 0% no-repeat padding-box;
  border-radius: 29px;
  opacity: 1;
  color: #232629;
  outline: none;
  border: none;
}

.btn-blue-1:active, .btn-grey-1:active {
  outline: none;
  border: none;
}

.border-1 {
  border: 1px solid #D5D5D5;
  border-radius: 10px;
}

.g-card {
  background-color: white;
  padding: 35px 40px;
}

.column-gap-20 {
  column-gap: 20px;
}

.count-controller {
  display: flex;
  box-shadow: 0px 10px 50px #FFFFFF;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  padding: 6px 10px;
  justify-content: space-between;
  align-items: center;
  max-width: 150px;
}

.line-through {
  text-decoration: line-through;
}

.space-around {
  justify-content: space-around;
}

.justify-flex-end {
  justify-content: flex-end;
}

.no-border {
  border: none;
}

.mt-px-10 {
  margin-top: 10px;
}

.mb-px-10 {
  margin-bottom: 10px;
}

.mt-px-20 {
  margin-top: 20px;
}

.flex-auto {
  flex: auto;
}

.relative {
  position: relative;
}

.new-desktop-only {
  display: block;
}

.new-mobile-only {
  display: none;
}

@media (max-width: 900px) {
  .new-desktop-only {
    display: none;
  }

  .new-mobile-only {
    display: block;
  }
}
@media (max-width: 600px) {
  .g-card {
    padding: 30px 10px;
  }

  .btn-blue-1 {
    display: block;
    width: 100%;
  }
}
.navbar-custom {
  background-color: white;
  position: fixed;
  left: 0;
  right: 0;
  top: 0px;
  z-index: 100;
  padding: 0px 0px;
  width: 100%;
  outline: none;
}

.nav-options-logo-div {
  transition: 0.3s;
}

@media (max-width: 600px) {
  .navbar-custom {
    position: sticky;
    left: 0;
    right: 0;
    top: -60px;
    z-index: 100;
  }

  .nav-bar-scroll-up {
    top: 0px;
  }
}
.mobile-nav-top {
  display: flex;
  align-items: center;
}

.mobile-only-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}

.mobile-only-nav {
  display: flex;
}

.tkm-top-logo {
  height: 50px;
  width: 50px;
  transition: 0.5s;
}

.tkm-top-logo:hover {
  transform: scale(1.1);
}

.top-nav-options {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  overflow-x: auto;
  gap: 0px;
}

@media (min-width: 600px) and (max-width: 980px) {
  .top-nav-options {
    max-width: 600px;
  }
}
@media (min-width: 980px) and (max-width: 1200px) {
  .top-nav-options {
    max-width: 600px;
  }
}
.user-profile-logo {
  display: none;
}

.active-link-div {
  display: flex;
}

.nav-options-logo-2 {
  height: 24px;
  width: 24px;
  margin-right: 5px;
}

.nav-options-logo-div {
  flex: 1;
  justify-content: stretch;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 8px;
  outline: none;
  border-bottom: 8px solid #F4F3F8;
}

.active-border-bottom {
  border-bottom: 6px solid #3E7EFF;
}

.shop-logo-div {
  flex: 1;
}

.business-logo-div {
  flex: 1;
}

.cursor {
  cursor: pointer;
}

.nav-options-text {
  font: normal normal 200 18px Poppins;
  font-family: "Poppins", Roboto;
  color: #979EA4;
  font-size: 16px;
}

.username-text {
  font: normal normal 300 18px Poppins;
  font-family: "Poppins", Roboto;
  color: #232629;
}

.active-font-color {
  color: #3E7EFF;
}

.small-icon {
  height: 24px;
  width: 24px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-30 {
  margin-right: 30px;
}

.right-div-flex {
  padding-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .nav-options-logo-div {
    text-align: center;
    padding: 5px 10px;
  }

  .nav-options-logo-2 {
    margin-right: 0px;
  }
}
@media (max-width: 1200px) {
  .username-text {
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
@media (min-width: 1200px) {
  .nav-options-logo-div:hover {
    background-color: #F4F3F8;
    border-bottom: 3px solid #F4F3F8;
    transition-property: background-color;
    border-radius: 10px 10px 0px 0px;
    transition: 0.6s ease-out;
  }
  .nav-options-logo-div:hover a {
    letter-spacing: 1px;
    transition: 0.6s;
  }

  @keyframes hover-menu {
    0% {
      background-color: white;
    }
    100% {
      background-color: #F4F3F8;
    }
  }
  .mobile-nav-top {
    display: block;
  }

  .navbar-custom {
    display: flex;
    height: 70px;
  }

  .right-div {
    display: inline-block;
    height: 100%;
    width: calc(100% - 250px);
    padding: 0px 30px 0px 0px;
  }

  .logo-div {
    width: 250px;
    display: flex;
    align-items: center;
    padding: 0px 30px;
  }

  .tkm-top-logo {
    height: 65px;
    width: 65px;
  }

  .nav-options-logo {
    height: 30px;
    width: 30px;
    margin-right: 8px;
  }

  .nav-options-profile-image {
    height: 30px;
    width: 30px;
    margin-right: 8px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #F4F3F8;
  }

  .user-profile-logo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .right-div-flex {
    padding: 0px;
    height: 100%;
    gap: 36px;
  }

  .nav-options-text {
    font: normal normal 200 18px Poppins;
  }

  .top-nav-options {
    display: flex;
    align-items: center;
    padding: 0% 5px;
    flex: 1.4;
    justify-content: stretch;
    text-align: center;
    margin-right: 0px;
    height: 100%;
  }

  .nav-options-logo-div {
    justify-content: stretch;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 6px solid white;
    border-bottom: 3px solid white;
    max-width: 150px;
    transition: 0.3s;
  }

  .active-border-bottom {
    border-bottom: 3px solid #3E7EFF;
  }

  @keyframes flash_border {
    0% {
      border-bottom-color: blue;
    }
    50% {
      border-bottom-color: blue;
    }
    100% {
      border-bottom-color: blue;
    }
  }
  .mobile-only-div {
    display: block;
    padding: 0px;
  }

  .mobile-only-nav {
    display: none;
  }

  .nav-options-logo-2 {
    height: 25px;
    width: 25px;
    margin-right: 5px;
  }
}
@media (min-width: 1600px) {
  .navbar-custom {
    padding-left: calc((100vw - 1600px) / 2);
    padding-right: calc((100vw - 1600px) / 2);
  }
}
/*
.content-page{
    background-color: $grey-faded;
    position: relative;
    top: $topbarheight;
    left: $menuwidth;
    bottom: 0px;
    right: 0px;
    min-height: 100%;
}
*/
@media (max-width: 600px) {
  #wrapper {
    position: relative;
    width: 100vw;
    display: block;
    margin: auto;
  }
}
@media (min-width: 600px) and (max-width: 1200px) {
  #wrapper {
    background-color: #F4F3F8;
    position: relative;
  }
}
#wrapper {
  position: relative;
  max-width: 1600px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.left-side-menu {
  display: none;
}

.create-post-btn {
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: 0.5s ease;
}

.create-post-btn:hover {
  transform: scale(1.05);
}

@media (min-width: 600px) and (max-width: 1200px) {
  .create-post-btn {
    display: block;
    height: 50px;
    border-radius: 21px;
    width: 300px;
    background-color: #3E7EFF;
    border: none;
    color: white;
    background: var(---3e7eff) 0% 0% no-repeat padding-box;
    background: #3E7EFF 0% 0% no-repeat padding-box;
    margin: 20px auto 10px;
    filter: blur(-1px);
    z-index: 2;
  }

  .btn-below {
    display: block;
    position: relative;
    top: -20px;
    margin: 0 auto;
    filter: blur(15px);
    height: 30px;
    width: 200px;
    background-color: #3E7EFF;
    z-index: 0;
  }

  .mr-8 {
    margin-right: 8px;
  }

  .menu-icon {
    height: 25px;
    width: 25px;
  }

  .nav-top-margin {
    margin-top: 24px;
  }

  .menu-items-top-margin {
    margin-top: 28px;
  }

  .menu-items-bottom-margin {
    margin-top: 30px;
  }

  .side-menu-div li {
    list-style: none;
    list-style-position: inside;
    margin-left: 0px;
  }

  ul .side-menu-list li {
    list-style: none;
    list-style-position: inside;
    margin-left: 0px;
    padding-left: 0px;
  }

  .menu-item-text {
    font: normal normal normal 15px Poppins;
    letter-spacing: 0px;
    color: #0F0F2D;
  }

  .menu-divider {
    border: 1px solid #F4F3F8;
    margin: 30px 0px 0px 0px;
  }
}
@media (max-width: 600px) {
  .create-post-btn {
    display: block;
    height: 50px;
    border-radius: 21px;
    width: 80%;
    background-color: #3E7EFF;
    border: none;
    color: white;
    background: var(---3e7eff) 0% 0% no-repeat padding-box;
    background: #3E7EFF 0% 0% no-repeat padding-box;
    margin: 20px auto 10px;
    filter: blur(-1px);
    z-index: 2;
  }

  .btn-below {
    display: block;
    position: relative;
    top: -20px;
    margin: 0 auto;
    filter: blur(15px);
    height: 30px;
    width: 70%;
    background-color: #3E7EFF;
    z-index: 0;
  }
}
@media (max-width: 700px) {
  .mr-8 {
    margin-right: 8px;
  }

  .menu-icon {
    height: 25px;
    width: 25px;
  }

  .nav-top-margin {
    margin-top: 24px;
  }

  .menu-items-top-margin {
    margin-top: 28px;
  }

  .menu-items-bottom-margin {
    margin-top: 30px;
  }

  .side-menu-div li {
    list-style: none;
    list-style-position: inside;
    margin-left: 0px;
  }

  ul .side-menu-list li {
    list-style: none;
    list-style-position: inside;
    margin-left: 0px;
    padding-left: 0px;
  }

  .menu-item-text {
    font: normal normal normal 15px Poppins;
    letter-spacing: 0px;
    color: #0F0F2D;
  }

  .menu-divider {
    border: 1px solid #F4F3F8;
    margin: 30px 0px 0px 0px;
  }
}
@media (min-width: 1200px) {
  .left-side-menu {
    position: relative;
    display: block;
    max-width: 250px;
    padding: 30px 20px 30px 10px;
    max-height: 100%;
    overflow-y: scroll;
  }

  .left-side-menu::-webkit-scrollbar {
    background-color: white;
    width: 8px;
  }

  .left-side-menu::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .left-side-menu:hover::-webkit-scrollbar {
    background-color: white;
    width: 8px;
  }

  .left-side-menu:hover::-webkit-scrollbar-thumb {
    background-color: #32335E26;
    border-radius: 8px;
  }

  .create-post-btn {
    display: block;
    height: 50px;
    border-radius: 21px;
    width: 100%;
    background-color: #3E7EFF;
    border: none;
    color: white;
    background: var(---3e7eff) 0% 0% no-repeat padding-box;
    background: #3E7EFF 0% 0% no-repeat padding-box;
    margin-top: 20px;
    margin-bottom: 10px;
    filter: blur(-1px);
    z-index: 2;
  }

  .btn-below {
    display: block;
    position: relative;
    top: -20px;
    margin: 0 auto;
    filter: blur(15px);
    height: 30px;
    width: 70%;
    background-color: #3E7EFF;
    z-index: 0;
  }

  .mr-8 {
    margin-right: 8px;
  }

  .menu-icon {
    height: 25px;
    width: 25px;
  }

  .nav-top-margin {
    margin-top: 24px;
  }

  .menu-items-top-margin {
    margin-top: 28px;
  }

  .menu-items-bottom-margin {
    margin-top: 30px;
  }

  .side-menu-div li {
    list-style: none;
    list-style-position: inside;
    margin-left: 0px;
  }

  ul .side-menu-list li {
    list-style: none;
    list-style-position: inside;
    margin-left: 0px;
    padding-left: 0px;
  }

  .menu-item-text {
    font: normal normal normal 15px Poppins;
    letter-spacing: 0px;
    color: #0F0F2D;
  }

  .menu-divider {
    border: 1px solid #F4F3F8;
    margin: 30px 0px 0px 0px;
  }
}
.content-flex {
  position: relative;
  display: block;
}

.content-area-div {
  position: relative;
  display: block;
  top: 170px;
  left: 0;
  right: 0;
}

@media (max-width: 600px) {
  .content-area-div {
    top: 0px;
  }
}
.content-div {
  background-color: #F4F3F8;
  padding-top: 10px;
  position: relative;
  height: auto;
  padding-bottom: 0px;
}

@media (min-width: 600px) and (max-width: 980px) {
  .content-area-div {
    max-width: 600px;
    top: 0px;
    padding-top: 105px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 980px) and (max-width: 1200px) {
  .content-area-div {
    width: 100%;
    top: 0px;
    padding-top: 105px;
    padding-left: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .content-div {
    padding: 30px 10px 0px 30px;
  }
}
@media (min-width: 1200px) {
  .content-area-div {
    position: relative;
    display: block;
    top: 175px;
    left: 0;
    right: 0;
    min-width: 100%;
  }

  .content-flex {
    display: grid;
    grid-template-columns: 250px 1fr;
    height: auto;
  }

  .menu-flex {
    height: calc(100vh - 70px - 3px);
    top: 70px;
    position: sticky;
    background-color: white;
  }

  .content-div {
    background-color: #F4F3F8;
    padding: 30px 10px 0px 30px;
    grid-row: 1;
    grid-column: 2;
  }
}
@media (min-width: 1200px) and (min-width: 1200px) {
  .content-div {
    padding-right: 0px;
  }
}
.search-div {
  height: 40px;
  background-color: white;
  border-radius: 10px;
  padding: 3px 10px;
  display: flex;
  align-items: center;
}

.custom-search-bar {
  border-radius: 10px;
  border: none;
  background-color: white;
  height: 100%;
  width: 80%;
  font: normal normal normal 16px/52px Poppins;
}

.custom-search-bar:focus {
  border: none;
  outline: none;
}

.custom-search-icon {
  padding: 5px 15px 5px 0px;
  width: auto;
  height: 35px;
}

.news-home-grid {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  grid-template-areas: "adv-item" "news-post-item";
  width: 100%;
  position: relative;
}

.adv-item {
  grid-area: adv-item;
  margin-bottom: 14px;
  position: relative;
}

.featured-stories-div {
  display: none;
}

.news-post-item {
  grid-area: news-post-item;
}

.search-location-div {
  display: none;
  position: relative;
}

.location-mob-div {
  display: flex;
  align-items: center;
  padding: 11px 15px;
}

.search-bar-div {
  display: none;
}

.location-text {
  font: normal normal 500 0.8em Poppins;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.location-icon {
  margin-right: 5px;
  height: 18px;
  width: 18px;
}

.topslide-text {
  font: normal normal normal 1em Poppins;
  color: #0F0F2D;
}

.suggestion-profile-image {
  height: 60px;
  width: 60px;
  margin-bottom: 8px;
  border-radius: 50%;
}

.sugg-username-text {
  font: normal normal 500 1.1em Poppins;
  letter-spacing: 0px;
  color: #0F0F2D;
}

.sugg-type-text {
  font: normal normal bold 1.1em Lato;
  letter-spacing: 0px;
  color: #979EA4;
}

@media (min-width: 980px) {
  .news-home-grid {
    display: grid;
    column-gap: 15px;
    grid-template-columns: 2fr 1.4fr;
    grid-template-rows: auto;
    grid-template-areas: "news-post-item adv-item";
    width: 100%;
    padding-top: 10px;
  }

  .featured-stories-div {
    display: block;
  }

  .location-div {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    position: relative;
  }

  .location-icon {
    height: 30px;
    width: 30px;
  }

  .location-mob-div {
    display: none;
  }

  .search-location-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-overflow: ellipsis;
    position: relative;
    max-width: 100%;
  }

  .location-div {
    display: flex;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    padding: 0px 20px;
    position: relative;
  }

  .news-post-item {
    min-height: 100px;
    max-width: 100%;
  }

  .adv-item {
    display: block;
    position: sticky;
    top: 100px;
    height: calc(100vh - 70px - 50px);
    overflow-y: scroll;
    background-color: white;
  }

  .adv-item::-webkit-scrollbar {
    background-color: white;
    width: 8px;
  }

  .adv-item::-webkit-scrollbar-thumb {
    background-color: white;
  }

  .adv-item:hover::-webkit-scrollbar {
    background-color: white;
    width: 8px;
  }

  .adv-item:hover::-webkit-scrollbar-thumb {
    background-color: #32335E26;
    border-radius: 8px;
  }

  .adv-people-card {
    background-color: white;
    padding: 20px 10px 20px 10px;
  }

  .news-posts-card {
    margin-top: 2em;
    background-color: white;
  }

  .search-bar-div {
    display: block;
    width: 50%;
  }

  .adv-image {
    max-width: 100%;
  }

  .people-suggestion-div {
    margin-top: 3em;
    text-align: center;
  }

  .people-recommendations-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(150px, auto) minmax(150px, auto);
    gap: 30px;
    margin: 2em;
  }

  .people-individual-card {
    box-shadow: 0px 3px 6px #32335E26;
    border-radius: 15px;
    padding: 10px;
    display: block;
  }

  .dot-vertical-div {
    position: fixed;
    top: 0px;
    width: 25px;
    height: 25px;
  }

  .suggestion-profile-image {
    height: 60px;
    width: 60px;
    margin-bottom: 8px;
  }

  .sugg-username-text {
    font: normal normal 500 1.2em Poppins;
    letter-spacing: 0px;
    color: #0F0F2D;
  }

  .sugg-type-text {
    font: normal normal bold 1em Lato;
    letter-spacing: 0px;
    color: #979EA4;
  }

  .cust-follow-btn {
    background: #3E7EFF 0% 0% no-repeat padding-box;
    border-radius: 18px;
    width: 100px;
    height: 32px;
    border: none;
    color: white;
    font-size: 15px;
    margin-top: 12px;
  }

  .news-category-list-margin {
    margin-top: 2em;
  }

  .location-text {
    font-size: 16px;
  }
}
.people-recommendations-div {
  display: none;
}

.people-suggestion-div {
  display: none;
}

.topslide-page-image {
  max-width: 100%;
  min-width: 100%;
  position: relative;
  min-height: 200px;
  max-height: 300px;
}

.topslide-page-image-div {
  min-height: 200px;
  width: 100%;
  background-color: #F4F3F8;
}

@media (max-width: 520px) {
  .topslide-page-card {
    min-height: 150px;
    background-color: white;
  }
}
@media (min-width: 1200px) {
  .topslide-page-card {
    min-height: 200px;
    background-color: #F4F3F8;
  }

  .people-suggestion-div {
    display: block;
    margin-top: 3em;
    text-align: center;
  }

  .people-recommendations-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(150px, auto) minmax(150px, auto);
    gap: 30px;
    margin: 2em;
  }

  .people-individual-card {
    box-shadow: 0px 3px 6px #32335E26;
    border-radius: 15px;
    padding: 10px;
    display: block;
    position: relative;
  }

  .dot-vertical-div {
    position: absolute;
    top: 5px;
    right: 0px;
  }

  .dot-vertical-icon {
    height: 25px;
    width: 25px;
  }

  .suggestion-profile-image {
    height: 60px;
    width: 60px;
    margin-bottom: 18px;
  }

  .sugg-username-text {
    font: normal normal 500 18px/9px Poppins;
    letter-spacing: 0px;
    color: #0F0F2D;
  }

  .sugg-type-text {
    font: normal normal bold 16px/7px Lato;
    letter-spacing: 0px;
    color: #979EA4;
  }

  .cust-follow-btn {
    background: #3E7EFF 0% 0% no-repeat padding-box;
    border-radius: 18px;
    width: 100px;
    height: 32px;
    border: none;
    color: white;
    font-size: 15px;
    margin-top: 12px;
    margin-bottom: 25px;
  }

  .no-mobile {
    display: block;
  }

  .topslide-text {
    margin-bottom: 8px;
  }
}
.timeline-circle {
  display: none;
}

.news-posts-list-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(25vh, auto) minmax(25vh, auto) minmax(25vh, auto) minmax(25vh, auto);
  gap: 25px;
  column-gap: 0px;
  padding-left: 10px;
  position: relative;
  border: 1px solid blue;
}

.posts-category-list-card-outer-div {
  position: relative;
  animation: slide-in 1s ease-in;
}

@keyframes slide-in {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
.np-rwc-adv-image-div {
  margin-right: 10px;
  margin-left: 10px;
}

.mob-adv-item {
  margin-bottom: 14px;
}

.mr-5 {
  margin-right: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.loc-black-icon {
  width: 25px;
  height: 25px;
}

.arrow-right-icon {
  height: 24px;
  width: 24px;
}

.post-image {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.post-title-text {
  color: #232629;
  font-family: "poppins-medium";
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.post-timeline-text {
  font: normal normal 500 16px/25px Poppins;
  color: #979EA4;
  font-size: 12px;
  line-height: 1.3em;
}

.post-author-img {
  height: 35px;
  width: 35px;
}

.np-rwc-adv-image {
  border-radius: 10px;
}

.next-adv-arrow-div {
  display: none;
}

.post-author-img-wrapper {
  display: none;
}

.next-arrow-div {
  display: none;
}

.prev-arrow-div {
  display: none;
}

.posts-category-list-card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  padding: 10px 0px 10px 10px;
  display: block;
  position: relative;
  min-height: 200px;
  margin: 15px 0px;
}

.posts-category-adv-card {
  display: block;
  position: relative;
  max-height: 90vh;
}

.posts-list-font-1 {
  font-family: "poppins-bold";
  font-size: 16px;
}

.posts-list-font-2 {
  font: normal normal 14px Poppins;
  color: #3E7EFF;
  font-size: 14px;
}

.post-card-top {
  padding-right: 15px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.news-posts-carousel-mobile-div {
  display: block;
  position: relative;
  width: 100%;
  overflow: scroll;
}

.news-posts-carousel-div {
  position: relative;
  margin-top: 10px;
  display: block;
  overflow: hidden;
}

.np-adv-carousel-div {
  margin-top: 0px;
  padding-top: 0px;
}

.news-posts-carousel-div::-webkit-scrollbar {
  display: none;
}

.news-posts-carousel-div::-webkit-scrollbar-track {
  display: none;
}

.news-posts-carousel-div::-webkit-scrollbar-thumb {
  display: none;
}

.news-posts-mobile-carousel-div {
  display: block;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 100%;
}

.news-posts-mobile-carousel {
  display: flex;
  width: 100vw;
  overflow: scroll;
  height: 100%;
}

.news-posts-mobile-card {
  display: block;
  position: relative;
  min-width: 40vw;
  margin-right: 10px;
  max-height: 100%;
}

.news-posts-mobile-image {
  width: 100%;
  height: 40%;
  object-fit: cover;
}

div.news-post-ind-card {
  margin-right: 10px;
}

.post-text-wrapper-div {
  padding: 0px 5px;
}

/*
.news-posts-carousel-div{
    display: none;
}*/
.video-btn-div {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background: transparent;
  z-index: 20;
}

.video-btn {
  height: 40px;
  width: 40px;
}

.post-image-div {
  position: relative;
  background-color: #F4F3F8;
}

@media (max-width: 600px) {
  .post-image-div {
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: #F4F3F8;
    padding-top: 69%;
  }

  .post-image {
    position: absolute;
    top: 0px;
    border-radius: 5px;
    object-fit: contain;
    display: block;
  }

  /*  .post-image-div::before{
      content:"";
      display: block;
      padding-top: 56.25%;
    }
    */
  .post-image-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-size: 100% 100%;
    background-size: cover;
  }

  /*  .post-image{
        position: relative;
        width: 100%;
        height: 100%;
      //  height: 200px;
      //  max-height: 12vh;
        object-fit: cover;  
     } 

      .post-image-div{
        position: relative;
        background-color: #F4F3F8;
        height: 100px;
     //   max-height: 12vh;
     //   min-height: 12vh;
    //    min-width: 100%;
    }
      */
  .post-card-top-name {
    max-width: 70%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
@media (min-width: 600px) {
  .video-btn {
    height: 60px;
    width: 60px;
  }

  .timeline-circle {
    display: block;
  }

  .news-posts-mobile-carousel-div {
    display: none;
  }

  .news-posts-list-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(150px, auto) minmax(150px, auto) minmax(150px, auto) minmax(150px, auto);
    gap: 25px;
    position: relative;
    width: 100%;
    border: 1px solid blue;
  }

  /*
  .post-image-div{
     // min-height: 150px;
      width: 100%;
      height: 100%;
      max-height: 200px;
      overflow: hidden;
      border: 1px solid red;
  }*/
  .post-image-div {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  /*.post-image-div::before{
     content:"";
     display: block;
     padding-top: 56.25%;
  } */
  .post-image-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-size: 100% 100%;
    background-size: cover;
  }

  .news-posts-carousel-div {
    display: block;
    position: relative;
    overflow: scroll;
    margin-top: 10px;
  }

  div.news-post-ind-card {
    margin-left: 10px;
    margin-right: 0px;
  }

  .post-author-img-wrapper {
    display: block;
    height: 45px;
    width: 45px;
  }

  .post-author-img {
    display: block;
    min-height: 35px;
    min-width: 35px;
    max-width: 35px;
    border-radius: 50%;
    object-fit: cover;
  }

  .post-footer-div {
    position: relative;
    display: flex;
    max-width: 100%;
  }

  .next-arrow-icon {
    height: 50px;
    width: 50px;
  }

  .next-arrow-div {
    display: block;
  }

  .next-adv-arrow-div {
    display: block;
    position: absolute;
    top: calc(50% - 25px);
    right: 0px;
    z-index: 5;
  }

  .prev-arrow-div {
    display: block;
    position: absolute;
    top: 50%;
    left: 20px;
    z-index: 5;
  }

  .np-rwc-adv-image-div {
    margin-right: 10px;
  }

  .opacity-div {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 50px;
    height: 100%;
    border-radius: 0px 10px 10px 0px;
    z-index: 3;
    background: transparent linear-gradient(270deg, #FFFFFF 0%, #FFFFFF 17%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
  }

  .opacity-adv-div {
    opacity: 0.8;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 20%;
    min-height: 80%;
    z-index: 3;
    background: transparent linear-gradient(270deg, #FFFFFF 0%, #FFFFFF 17%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
  }

  .left-opacity-div {
    opacity: 0.8;
    position: absolute;
    top: 18%;
    bottom: 0px;
    left: 0px;
    width: 20%;
    min-height: 80%;
    border-radius: 10px;
    z-index: 3;
    background: transparent linear-gradient(270deg, #FFFFFF00 0%, #FFFFFF 80%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  }

  .post-timeline-text {
    font: normal normal 300 13px Poppins;
    color: #979EA4;
    display: flex;
    align-items: center;
  }

  .post-title-text {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 550;
    box-sizing: border-box;
    overflow: hidden;
  }

  .posts-list-font-1 {
    font-size: 18px;
  }

  .posts-list-font-2 {
    font-size: 14px;
  }

  .post-card-top {
    padding-left: 10px;
    padding-right: 40px;
  }

  .posts-category-list-card {
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 20px;
    min-height: 280px;
    margin: 20px 0px;
  }

  .loc-black-icon {
    width: 35px;
    height: 35px;
  }

  .mob-adv-item {
    display: none;
  }

  .post-text-wrapper-div {
    padding: 0px;
    width: calc(100% - 45px);
  }

  .timeline-circle {
    height: 10px;
    line-height: initial;
    width: 10px;
    border-radius: 100%;
    display: block;
    background-color: #979EA4;
    margin: 2px 5px;
  }
}
/*
.post-image-div{
    position: relative;
    width: 100%;
    overflow: hidden;
} */
/*.post-image-div::before{
  content:"";
  display: block;
  padding-top: 69.25%;
} */
.post-image-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center;
  background-size: 100% 100%;
  background-size: cover;
}

.post-image-div {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #F4F3F8;
  padding-top: 69%;
  border-radius: 5px;
}

.post-image-blur {
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  filter: blur(10px);
  border-radius: 5px;
}

.post-image {
  z-index: 10;
  position: absolute;
  top: 0px;
  border-radius: 5px;
  object-fit: contain;
  display: block;
}

.back-btn-main-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5em;
  top: 0em;
  margin-bottom: 1em;
  padding: 10px 15px;
  background-color: white;
}

.back-icon {
  height: 30px;
  width: 30px;
}

.login-modal {
  padding: 20px 0px;
}

.back-btn-div {
  display: flex;
  align-items: center;
}

.back-icon {
  height: 30px;
  width: 30px;
  margin-right: 15px;
}

.ap-font-1 {
  font: normal normal 600 0.8em Poppins;
  color: #232629;
}

.ap-font-2 {
  font: normal normal 600 0.7em Poppins;
  color: #0F0F2D;
}

.ap-img-wrapper {
  background-color: #000000;
  position: relative;
}
.ap-img-wrapper .ap-img {
  min-height: 30vh;
  width: 100%;
  position: relative;
  max-height: 80vh;
  object-fit: contain;
}

.posts-list-div {
  display: grid;
  grid-template-columns: 100%;
  row-gap: 10px;
}

.post-detail-card {
  background-color: white;
  min-height: 40vh;
  padding-bottom: 10px;
}

.post-detail-header-div {
  padding: 10px 15px;
  position: relative;
}

.post-detail-author-image {
  height: 40px;
  width: 40px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover;
}

.pd-name-div {
  display: flex;
  align-items: center;
}

.pd-font-1 {
  font: normal normal 500 0.9em Poppins;
  letter-spacing: 0px;
  color: #232629;
}

.pd-font-2 {
  font: normal normal normal 0.8em Lato;
  letter-spacing: 0px;
  color: #0F0F2D;
}

.pd-font-3 {
  font: normal normal bold 0.8em Lato;
  letter-spacing: 0px;
  color: #A7AEB4;
}

.pd-font-5 {
  font: normal normal bold 0.7em Lato;
  letter-spacing: 0px;
  color: #0F0F2D;
}

.pd-font-4 {
  font: normal normal 400 1em Poppins;
  letter-spacing: 0px;
  color: #232629;
}

.pd-font-6 {
  font: normal normal medium 18px Lato;
  letter-spacing: 0px;
  color: #0F0F2D;
  opacity: 1;
}

.pd-font-7 {
  font: normal normal normal 14px Poppins;
  color: #232629;
}

.verified-icon {
  height: 15px;
  width: 15px;
  margin-left: 5px;
}

.pd-vertical-div {
  position: absolute;
  right: 0px;
  top: 10px;
}

.load-comments {
  margin-top: 1em;
}

.pd-story {
  margin-top: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  max-width: 100%;
  overflow: hidden;
  white-space: wrap;
}

.pd-story-max {
  margin-top: 10px;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  white-space: wrap;
  word-wrap: break-word;
}

.pd-circle-div {
  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #A7AEB4;
  margin: 0px 6px;
}

.pd-views-div {
  margin-top: 8px;
  display: flex;
}

.pd-v-flex-1 {
  flex: 1;
}

.pd-v-flex-2 {
  flex: 1;
}

.pd-v-flex-3 {
  flex: 1;
}

.divide-margin {
  border-bottom: 2px solid #F4F3F8;
  margin: 0px 15px;
}

.pd-title-div {
  padding: 10px 15px;
}

.pd-interactions-div {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}

.pd-like-share-comment-div {
  display: flex;
}

.interaction-icon {
  height: 30px;
  width: 30px;
}

.interaction-rm {
  position: relative;
  margin-right: 20px;
}

.share-number-div {
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  position: absolute;
  left: 24px;
  top: -8px;
}

.reactions-popup-div {
  position: absolute;
  top: -52px;
  left: 0px;
}

.comment-name-text {
  font: normal normal 400 13px Poppins;
  color: #0F0F2D;
}

.comment-desc-text {
  font: normal normal medium 11px Lato;
  color: #0F0F2D;
}

.comments-list-div {
  margin: 0px 15px;
  margin-top: 1.8em;
}

.comment-smiley {
  background: transparent linear-gradient(180deg, #EF9E0B 0%, #D67100 100%) 0% 0% no-repeat padding-box;
}

.add-comment-div {
  width: 100%;
  position: relative;
  margin-bottom: 0.8em;
}

.comment-image {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.comment-input {
  border: 1px solid #979EA4;
  border-radius: 35px;
  height: 32px;
  min-width: 100%;
  padding: 5px 12px;
}

.comment-post-btn {
  background: #3E7EFF 0% 0% no-repeat padding-box;
  border-radius: 19px;
  margin-top: 15px;
  color: white;
  padding: 5px 15px;
  border: none;
  font: normal normal medium 16px Poppins;
}

.comment-details-div {
  margin-top: 2em;
}

.comment-details-list-wrapper {
  margin-top: 1em;
}

.modal-title-text {
  font: normal normal 550 14px Poppins;
  color: #0F0F2D;
}

.cust-modal-content {
  padding: 15px 15px;
}

.modal-close-icon {
  height: 30px;
  width: 30px;
}

.cust-modal {
  border-radius: 20px;
  margin-bottom: 1em;
}

.new-post-tags-div {
  margin: 16px 0px 12px 0px;
}

@media (max-width: 500px) {
  .all-posts-div {
    position: absolute;
    top: -120px;
    z-index: 200;
    background-color: white;
    min-width: 100vw;
    min-height: 100vh;
  }

  .new-post-tags-div {
    margin: 12px 0px 10px 0px;
  }

  .single-post-div {
    position: absolute;
    top: -60px;
    z-index: 200;
    background-color: white;
    width: 100vw;
    min-height: 100vh;
  }

  .single-post-div-card {
    border-radius: 0px;
    box-shadow: none;
  }
}
@media (min-width: 1200px) {
  .all-posts-div {
    position: relative;
    z-index: 0;
    background-color: none;
    top: 0px;
  }

  .login-modal {
    padding: 20px;
  }

  .cust-modal-content {
    padding: 30px 40px;
  }

  .back-icon {
    height: 40px;
    width: 40px;
    margin-right: 30px;
  }

  .back-btn-main-div {
    margin-bottom: 1.2em;
    background-color: #F4F3F8;
  }

  .back-icon {
    height: 35px;
    width: 35px;
  }

  .share-number-div {
    top: -10px;
    left: 21px;
  }

  .pd-font-1 {
    font-size: 1em;
  }

  .pd-font-2 {
    font-size: 0.8em;
  }

  .pd-font-3 {
    font-size: 0.9em;
  }

  .pd-font-4 {
    font-size: 1.1em;
  }

  .pd-font-5 {
    font-size: 1em;
  }

  .pd-font-6 {
    font-size: 1.1em;
  }

  .pd-font-7 {
    font-size: 0.9em;
  }

  .modal-title-text {
    font-size: 1.4em;
  }

  .ap-font-1 {
    font-size: 1.2em;
  }

  .ap-font-2 {
    font-size: 1em;
  }

  .pd-story {
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    max-width: 100%;
    overflow: hidden;
    white-space: wrap;
  }

  .pd-story-max {
    margin-top: 10px;
    max-width: 100%;
    overflow: hidden;
    white-space: wrap;
  }

  .pd-read-more {
    margin-top: 10px;
  }

  .post-detail-card {
    padding: 20px 40px;
  }

  .post-detail-author-image {
    height: 3em;
    width: 3em;
    border-radius: 50%;
    margin-right: 20px;
  }

  .post-detail-header-div {
    padding: 0px 0px;
    position: relative;
    margin-bottom: 20px;
  }

  .ap-img-wrapper {
    position: relative;
  }
  .ap-img-wrapper .ap-img {
    min-height: 400px;
    max-width: 100%;
  }

  .verified-icon {
    height: 0.9em;
    width: 0.9em;
    margin-left: 10px;
  }

  .pd-views-div {
    margin-top: 0.9em;
    margin-bottom: 0.5em;
  }

  .pd-title-div {
    padding: 20px 0px 30px 0px;
  }

  .divide-margin {
    margin: 0px 0px;
  }

  .interaction-icon {
    height: 32px;
    width: 32px;
  }

  .pd-interactions-div {
    position: relative;
    padding: 0px 0px;
  }

  .interaction-rm {
    position: relative;
    margin-right: 3em;
  }

  .like-interaction-empty::after {
    content: "Like";
    font: normal normal normal 14px Poppins;
    margin-left: 5px;
  }

  .comment-i::after {
    content: "Comment";
    font: normal normal normal 14px Poppins;
    margin-left: 5px;
  }

  .share-i::after {
    content: "Share";
    font: normal normal normal 14px Poppins;
    margin-left: 5px;
  }

  .back-btn-main-div {
    margin-top: 0.3em;
    top: 70px;
    padding: 10px 0px;
    background-color: #F4F3F8;
  }

  .post-detail-card {
    background-color: white;
    min-height: 20vh;
    padding-bottom: 10px;
    border-radius: 10px;
  }

  .posts-list-div {
    row-gap: 20px;
  }

  .reactions-popup-div {
    top: -55px;
  }

  .comment-input {
    height: 40px;
    padding: 12px 30px;
  }

  .comment-image {
    height: 35px;
    width: 35px;
    margin-right: 20px;
  }

  .comment-post-btn {
    margin-top: 20px;
    color: white;
    padding: 6px 20px;
    font: normal normal medium 20px/9px Poppins;
  }

  .comment-details-div {
    margin-top: 2em;
  }

  .comment-details-list-wrapper {
    margin-top: 1em;
  }

  .comments-list-div {
    margin: 0px;
    margin-top: 3em;
  }

  .comment-name-text {
    font-size: 16px;
  }

  .comment-desc-text {
    font-size: 15px;
  }

  .pd-likes-count {
    display: inline;
  }
}
.new-post-detail-card {
  background: #FFFFFF;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-height: 300px;
  padding: 35px 24px 10px 40px;
}

.new-post-rejected-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.new-post-card-font-1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
}

.new-post-rejected-overlay-center {
  background-color: white;
  border-radius: 12px;
  padding: 60px 30px;
}

.new-post-card-font-2 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.new-post-card-font-3 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.new-post-card-font-4 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.new-post-card-font-5 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.new-post-card-color-1 {
  color: #000000;
}

.new-post-card-comment {
  margin: 16px 0px;
}

.new-post-card-reaction-div {
  margin: 16px 0px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.new-post-card-color-2 {
  color: #3E7EFF;
}

.new-post-card-color-3 {
  color: #696969;
}

.new-post-card-name-div {
  display: flex;
  column-gap: 20px;
  align-items: center;
  margin-bottom: 24px;
  position: relative;
}

.new-post-card-more-div {
  display: none;
  position: absolute;
  top: 0px;
  right: -5px;
}

.new-post-card-circle {
  height: 4px;
  width: 4px;
  background: #000000;
  border-radius: 50%;
}

.new-post-card-margin {
  border: 1px solid #D9D9D9;
  margin: 8px 20px 16px 0px;
}

.new-post-card-margin-2 {
  border: 1px solid #D9D9D9;
  margin: 16px 10px 16px 0px;
}

.new-post-card-comment-div {
  margin-top: 24px;
}

.new-post-card-title-div {
  margin: 30px 0px 16px;
}

.new-post-card-views-div {
  margin: 14px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new-post-card-image-div {
  height: 48px;
  width: 48px;
  min-height: 48px;
  min-width: 48px;
  background-color: #EEEEEE;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #D9D9D9;
}

.new-post-card-icon {
  height: 24px;
  width: 24px;
  object-fit: contain;
}

.new-post-reaction-series-div {
  position: relative;
}

.overlap-icon-1 {
  position: relative;
  left: 0px;
  top: 0px;
}

.overlap-icon:not(:first-child) {
  position: relative;
  margin-left: -6px;
}

.new-post-card-icon-2 {
  height: 16px;
  width: 16px;
}

.new-post-card-icon-3 {
  height: 22px;
  width: 22px;
}

.icon-flex {
  display: flex;
  gap: 8px;
  align-items: center;
}

.new-post-card-comment {
  display: flex;
  column-gap: 8px;
  padding-right: 10px;
  position: relative;
}

.new-post-delete-comment-icon {
  position: absolute;
  top: 6px;
  right: 6px;
}

.new-post-card-comment-text-div {
  background: #EEEEEE;
  border-radius: 5px;
  padding: 16px;
  justify-self: stretch;
  position: relative;
  width: calc(100% - 48px);
}

.new-post-card-comment-name {
  margin-bottom: 4px;
  align-items: flex-end;
}

.new-post-card-timestamp {
  margin-top: 5px;
  gap: 10px;
}

.new-text-eclipse-1 {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-text-eclipse-2 {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-post-card-input {
  background: #F3F3F3;
  border-radius: 72px;
  padding: 10px 15px;
  padding-right: 42px;
  width: 100%;
  justify-self: stretch;
  height: 48px;
  outline: none;
}

.new-post-card-input-parent {
  width: 100%;
  position: relative;
}

.new-post-card-comment-input-div {
  display: flex;
  gap: 16px;
  margin: 24px 0px;
}

.new-post-card-send-button-div {
  position: absolute;
  top: 12px;
  right: 12px;
}

@media (max-width: 600px) {
  .new-post-detail-card {
    background: #FFFFFF;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    min-height: 300px;
    padding: 15px;
    padding-bottom: 1px;
    margin-bottom: 16px;
  }

  .new-post-card-comment {
    padding-right: 0px;
  }

  .new-post-card-font-3 {
    font-size: 12px;
    line-height: 18px;
  }

  .mobile-font-1 {
    font-size: 12px;
    line-height: 18px;
  }

  .new-post-card-font-5 {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
  }

  .new-post-card-comment-text-div {
    padding: 8px;
    padding-right: 20px;
    position: relative;
    justify-self: stretch;
  }

  .new-post-card-comment {
    padding-right: 0px;
  }

  .new-post-card-timestamp {
    margin-top: 2px;
  }

  .new-text-eclipse-1 {
    max-width: 180px;
  }

  .new-text-eclipse-2 {
    max-width: 100px;
  }
}
.new-headline-font {
  font-size: 18px;
  line-height: 24px;
}

.new-description-font {
  font-size: 16px;
  line-height: 25px;
}

@media (max-width: 900px) {
  .new-headline-font {
    font-size: 17px;
    line-height: 24px;
  }

  .new-description-font {
    font-size: 15px;
    line-height: 26px;
  }
}
.follower-details-list {
  margin-top: 1.5em;
  padding: 0px 15px;
}

.follower-details-desc {
  margin-bottom: 1em;
}

.follower-image {
  height: 45px;
  width: 45px;
  border-radius: 50%;
}

.list-close-btn {
  display: none;
}

.react-name-text {
  font: normal normal normal 1em Poppins;
  letter-spacing: 0px;
  color: #0F0F2D;
}

.followers-follow-btn {
  background: #3E7EFF 0% 0% no-repeat padding-box;
  border-radius: 22px;
  font: normal normal medium 1em Poppins;
  letter-spacing: 0.8px;
  color: #FFFFFF;
  border: none;
  padding: 5px 22px;
}

.reaction-type-image {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background: #0F0F2D 0% 0% no-repeat padding-box;
  border: 1px solid #FFFFFF;
  padding: 5px;
}

.react-image-div {
  position: relative;
  margin-right: 0.8em;
}

.reaction-list-back-div {
  display: block;
  margin-right: 1.3em;
}

.reaction-list-back-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 0.5em;
}

.mobile-only {
  display: block;
}

.react-cust-title {
  padding: 20px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
}

@media (min-width: 900px) {
  .mobile-only {
    display: none;
  }

  .react-image-div {
    margin-right: 1.5em;
  }

  .follower-details-desc {
    margin-bottom: 1em;
  }

  .reaction-list-back-div {
    display: none;
  }

  .list-close-btn {
    display: block;
  }

  .no-mobile {
    display: block;
  }

  .follower-details-list {
    margin-top: 1.5em;
    padding: 0px 60px;
  }

  .react-cust-title {
    justify-content: space-between;
  }

  .follower-image {
    height: 50px;
    width: 50px;
  }

  .react-cust-title {
    padding: 30px 60px 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: none;
    display: flex;
  }

  .reaction-list-back-icon {
    height: 40px;
    width: 40px;
    margin-right: 1.2em;
  }

  .react-name-text {
    font-size: 1.3em;
  }

  .followers-follow-btn {
    font-size: 1em;
    padding: 6px 24px;
  }
}
.featured-story-card {
  display: flex;
}

.featured-story-image-div {
  flex: 1;
}

.featured-story-text {
  font-family: "poppins-medium";
  font-size: 14px;
  color: #232629;
}

.featured-story-text-2 {
  font-family: "poppins-medium";
  font-size: 12px;
  color: #979EA4;
  margin-top: 0.2em;
}

.featured-story-content-div {
  flex: 3;
  padding: 0px 10px 0px 10px;
}

.featured-story-image {
  width: 90px;
  min-height: 60px;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.download-app-div {
  width: 85%;
  margin: 20px auto;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px 3px #E9E9EB;
}

.download-app-div:hover {
  box-shadow: 0 0 5px 3px #b136b1;
}

.fs-icons-div {
  margin-top: 1.2em;
}

.fs-google-play {
  height: 40px;
  margin-right: 10px;
}

.fs-app-store {
  height: 40px;
}

.fs-timeline-circle {
  height: 6px;
  width: 6px;
}

.divider-line-4 {
  border: 1px solid #E9E9EB;
  margin: 25px 0px;
}

.sk-search-bar {
  min-height: 30px;
  width: 100%;
}

.sample-skeleton {
  min-height: 40vh;
  min-width: 200px;
}

.sk-posts-category-div {
  min-width: 100%;
  min-height: 30vh;
  display: flex;
  gap: 1em;
  margin-bottom: 3em;
}

.sk-post-image {
  min-height: 150px;
}

.sk-circle {
  min-height: 40px;
  min-width: 40px;
  border-radius: 50%;
}

.sk-post-sample-1 {
  flex: 1;
  display: block;
  position: relative;
}

.sk-rect-div {
  min-width: calc(100% - 60px);
}

.sk-post-sample-2 {
  flex: 1;
}

.sk-post-sample-3 {
  flex: 0.5;
}

.sk-sm-rect {
  display: block;
  position: relative;
  min-height: 10px;
}

.sk-featured-story-card {
  display: block;
  position: relative;
  min-height: 80px;
}

.sk-topslide-page-adv-card {
  display: block;
  min-height: 200px;
}

.sk-ps-div-1, .sk-ps-div-2, .sk-ps-div-3 {
  min-height: 200px;
  margin: 20px;
}

.sk-search-bar:empty, .sample-skeleton:empty,
.sk-post-image:empty, .sk-circle:empty, .sk-ps-div-1, .sk-ps-div-2, .sk-ps-div-3, .sk-sm-rect:empty, .sk-featured-story-card:empty, .sk-topslide-page-adv-card:empty {
  animation: loading 1.1s infinite linear;
  background: #dedfe1;
  background-image: -webkit-linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
  background-image: linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
  background-repeat: no-repeat;
}

@keyframes loading {
  0% {
    background-position: -100px;
  }
  100% {
    background-position: 500px;
  }
}
@media (max-width: 900px) {
  .sk-circle {
    display: none;
  }

  .sk-posts-category-div {
    min-width: 100%;
    min-height: 15vh;
    display: flex;
    gap: 1em;
    margin-bottom: 2em;
    background-color: white;
    padding: 10px;
  }

  .sk-post-image {
    min-height: 10vh;
  }

  .sk-rect-div {
    min-width: 100%;
  }

  .sk-search-bar {
    min-height: 20vh;
  }

  .sk-topslide-page-adv-card {
    min-height: 150px;
  }
}
.sk-post {
  padding: 1.5em;
}

.sk-all-post-image {
  min-height: 35vh;
}

.food-category-sk-image:empty {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.list-sk-image {
  height: 80px;
  width: 80px;
  margin-bottom: 20px;
  display: block;
  margin-right: 20px;
}

.list-sk-body-1 {
  height: 30px;
  margin-bottom: 20px;
  display: block;
}

.food-category-sk-name {
  min-height: 20px;
}

.restaurant-safe-tag:empty {
  min-height: 20px;
  min-width: 60px;
}

.sk-all-post-image:empty, .food-category-sk-image, .food-category-sk-name, img .food-restaurant-image:empty, div .food-restaurant-image:empty, .restaurant-safe-tag:empty, .list-sk-image, .list-sk-body-1 {
  animation: loading 1.1s infinite linear;
  background: #dedfe1;
  background-image: -webkit-linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
  background-image: linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
  background-repeat: no-repeat;
}

.ps-skeleton-div-1 {
  height: 100px;
  width: 100%;
}

.ps-skeleton-div-2 {
  height: 10px;
  width: 100%;
  margin: 10px 0px;
}

.ps-skeleton-div-3 {
  height: 20px;
  width: 60%;
  margin: 10px 0px;
}

.ps-skeleton-div-4 {
  height: 40px;
  width: 100%;
  margin: 10px 0px;
}

.ps-skeleton {
  margin-top: 20px;
}

.od-sk-left {
  height: 500px;
  border: 1px solid #D5D5D5;
}

.od-sk-right-div-1 {
  height: 140px;
  margin: 20px 0px;
  border: 1px solid #D5D5D5;
}

.ps-skeleton-mobile {
  display: none;
}

.ps-skeleton-desktop {
  display: none;
}

@media (max-width: 600px) {
  .ps-skeleton-mobile {
    display: block;
    margin-top: 20px;
  }

  .ps-skeleton-desktop {
    display: none;
  }
}
@media (min-width: 600px) {
  .ps-skeleton-desktop {
    display: flex;
    margin-top: 30px;
  }

  .ps-skeleton-mobile {
    display: none;
  }
}
.order-sk-card {
  margin: 20px 0px;
  min-height: 200px;
  border: 1px solid #D5D5D5;
  border-radius: 6px;
}

.order-sk-card-top {
  min-height: 60px;
  border: 1px solid #D5D5D5;
  border-radius: 6px;
}

.order-sk-card-detail {
  min-height: 140px;
}

.ps-skeleton-div-1, .ps-skeleton-div-2, .ps-skeleton-div-3, .ps-skeleton-div-4, .order-sk-card-top, .order-sk-card-detail, .od-sk-left, .od-sk-right-div-1 {
  animation: loading 1.1s infinite linear;
  background: #dedfe1;
  background-image: -webkit-linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
  background-image: linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
  background-repeat: no-repeat;
}

.ps-skeleton-div-2, .order-sk-card-top {
  animation-delay: 0.7s;
}

.ps-skeleton-div-3 {
  animation-delay: 1s;
}

.ps-skeleton-div-4 {
  animation-delay: 1.3s;
}

.login-wrapper {
  position: relative;
  min-height: 100vh;
  padding: 10px;
  display: block;
  max-width: 1600px;
  margin: 0 auto;
}

.login-main-div {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas: "login-mobile-card";
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.modal-login-main-div {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas: "login-mobile-card";
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.login-mobile-card {
  position: relative;
}

.desktop-card {
  display: none;
}

.login-3km-logo-div {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.login-3km-logo-image {
  width: 100px;
  height: 90px;
}

.login-text {
  color: #007FFF;
}

.login-text-2 {
  font: normal normal bold 16px/25px Poppins;
  color: #232629;
}

.login-text-3 {
  font: normal normal bold 16px/25px Poppins;
  color: #FFFFFF;
}

.login-text-4 {
  font: normal normal normal 16px/25px Poppins;
  color: #232629;
}

.login-text-5 {
  font: normal normal 500 16px/9px Poppins;
  letter-spacing: 0.64px;
  color: #3E7EFF;
}

.login-text-6 {
  text-decoration: underline;
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #0F0F2D;
}

.login-text-7 {
  text-decoration: underline;
  font: normal normal 600 16px/25px Poppins;
  color: #3E7EFF;
}

.login-text-8 {
  font: normal normal 600 14px Poppins;
  color: #0F0F2D;
  text-align: center;
}

.login-text-9 {
  font: normal normal bold 16px Poppins;
}

.login-text-11 {
  font: normal normal normal 14px Poppins;
  color: #0F0F2D;
}

.login-text-12 {
  font: normal normal normal 14px Poppins;
  color: #0F0F2D;
}

.step-1-div {
  margin: 22px auto 50px auto;
}

.step-2-div {
  margin: 22px auto 60px auto;
}

.reset-password-2-form {
  margin: 20px 20px;
}

.text-login-blue {
  color: #3E7EFF;
}

.login-otp-div {
  padding: 60px 40px;
}

.otp-phone-no {
  display: flex;
  justify-content: space-around;
}

.otp-phone-no-digit-filled {
  min-width: 20%;
  max-width: 65px;
  height: 65px;
  background: #F4F3F8 0% 0% no-repeat padding-box;
  border: 2px solid #3E7EFF;
  border-radius: 10px;
  padding: 5px;
  font: normal normal bold 30px Poppins;
  letter-spacing: 0px;
  text-align: center;
  color: #3E7EFF;
}

.otp-phone-no-digit {
  border: 1px solid var(---d5d5d5);
  background: #F4F3F8 0% 0% no-repeat padding-box;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  padding: 5px;
  min-width: 20%;
  max-width: 65px;
  font: normal normal bold 30px Poppins;
  color: #D5D5D5;
  height: 65px;
  text-align: center;
}

.otp-phone-no-digit-wrong {
  background: #F4F3F8 0% 0% no-repeat padding-box;
  border: 2px solid #FF5858;
  border-radius: 10px;
  opacity: 1;
  padding: 5px;
  min-width: 20%;
  max-width: 65px;
  font: normal normal bold 30px Poppins;
  letter-spacing: 0px;
  color: #FF5858;
  text-align: center;
  height: 65px;
}

.otp-phone-no-digit-filled:focus, .otp-phone-no-digit:focus, .otp-phone-no-digit-wrong:focus {
  outline: 2px solid #3E7EFF;
}

.otp-phone-no-input {
  font: normal normal bold 40px Poppins;
  letter-spacing: 0px;
  color: #3E7EFF;
  max-width: 100%;
  max-height: 100%;
}

.wrong-otp-text {
  font: normal normal 600 14px Poppins;
  color: #FF5858;
  padding-top: 15px;
}

.login-otp-text-div {
  margin: 40px 0px;
  display: block;
}

.login-otp-text-div-2 {
  margin: 25px 0px;
  display: block;
}

.login-otp-text-div-3 {
  margin: 35px 0px 50px 0px;
  display: block;
}

.ph-code {
  position: absolute;
  display: flex;
  align-items: center;
  font: normal normal normal 15px Poppins;
  color: #0F0F2D;
  left: 10px;
  height: 100%;
}

.sakal-bak-div {
  position: absolute;
  bottom: -50px;
  left: -50px;
  right: 0px;
  padding: 30px 10px;
}

.sakal-bak-logo {
  height: 50px;
}

.login-desk-footer-div {
  display: none;
}

.login-footer-div {
  position: absolute;
  bottom: -100px;
  right: 10px;
  left: 10px;
}

.login-fb-image {
  height: 40px;
  width: 40px;
}

.login-fb-image-div {
  background-color: #FFFFFF;
  height: 100%;
  border-radius: 10px 0px 0px 10px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.facebook-login-div {
  border: 1px solid #3F51B5;
  border-radius: 10px;
  width: 80%;
  display: flex;
  margin: 0px auto;
  align-items: center;
  height: 60px;
}

.login-fb-text {
  background-color: #3F51B5;
  height: 100%;
  border-radius: 0px 9px 9px 0px;
  width: calc(100% - 60px);
  border-color: #3F51B5;
}

.login-google-text {
  background-color: #3E7EFF;
  height: 100%;
  border-radius: 0px 9px 9px 0px;
  width: calc(100% - 60px);
  border-color: #3E7EFF;
}

.google-login-div {
  margin-top: 30px;
  border-color: #3E7EFF;
}

.app-play-store-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.google-play-div {
  margin-right: 32px;
}

.google-play-logo {
  height: 55px;
}

.app-store-logo {
  height: 55px;
}

.login-divider {
  width: 85%;
  border: 1px solid #D5D5D5;
  display: block;
  margin: 30px auto;
}

.login-divider-2 {
  width: 85%;
  border: 1px solid #D5D5D5;
  display: block;
  margin: 40px auto 50px;
}

.left-arrow {
  height: 34px;
  width: 34px;
  margin-right: 8px;
}

.login-input-div {
  width: 75%;
  display: block;
  margin: 30px auto;
}

.login-phno-input {
  background: #F4F3F8 0% 0% no-repeat padding-box;
  border: 1px solid #D5D5D5;
  border-radius: 37px;
  width: 100%;
  height: 45px;
  padding: 8px 50px;
  text-align: left;
  font: normal normal normal 15px Poppins;
  letter-spacing: 0px;
  color: #0F0F2D;
}

.login-phno-input-2 {
  background: #F4F3F8 0% 0% no-repeat padding-box;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  width: 100%;
  height: 45px;
  padding: 8px 50px;
  text-align: left;
  font: normal normal normal 15px Poppins;
  letter-spacing: 0px;
  color: #0F0F2D;
}

.login-phno-input-3 {
  background: #F4F3F8 0% 0% no-repeat padding-box;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  width: 100%;
  height: 45px;
  padding: 8px 50px 8px 20px;
  text-align: left;
  font: normal normal normal 15px Poppins;
  letter-spacing: 0px;
  color: #0F0F2D;
}

.pwd-eye {
  position: absolute;
  right: 0px;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-password-input-div {
  margin-top: 22px;
  margin-bottom: 35px;
}

.title-2 {
  margin-bottom: 45px;
}

.login-password-input-div-2 {
  margin-top: 22px;
  margin-bottom: 35px;
}

.login-password-btn {
  width: 80%;
  display: block;
  margin: 40px auto 0px auto;
  background: #3E7EFF 0% 0% no-repeat padding-box;
  border: 1px solid #3E7EFF;
  border-radius: 29px;
  height: 45px;
  color: white;
}

.login-password-form-div {
  width: 85%;
  display: block;
  margin: 35px auto;
}

.login-phno-input:focus, .login-phno-input-2:focus, .login-phno-input-3:focus {
  outline-color: #3E7EFF;
}

.login-pwd-divider {
  border: 1px solid #00000029;
  height: 1px;
}

.password-otp-btn {
  width: 40%;
  display: block;
  margin: 0px auto;
  margin-bottom: 40px;
}

.login-pwd-divider-div {
  display: flex;
  align-items: center;
  width: 80%;
  margin: 20px auto;
}

.login-otp-input {
  border: 1px solid var(---d5d5d5);
  background: #F4F3F8 0% 0% no-repeat padding-box;
  border: 1px solid #D5D5D5;
  border-radius: 37px;
  width: 100%;
  height: 45px;
  padding: 8px 20px;
  text-align: left;
  font: normal normal normal 15px Poppins;
  letter-spacing: 0px;
  color: #0F0F2D;
}

.login-btn {
  border: 1px solid #3E7EFF;
  border-radius: 29px;
  margin-top: 20px;
  width: 100%;
  background-color: white;
  height: 45px;
}

.otp-btn {
  border: 1px solid #3E7EFF;
  border-radius: 29px;
  width: 100%;
  background-color: white;
  height: 45px;
  margin: 20px auto;
  display: block;
}

.login-btn:hover, .otp-btn:hover {
  background-color: #3E7EFF;
  color: white;
}

.otp-text {
  font: normal normal 600 14px Poppins;
  letter-spacing: 0px;
  color: #3E7EFF;
}

.otp-text:hover {
  text-decoration: underline;
}

.otp-timer {
  text-decoration: none;
}

.otp-timer:focus, .otp-timer:active {
  text-decoration: none;
}

.login-forg-btn-div {
  margin: 51px 0px 41px 0px;
}

@media (min-width: 900px) {
  .login-main-div {
    display: grid;
    column-gap: 50px;
    grid-template-columns: 2fr 1.2fr;
    grid-template-rows: auto;
    grid-template-areas: "desktop-card login-mobile-card-outer-div";
    width: 100%;
    max-width: none;
    padding: 50px 60px 10px 60px;
  }

  .modal-login-main-div {
    display: grid;
    column-gap: 50px;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas: "login-mobile-card-outer-div";
    width: 100%;
  }

  .desktop-card {
    display: flex;
    justify-content: center;
  }

  .login-mobile-card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #00000029;
    border-radius: 10px;
    padding: 30px 0px;
    position: relative;
    min-height: 50vh;
    max-width: none;
  }

  .login-3km-logo-div {
    margin-top: 40px;
  }

  .login-3km-logo-image {
    width: 200px;
    height: 210px;
  }

  .login-text {
    position: relative;
    top: -30px;
    font: normal normal 600 12px/18px Poppins;
  }

  .login-text-2 {
    font-size: 20px;
  }

  .get-app-div {
    margin-top: 100px;
  }

  .login-desk-footer-div {
    display: block;
    position: relative;
    padding-top: 30px;
  }

  .login-otp-text-div {
    margin: 80px 0px;
  }

  .login-otp-text-div-2 {
    margin: 50px 0px 40px;
  }

  .login-otp-text-div-3 {
    margin: 50px 0px 40px;
  }

  .title-2 {
    margin-bottom: 45px;
  }

  .login-text-8 {
    font-size: 20px;
    text-align: left;
  }

  .login-phno-input {
    font-size: 18px;
  }

  .login-phno-input-2, .login-phno-input-3 {
    font-size: 18px;
  }

  .login-otp-input {
    font-size: 18px;
  }

  .ph-code {
    font-size: 18px;
  }

  .wrong-otp-text {
    font-size: 20px;
  }

  .otp-phone-no-digit-filled {
    font-size: 50px;
    height: 80px;
  }

  .otp-phone-no-digit {
    font-size: 50px;
    height: 80px;
  }

  .login-password-input-div {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .login-password-input-div-2 {
    margin-top: 30px;
    margin-bottom: 35px;
  }

  .otp-phone-no-digit-wrong {
    font-size: 50px;
    height: 80px;
  }

  .otp-text {
    font-size: 16px;
  }

  .login-password-form-div {
    margin: 50px auto;
    width: 70%;
  }

  .otp-btn {
    width: 50%;
  }

  .login-text-9 {
    font-size: 20px;
  }

  .login-pwd-divider-div {
    margin: 30px auto;
  }

  .login-text-4 {
    font-size: 18px;
  }

  .login-text-12 {
    font: normal normal normal 18px Poppins;
    color: #0F0F2D;
  }

  .reset-password-2-form {
    margin: 20px 40px;
  }

  .login-text-11 {
    font-size: 18px;
    line-height: 25px;
  }
}
.profile-info-outer-div {
  display: grid;
  grid-template-columns: 1.1fr 3fr;
  grid-template-rows: auto;
  grid-template-areas: "profile-info-menu-div profle-info-right-div";
}

.modal-close-custom {
  position: absolute;
  top: 10px;
  right: 10px;
}

.profile-info-menu-div {
  border-right: 1px solid #D5D5D5;
}

.profile-info-menu-list {
  list-style: none;
  list-style-type: none;
  list-style-position: outside;
  padding: 0px;
}

.profile-info-menu-item-text {
  font: normal normal 400 16px Poppins;
  color: #0F0F2D;
  text-transform: capitalize;
}

.profile-info-menu-list li {
  height: 70px;
  padding-left: 40px;
  border-bottom: 1px solid #D5D5D5;
  display: flex;
  align-items: center;
  font: normal normal 400 16px Poppins;
  color: #0F0F2D;
  text-transform: capitalize;
}

.profile-info-menu-icon {
  height: 40px;
  width: 40px;
  margin-right: 25px;
}

.profile-info-menu-icon-2 {
  height: 22px;
  width: 22px;
}

.active-menu {
  background: #3E7EFF 0% 0% no-repeat padding-box;
  border-right: none;
}
.active-menu .profile-info-menu-icon-div {
  background-color: white;
}

.profile-info-menu-icon-div {
  height: 40px;
  width: 40px;
  background-color: #F4F4F5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
}

.profile-info-right-div {
  padding: 40px 50px;
}

.profile-info-text-2 {
  font: normal normal 600 21px Poppins;
  color: #0F0F2D;
  text-transform: uppercase;
}

.profile-edit-div {
  margin-top: 50px;
  position: relative;
}

.profile-info-image-div {
  position: relative;
  height: 110px;
  width: 110px;
}

.profile-info-image {
  height: 110px;
  width: 110px;
  border-radius: 50%;
  object-fit: cover;
  padding: 2px;
  outline: 3px solid #62a104;
}

.profile-name-input-div {
  max-width: 80%;
  padding: 15px 30px 25px;
}

.profile-info-edit-image {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 30px;
  width: 30px;
}

.profile-cancel-text {
  position: absolute;
  top: 0px;
  right: 0px;
}

.divider-line {
  border: 1px solid #E9E9EB;
  margin: 35px 0px;
}

.divider-line-2 {
  border: 1px solid #E9E9EB;
  margin: 20px 0px;
}

.divider-line-3 {
  border: 1px solid #E9E9EB;
  margin: 2em 0px 1em 0px;
}

.profile-name-div {
  position: relative;
  display: flex;
  align-items: center;
}

.profile-change-pwd-btn {
  background: #d6dff0 0% 0% no-repeat padding-box;
  border-radius: 25px;
  padding: 5px 30px;
  outline: none;
  border: none;
}

.modal-close-cust-btn {
  padding: 5px;
  border-radius: 25%;
}

.profile-change-pwd-btn:focus, .profile-change-pwd-btn:active {
  background: #d6dff0 0% 0% no-repeat padding-box;
}

.profile-btn-1 {
  letter-spacing: 0.8px;
  font-family: poppins;
  font-size: 16px;
  margin-top: 30px;
  height: 40px;
  width: 100%;
}

.profile-edit-btn, .profile-cancel-btn {
  letter-spacing: 0.8px;
  font-family: poppins;
  font-size: 16px;
  height: 40px;
  padding: 5px 20px;
  margin-right: 20px;
}

.gender-icon {
  height: 30px;
  width: 30px;
  margin-right: 15px;
}

.gender-icon-2 {
  height: 30px;
  width: 30px;
  margin-right: 6px;
  margin-left: 12px;
}

.profile-radio-input {
  margin-right: 20px;
  font-size: 16px;
}

.mob-edit-profile-title-btn {
  display: none;
}

@media (max-width: 600px) {
  .p-mt-px-10 {
    margin-top: 8px;
  }

  .profile-radio-input {
    margin-right: 10px;
    font-size: 12px;
  }

  .profile-info-right-div {
    padding: 20px 20px;
  }

  .profile-name-input-div {
    max-width: 100%;
    padding: 15px 10px;
  }

  .profile-info-menu-div {
    display: none;
  }

  .profile-name-div {
    display: block;
  }

  .p-mt-px-20px {
    margin-top: 45px;
  }

  .profile-info-outer-div {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "profle-info-right-div";
    padding-bottom: 30px;
    overflow-y: auto;
  }

  .mob-edit-profile-title-btn {
    display: flex;
    margin: 10px;
  }

  .edit-profile-title-btn {
    display: none;
  }

  .p-email-inp {
    width: 100%;
    min-width: 100%;
  }

  .p-line-2 {
    display: none;
  }
}
.p-verify-otp-div {
  padding: 20px 40px;
}

.verify-otp-title {
  padding: 20px;
}

.modal-close-btn-2 {
  position: absolute;
  right: 20px;
  top: 20px;
}

.vo-divider {
  width: 100%;
  border-top: 1px solid #D5D5D5;
  display: block;
  margin: 0px 0px 20px;
}

.verify-otp-text {
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 20px;
}

.verify-otp-text-2 {
  font-size: 15px;
}

.p-verify-btn-div {
  margin-top: 20px;
  margin-bottom: 20px;
}

.cp-input-text {
  font-family: "poppins-light";
  font-size: 16px;
}

.cp-input-text-2 {
  font-family: "poppins-medium";
  font-size: 16px;
}

.cp-input {
  margin-bottom: 20px;
}

.cp-input-div {
  width: 80%;
  margin: 30px auto;
}

.cp-input-submit-btn {
  margin: 40px auto;
}

.share-card-div {
  padding: 20px;
  background-color: white;
}

.share-card-title {
  margin-bottom: 1.2em;
}

.checkout-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

.checkout-outer-div {
  min-height: 100vh;
}

.checkout-font-1 {
  font-size: 21px;
  margin-bottom: 25px;
}

.checkout-font-2 {
  font-size: 18px;
}

.checkout-font-3 {
  font-size: 16px;
}

.checkout-pay-btn {
  padding: 10px 20px;
  border-radius: 10px;
}

.checkout-product-card {
  gap: 10px;
}

.checkout-address-btn {
  padding: 10px 20px;
  border-radius: 10px;
}

.checkout-product-card-outer-div {
  border: 1px solid #D5D5D5;
  padding: 20px 10px;
  border-radius: 10px;
  gap: 0px;
}

.checkout-summary-div {
  box-shadow: 0px 0px 99px #32335E26;
  border-radius: 10px;
  min-height: 400px;
  padding: 20px 20px;
}

.checkout-image {
  height: 50px;
  width: 70px;
  border-radius: 10px;
  object-fit: contain;
}

.checkout-summary-outer-div {
  padding: 20px 0px 20px 40px;
}

.checkout-address-div {
  border: 1px solid #D5D5D5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout-address-div {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.mobile-addressdiv {
  height: auto;
  overflow: auto;
  margin-left: 35px;
}

@media (max-width: 600px) {
  .checkout-font-1 {
    font-size: 18px;
  }

  .checkout-font-2 {
    font-size: 16px;
  }

  .checkout-font-3 {
    font-size: 14px;
  }

  .checkout-address-div {
    display: block;
  }

  .checkout-address-btn {
    display: block;
  }

  .checkout-address-btn {
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 10px;
  }

  .checkout-summary-div {
    min-height: auto;
  }

  .checkout-div {
    display: block;
  }

  .checlout-items-div {
    padding: 10px;
  }

  .mobile-addressdiv {
    height: 100%;
    overflow-y: scroll;
    margin-left: 0px;
  }

  .checkout-summary-outer-div {
    padding: 20px 0px;
  }
}
.success-div {
  display: flex;
  min-height: 50vh;
  align-items: center;
  justify-content: center;
  background-color: #43B978;
  padding: 30px 20px;
}

.success-image-div {
  position: relative;
  display: block;
  margin: 0px auto;
  margin-top: 30px;
  height: 136px;
  width: 204px;
}

.success-font-1 {
  font-size: 21px;
}

.success-tick-icon {
  position: absolute;
  height: 70px;
  width: 70px;
  right: 67px;
  top: 31px;
}

.success-tick-spread-icon {
  height: 136px;
  width: 204px;
}

@media (max-width: 600px) {
  .success-div {
    height: 80vh;
  }
}
.address-list-div {
  padding: 30px;
}

.address-list-card {
  border: 1px solid #D5D5D5;
  padding: 10px 10px;
  border-radius: 10px;
}

.address-list-active-card {
  border: 2px solid greenyellow;
  padding: 10px 10px;
  border-radius: 10px;
}

@media (max-width: 600px) {
  .address-list-div {
    padding: 0px 10px;
  }
}
.cart-min-icon-div {
  padding: 20px;
  background-color: #3E7EFF;
  border-radius: 50%;
}

.cart-outer-div {
  background-color: white;
  height: 100%;
  display: block;
  position: relative;
  z-index: 10;
}

.cart-product-card {
  display: flex;
  column-gap: 30px;
}

.cart-product-card {
  padding: 0px 15px;
}

.cart-image {
  height: 100px;
  width: 130px;
  border-radius: 10px;
  object-fit: contain;
}

.cart-product-discard-icon {
  position: absolute;
  left: -10px;
  top: -10px;
}

.cart-empty-div {
  height: calc(100vh - 70px - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .cart-outer-div {
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    overflow-y: scroll;
    height: 100vh;
    z-index: 100;
  }

  .my-cart-div {
    width: 100%;
  }

  .cart-product-card {
    padding: 0px 5px;
  }

  .cart-product-card {
    column-gap: 21px;
  }

  .cart-image {
    height: 100px;
    width: 120px;
    border-radius: 10px;
  }
}
@media (min-width: 600px) {
  .my-cart-outer-div {
    margin-right: 20px;
    border-radius: 10px;
  }
}
.new-post-main-div {
  display: block;
  min-height: 100vh;
  background-color: white;
  position: relative;
}

.modal-title-div {
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 6px #32335E26;
}

.modal-content-div {
  padding: 40px 40px;
}

.post-form-input-div {
  margin-bottom: 25px;
}

.news-post-red-close-icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.news-post-img-close {
  position: absolute;
  top: -10px;
  right: -10px;
}

.modal-close-img {
  display: none;
}

.select-gallery-div {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  position: relative;
}

.new-post-upload-btn {
  background: #cfdaf1 0% 0% no-repeat padding-box;
  border-radius: 29px;
  padding: 10px 20px;
  color: #3E7EFF;
  margin: 0px auto;
  margin-top: 3em;
  display: block;
}

.new-post-gallery-img {
  display: block;
  margin: 0px auto;
  height: 50px;
  width: 50px;
  margin-bottom: 1em;
}

.new-post-gallery-img-2 {
  margin: 0px auto;
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.modal-title-1 {
  font: poppins-semibold;
  font-size: 16px;
  color: #0F0F2D;
  text-transform: capitalize;
}

.modal-title-2 {
  font: poppins-medium;
  font-size: 14px;
  color: #232629;
}

.modal-title-3 {
  font: poppins-light;
  font-size: 14px;
}

.modal-title-4 {
  font-family: Poppins;
  font-size: 21px;
  font-weight: 600px;
}

.modal-back-img {
  display: none;
}

.newpost-image-preview-div {
  display: flex;
  flex-flow: row wrap;
  max-width: 100%;
  gap: 20px;
}

.newpost-preview-img {
  position: relative;
  background: transparent 0% 0% no-repeat padding-box;
  background-position: center;
  border-radius: 10px;
  background-size: contain;
  opacity: 1;
  width: 130px;
  height: 100px;
  flex: 1 130px;
  background-color: black;
}

@media (max-width: 600px) {
  .modal-title-div {
    padding: 10px 10px;
  }

  .news-post-red-close-icon {
    height: 20px;
    width: 20px;
  }

  .select-gallery-div {
    min-height: calc(90vh - 70px - 100px);
  }

  .new-post-main-div {
    min-height: 100vh;
  }

  .post-form-input-div {
    margin-bottom: 21px;
  }

  .newpost-preview-img {
    height: 150px;
    width: 150px;
    background-color: black;
    flex: 1 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-back-img {
    display: inline-block;
    height: 24px;
    width: 24px;
    margin-right: 10px;
  }

  .newpost-image-preview-div {
    gap: 10px;
  }

  .modal-content-div {
    padding: 30px 10px;
  }
}
@media (min-width: 1200px) {
  .modal-close-img {
    display: block;
    height: 30px;
    width: 30px;
  }

  .modal-title-1 {
    font-size: 20px;
  }

  .modal-title-2 {
    font-size: 16px;
  }

  .modal-title-3 {
    font-size: 16px;
  }

  .new-post-main-div {
    min-height: 50vh;
  }
}
.notification-details-div {
  position: fixed;
  display: block;
  top: 112px;
  right: 0px;
  padding-right: 10px;
  bottom: 0px;
  width: 100%;
  border-radius: 0px;
  padding: 15px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  z-index: 100;
}

.notification-main-div {
  max-height: 80vh;
  overflow-y: scroll;
}

.notification-main-div::-webkit-scrollbar {
  display: none;
}

.notification-main-div::-webkit-scrollbar-track {
  display: none;
}

.notification-main-div::-webkit-scrollbar-thumb {
  display: none;
}

.notification-desc {
  margin-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #D5D5D5;
}

.not-title {
  font: normal normal bold 1.2em Poppins;
  color: #140F26;
}

.not-unread-text {
  font: normal normal bold 1em Lato;
  color: #0F0F2D;
}

.not-list-image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-left: 1em;
}

.not-list-image-2 {
  height: 70px;
  width: 70px;
  object-fit: contain;
}

.not-list-image-div {
  margin-right: 15px;
}

.not-title-text {
  font: normal normal medium 20px/14px Lato;
  color: #140F26;
}

.not-timeline-text {
  font: normal normal 600 16px/14px Lato;
  color: #979EA4;
  margin-top: 8px;
}

.not-follow-btn {
  background: #3E7EFF 0% 0% no-repeat padding-box;
  border-radius: 24px;
  font: normal normal medium 20px/9px Poppins;
  letter-spacing: 0.8px;
  color: #FFFFFF;
  border: none;
  padding: 6px 20px;
  margin-left: 1em;
}

@media (max-width: 500px) {
  .notification-main-div {
    min-height: 100vh;
  }
}
@media (min-width: 1200px) {
  .notification-details-div {
    top: 65px;
    right: 10px;
    padding-right: 10px;
    height: 80vh;
    width: 35%;
    border-radius: 15px;
    border: 1px solid #D5D5D5;
  }

  .notification-main-div {
    max-height: 75vh;
  }
}
.like-reactions-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0F0F2D;
  padding: 0px 20px;
  border-radius: 48px;
}

.like-reactions-logo {
  height: 22px;
  width: 30px;
}

.like-reactions-logo-rm {
  margin-right: 0.6em;
}

@media (min-width: 1200px) {
  .like-reactions-div {
    padding: 10px 15px;
  }

  .like-reactions-logo {
    height: 26px;
    width: 30px;
  }

  .like-reactions-logo-rm {
    margin-right: 1em;
  }
}
.like-reactions-logo-rm-2 {
  margin-right: 6px;
}

.food-main-div {
  position: relative;
  width: 100%;
  display: block;
  max-width: 100%;
}

.food-main-card {
  background-color: white;
  margin-top: 2em;
  margin-right: 1.5em;
}

.food-category-image {
  height: 80px;
  width: 80px;
  margin-bottom: 12px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.food-restaurants-div {
  padding: 0px 15px;
  display: block;
}

.food-download-app-div {
  background: #0F0F2D 0% 0% no-repeat padding-box;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 100px;
}

.food-restaurant-card {
  margin-bottom: 1.3em;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #32335E26;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
  position: relative;
}

.food-restaurant-topslide-card {
  margin-bottom: 1.3em;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 10px;
}

.food-restaurant-image {
  height: 200px;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.shop-food-restaurant-image {
  height: 150px;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.food-restaurant-topslide-div {
  border-radius: 0px;
  width: 100%;
}

.food-restaurant-topslide {
  position: relative;
  width: 100%;
}

.food-font-1 {
  padding: 1.2em 1em 1.5em 1.5em;
  font-family: "poppins-bold";
  font-size: 16px;
}

.food-font-2 {
  font-family: "poppins-medium";
  font-size: 16px;
}

.food-font-3 {
  font-family: "lato-bold";
  font-size: 12px;
}

.food-font-4 {
  font-family: "lato-regular";
  font-weight: 300;
  font-size: 12px;
  color: #979EA4;
}

.food-font-5 {
  color: #3E7EFF;
  font-size: 14px;
  font-family: "poppins-semibold";
}

.food-font-6 {
  font-size: 18px;
  font-family: "poppins-medium";
  color: white;
}

.restaurant-safe-tag {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 6px 10px;
  border-radius: 8px 0px 10px 0px;
  background-color: white;
  z-index: 2;
}

.restaurant-name-info {
  padding: 10px 15px 20px 15px;
}

.food-location-div {
  display: flex;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  padding: 0px 20px;
  position: relative;
}

.food-search-location-div {
  max-width: 55%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.restaurant-cuisines-text {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;
}

.food-search-bar-div {
  display: block;
  width: 350px;
}

.food-category-div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #e7f3f7;
  padding: 20px 15px;
  gap: 15px;
  justify-items: center;
}

.food-google-play-icon {
  height: 50px;
  margin-right: 50px;
}

.food-appstore-icon {
  height: 50px;
}

@media (max-width: 600px) {
  .food-main-card {
    margin-right: 0px;
    margin-top: 1.3em;
    background-color: transparent;
  }

  .food-font-1 {
    font-size: 13px;
  }

  .food-font-2 {
    font-size: 14px;
  }

  .food-download-app-div {
    display: none;
  }

  .food-search-location-div {
    margin-top: 1em;
    margin-bottom: 1em;
    max-width: 100%;
    display: block;
  }

  .food-search-bar-div {
    display: block;
    width: 90%;
    margin: 0px auto;
  }

  .food-location-div {
    display: none;
  }

  .food-category-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 1.5em;
  }
}
@media (min-width: 600px) and (max-width: 1200px) {
  .food-restaurants-div {
    padding: 0px 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    max-width: 1200px;
  }

  .food-download-app-div {
    display: none;
  }
}
@media (min-width: 600px) and (max-width: 980px) {
  .food-search-location-div {
    margin-top: 1em;
    margin-bottom: 1em;
    max-width: 100%;
    display: block;
  }

  .food-search-bar-div {
    display: block;
    margin: 0px auto;
    width: 600px;
  }

  .food-location-div {
    display: none;
  }

  .food-main-card {
    margin-right: 0em;
    border-radius: 10px;
    margin-right: 0px;
  }
}
@media (min-width: 980px) and (max-width: 1200px) {
  .food-search-location-div {
    max-width: 80%;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .food-main-card {
    margin-right: 0em;
    border-radius: 10px;
    margin-right: 10px;
  }
}
@media (min-width: 1200px) {
  .food-font-2 {
    font-size: 15px;
  }

  .food-font-6 {
    font-size: 22px;
  }

  .food-font-3, .food-font-4, .food-font-5 {
    font-size: 18px;
  }

  .food-category-item {
    padding: 10px 15px;
    width: 100%;
  }

  .food-category-item:hover {
    background-color: white;
    border-radius: 6px;
  }

  .food-category-div {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: 1.5em;
  }

  .food-main-card {
    border-radius: 10px;
    padding-bottom: 1px;
  }

  .food-font-1 {
    font-size: 18px;
  }

  .food-restaurant-card {
    border: none;
  }

  .shop-food-restaurant-image {
    height: 170px;
  }

  .food-restaurants-div {
    padding: 0px 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    max-width: 1200px;
  }

  .food-download-app-div {
    position: relative;
    margin: 20px 30px 30px 30px;
    backdrop-filter: blur(10px);
  }

  .border-gradient {
    border: 10px solid;
    border-image-slice: 1;
    border-width: 3px;
    border-radius: 5px;
  }

  .border-gradient-purple {
    border-image-source: linear-gradient(98deg, #1B00E8 0%, #FF0000 100%);
    backdrop-filter: blur(30px);
    border-radius: 5px;
  }
}
.food-checkout-main-div {
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  position: fixed;
  background-color: white;
  padding: 30px 0px;
  z-index: 1000;
  max-height: 100vh;
  overflow-y: scroll;
}

.food-checkout-font-1 {
  font-family: poppins-medium;
  font-weight: 600;
  font-size: 12px;
}

.food-checkout-font-2 {
  font-family: poppins-medium;
  font-weight: 500;
  font-size: 20px;
}

.food-checkout-font-3 {
  font-family: poppins-light;
  font-weight: 500;
  font-size: 12px;
}

.food-checkout-font-4 {
  font-family: poppins-medium;
  font-weight: 400;
  font-size: 16px;
}

.food-checkout-font-5 {
  font-family: lato-regular;
  font-weight: 400;
  font-size: 16px;
}

.food-checkout-font-6 {
  font-family: poppins-medium;
  font-weight: 400;
  font-size: 16px;
  color: #93959F;
}

.food-checkout-inner-div {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 50px;
}

.food-checkout-address-list-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.food-checkout-desktop-address-div {
  display: none;
  border-right: 3px solid #D5D5D5;
  padding-right: 50px;
}

.food-checkout-mobile-address-div {
  padding: 15px 0px;
}

.middle-gap {
  height: 25px;
  background-color: #F4F3F8;
}

.food-checkout-address-card {
  padding: 20px;
  border: 0.5px solid #000000;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.food-checkout-mobile-address-card {
  margin: 20px 0px;
  padding: 15px;
}

.not-selected-address {
  background-color: #F9F7FF;
}

.selected-address {
  background-color: #70FF431C;
}

.food-checkout-address-desktop-new-card {
  min-height: 250px;
}

.align-self-center {
  align-self: center;
}

.food-checkout-mobile-new-address-card {
  margin: 20px 15px;
  background-color: #F9F7FF;
  padding: 15px;
}

.d-flex {
  display: flex;
}

.title-1 {
  margin: 40px 0px;
}

.fixed-cart-div {
  display: none;
}

.veg-symbol {
  height: 24px;
  width: 24px;
  border-radius: 2px;
  border: 1px solid #4CBA09;
  display: flex;
  align-items: center;
  justify-content: center;
}

.non-veg-symbol {
  height: 24px;
  width: 24px;
  border-radius: 2px;
  border: 1px solid #FF5858;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-title-info-div {
  display: flex;
  gap: 20px;
}

.veg-symbol-circle {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #4CBA09;
}

.non-veg-symbol-circle {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #FF5858;
}

.restaurant-menu-divider-line-2 {
  border-bottom: 2px solid #D5D5D5;
  margin: 16px 0px;
}

.menu-title-info-div {
  display: flex;
  gap: 20px;
}

.btn-1 {
  background-color: #3E7EFF;
  font-size: 14px;
  font-family: poppins-medium;
  color: white;
  padding: 12px 30px;
  border-radius: 12px;
  margin-top: 25px;
}

.btn-2 {
  background: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  font-size: 14px;
  font-family: poppins-medium;
  color: #4CBA09;
  padding: 10px 15px;
}

.btn-4 {
  padding: 10px 15px;
  margin-top: 0px;
  border-radius: 20px;
}

.food-checkout-cart-list-div {
  margin-top: 20px;
}

.food-checkout-cart-div {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

.address-select-btn {
  border: 1px solid #3E7EFF;
  border-radius: 4px;
  color: #3E7EFF;
  padding: 15px 40px;
  margin-top: 15px;
}

.address-selected-btn {
  border: 1px solid #3E7EFF;
  border-radius: 4px;
  color: white;
  background-color: #3E7EFF;
  padding: 15px 40px;
  margin-top: 15px;
}

button:focus, button:active, .toggle-btn:focus {
  outline: none;
  box-shadow: none;
}

.food-cart-add-new-address {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(19, 20, 24, 0.5);
  overflow: hidden;
  display: none;
  z-index: 1001;
}

.food-cart-add-new-address-card {
  width: 100vw;
  background-color: white;
  padding: 0px 10px;
  padding-bottom: 20px;
  height: 100%;
  overflow-y: auto;
  z-index: 1001;
}

.add-address-title-1 {
  margin: 30px 0px 40px 0px;
}

@media (max-width: 600px) {
  .food-checkout-main-div {
    padding-bottom: 100px;
  }

  .add-address-title-1 {
    margin: 20px 0px 20px 0px;
  }

  .fixed-cart-div {
    min-height: 80px;
    position: fixed;
    display: block;
    bottom: 0px;
    background-color: #0F0F2D;
    color: white;
    padding: 15px;
    left: 0px;
    right: 0px;
  }
}
@media (min-width: 1200px) {
  .food-checkout-main-div {
    position: relative;
    background-color: white;
    min-height: 90vh;
    padding: 30px 20px 30px 40px;
    overflow: auto;
    max-height: none;
    z-index: 1;
    width: 100%;
  }

  .food-checkout-font-2 {
    font-size: 22px;
  }

  .food-checkout-font-3 {
    font-size: 16px;
  }

  .food-checkout-font-4 {
    font-size: 16px;
  }

  .food-checkout-font-5 {
    font-size: 16px;
  }

  .food-checkout-font-6 {
    font-size: 15px;
  }

  .align-self-desktop {
    align-self: center;
  }

  .food-checkout-inner-div {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  .food-checkout-desktop-address-div {
    display: block;
  }

  .food-checkout-address-div {
    border-right: 3px solid #D5D5D5;
    padding-right: 50px;
  }

  .food-checkout-cart-list-div {
    margin-top: 20px;
  }

  .food-checkout-cart-div {
    padding: 0px;
  }

  .btn-1 {
    font-size: 16px;
    padding: 15px 30px;
    margin-top: 40px;
  }

  .btn-3 {
    font-size: 16px;
    padding: 10px 30px;
    margin-top: 20px;
    display: block;
    width: 100%;
  }

  .food-cart-add-new-address {
    display: none;
    height: 100vh;
    top: 0vh;
    z-index: 1000 !important;
    background: rgba(0, 0, 0, 0.5);
  }

  .food-cart-add-new-address-card {
    width: 40vw;
    background-color: white;
    padding: 80px 60px;
    height: 100%;
    overflow-y: auto;
  }

  .food-cart-new-address-scroll::-webkit-scrollbar {
    background-color: white;
    width: 15px;
  }

  .food-cart-new-address-scroll::-webkit-scrollbar-thumb {
    background-color: #D5D5D5;
    border-radius: 8px;
  }

  .food-cart-new-address-scroll::-webkit-scrollbar {
    background-color: white;
    width: 15px;
  }

  .food-cart-new-address-scroll:hover::-webkit-scrollbar-thumb {
    background-color: #D5D5D5;
    border-radius: 8px;
  }
}
.food-cart-close-icon {
  margin-right: 10px;
}

@media (min-width: 1600px) {
  .food-cart-add-new-address {
    left: calc((100vw - 1610px) / 2);
    display: none;
  }

  .food-cart-add-new-address-card {
    width: 30vw;
  }
}
.shop-category-image {
  height: 80px;
  width: 80px;
  margin-bottom: 12px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.shop-main-card {
  display: block;
  border-radius: 10px;
}

.red-font {
  color: #FF5858;
}

.shop-topslide-div {
  background-color: #0F0F2D;
  min-height: 250px;
  display: block;
  margin-bottom: 2em;
  padding: 20px 20px;
}

.shop-topslide-image {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.shop-category-title-div {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 1em 15px;
  max-width: 1200px;
  margin-bottom: 6px;
}

.shop-category-title {
  font-family: "poppins-bold";
  font-size: 14px;
}

.shop-product-card {
  margin-bottom: 1.3em;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  position: relative;
}

.topslide-gradient {
  background: transparent linear-gradient(103deg, #17EAD9 0%, #6078EA 100%) 0% 0% no-repeat padding-box;
}

.topslide-grey {
  background: #D5D5D5 0% 0% no-repeat padding-box;
}

.shop-red-arrow {
  height: 16px;
  width: 16px;
  margin-left: 5px;
}

.shop-category-title-2 {
  font-size: 13px;
  font-family: "lato-bold";
  display: flex;
  align-items: center;
}

.shop-product-image {
  width: 100%;
  height: 150px;
  object-fit: contain;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.shop-product-image-wrapper {
  min-width: 100%;
  min-height: 150px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.shop-products-div {
  padding: 0px 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 40px;
}

.shop-category-div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 10px;
  padding: 60px 15px;
  gap: 15px;
  justify-items: center;
  background-image: linear-gradient(white, #f8f8b4, #a6f7ca, #e1f8f4, white);
}

@media (min-width: 1200px) {
  .shop-category-image {
    height: 100px;
    width: 100px;
  }

  .shop-category-div {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: 1.5em;
  }

  .shop-topslide-div {
    padding: 20px 40px;
  }

  .shop-product-image {
    height: 170px;
  }

  .shop-topslide-image {
    display: block;
    height: 300px;
    border-radius: 15px;
    margin: 0px auto;
    width: auto;
  }

  .shop-products-div {
    padding: 0px 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    max-width: 1200px;
  }

  .shop-category-title-div {
    padding: 1em 30px;
    margin-bottom: 12px;
  }

  .shop-product-image-wrapper {
    min-height: 170px;
  }

  .shop-category-title {
    font-size: 21px;
  }

  .shop-category-title-2 {
    font-size: 18px;
  }

  .shop-products-div {
    margin-bottom: 50px;
  }

  .shop-red-arrow {
    height: 24px;
    width: 24px;
  }
}
.category-list-div {
  display: block;
  background-color: white;
  position: relative;
}

.category-list-back-btn {
  display: block;
  background-color: white;
}

.category-back-title {
  top: 0px;
  margin-top: 0px;
  box-shadow: none;
  background-color: white;
  padding: 10px 10px;
}

.category-back-icon {
  height: 50px;
  width: 40px;
}

.category-list-image {
  object-fit: contain;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.category-cart-btn {
  padding: 6px 12px;
  border: 2px solid #979EA4;
  margin-top: 1em;
  border-radius: 29px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  color: #232629;
}

.category-list-image-wrapper {
  height: 170px;
  width: 100%;
  position: relative;
  border-radius: 10px;
}

.category-list-product-card {
  display: block;
  padding: 0px 0px;
  max-width: 100%;
  border-radius: 10px;
}

.category-list-product-outer-card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  position: relative;
  border: 1px solid #D5D5D5;
}

.category-cart-icon {
  position: relative;
  z-index: 11;
  display: flex;
  justify-content: flex-end;
  margin: 20px auto;
  width: auto;
}

.cat-btn {
  margin: 0px auto;
  display: block;
  border-radius: 30px;
  padding: 10px 20px;
  margin-bottom: 20px;
  width: 38%;
}

.category-products-div {
  padding-bottom: 20px;
}

.category-list-product-outer-active-card {
  box-shadow: none;
  border: 3px solid #3E7EFF;
}

.category-title-5 {
  margin-left: 30px;
}

@media (max-width: 600px) {
  .category-title-5 {
    margin-left: 15px;
  }

  .category-list-div {
    position: fixed;
    display: block;
    z-index: 100;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    padding-bottom: 0px;
    top: 0px;
  }

  .cat-btn {
    padding: 10px 20px;
    border-radius: 0px;
    margin-top: 0px;
    width: 100%;
    margin-bottom: 0px;
  }

  .category-list-image-wrapper {
    height: 150px;
    flex: 2;
    flex-basis: 190px;
    position: relative;
    border-radius: 10px;
  }

  .category-back-title {
    top: 0px;
    margin-top: 0px;
    padding: 15px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .category-list-product-outer-card {
    border: none;
  }

  .category-cart-icon {
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 11;
    display: block;
    right: 0px;
    display: block;
    width: 100%;
    margin-bottom: 0px;
  }

  .category-list-product-card {
    display: flex;
    flex-grow: row wrap;
    padding: 12px 15px;
    gap: 10px;
    max-width: 100%;
    box-shadow: 0px 3px 6px #00000029;
    border: none;
    border-radius: 10px;
  }

  .category-trash-icon {
    height: 36px;
    width: 36px;
    background-color: #FF5858;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .category-list-product-outer-active-card {
    box-shadow: none;
    border: 3px solid #3E7EFF;
  }

  .category-products-div {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
.product-detail-outer-div {
  background-color: white;
  padding: 30px 40px;
  min-height: calc(100vh - 70px - 30px);
  position: relative;
}

.product-detail-div {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: 40px;
}

.product-detail-image {
  border-radius: 10px;
  width: 100%;
  max-height: 75vh;
  object-fit: cover;
}

.product-detail-title {
  font-family: poppins-bold;
  font-size: 20px;
  font-weight: 600;
  margin-top: 1.2em;
}

.product-tag {
  padding: 10px 20px;
}

.product-detail-seller-card {
  column-gap: 25px;
  margin-top: 1.5em;
}

.product-detail-seller-image {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border: 1px solid #F4F3F8;
  border-radius: 15px;
}

.product-detail-font-1 {
  font-size: 18px;
}

.product-detail-font-2 {
  font-size: 17px;
  font-weight: lighter;
}

.product-tag {
  background-color: #43B978;
  border-radius: 20px;
  color: white;
}

.product-detail-description {
  font-family: lato-regular;
  font-size: 18px;
  margin-top: 1.5em;
}

.product-detail-description-min {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.product-detail-description-max {
  height: auto;
}

.product-detail-read-more {
  font-family: poppins-light;
}

.product-detail-tags-div {
  display: flex;
  flex-flow: row wrap;
  margin-top: 1.5em;
  row-gap: 10px;
}

.product-tag-outline {
  border: 1px solid #979EA4;
  border-radius: 16px;
  background-color: white;
  font-family: "lato-regular";
  font-size: 16px;
  padding: 5px 15px;
}

.product-tag-outline-selected {
  border: 2px solid greenyellow;
  border-radius: 16px;
  background-color: white;
  font-family: "lato-regular";
  font-size: 16px;
  padding: 5px 15px;
}

.product-detail-cart-btn {
  background: #FF5858 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 50px #FFFFFF;
  border-radius: 10px;
  color: white;
  width: 40%;
  padding: 10px 20px;
}

.product-detail-wishlist-btn {
  background: #F4F3F8 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 50px #FFFFFF;
  border-radius: 10px;
  color: #0F0F2D;
  width: 40%;
  padding: 10px 20px;
}

.product-detail-btn-div {
  display: flex;
  margin: 3em 0px 60px;
}

.product-divider-line {
  width: 90%;
}

.product-detail-image-div {
  background-color: white;
}

.back-detail-main-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}

@media (max-width: 600px) {
  .back-detail-main-div {
    display: none;
  }

  .product-detail-outer-div {
    position: fixed;
    display: block;
    z-index: 100;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    padding-bottom: 0px;
    top: 0px;
    padding: 30px 10px 50px 10px;
  }

  .product-detail-image-div {
    margin-bottom: 2em;
  }

  .product-detail-div {
    display: block;
  }

  .product-detail-btn-div {
    display: block;
  }

  .product-detail-cart-btn {
    width: 100%;
    display: block;
  }

  .product-detail-wishlist-btn {
    width: 100%;
    display: block;
    margin-top: 1.2em;
  }

  .product-detail-title {
    font-size: 18px;
  }
}
@media (min-width: 1200px) {
  .product-detail-title {
    font-size: 25px;
  }
}
.author-profile-main-div {
  padding: 40px 80px;
  background-color: white;
  margin-right: 30px;
  min-height: 90vh;
}

.author-profile-pic {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: #D9D9D9;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  margin: 0px auto;
}

.author-profile-name {
  margin-top: 20px;
}

.author-profile-font-1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 33px;
}

.author-profile-font-2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #7C7C7C;
}

.author-profile-font-3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7C7C7C;
}

.author-profile-font-4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #444444;
}

.author-verification-banner-top {
  display: flex;
  align-items: center;
  column-gap: 8px;
  justify-content: center;
}

.author-profile-sk-posts {
  min-height: 100vh;
  width: 60%;
  position: relative;
  display: block;
  margin: 0px auto;
}

.author-profile-count-div {
  margin: 16px auto;
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
}

.author-verification-banner {
  background: #F8F8F8;
  border-radius: 10px;
  margin: 16px auto;
  padding: 16px 18px;
  width: 100%;
}

.eq-flex {
  width: 120px;
}

.author-profile-btn-1 {
  background: #3E7EFF;
  border-radius: 10px;
  padding: 10px 16px;
  width: 400px;
  display: block;
  margin: 20px auto;
}

.author-profile-btn-2 {
  color: #3E7EFF;
  border-radius: 30px;
  padding: 10px 24px;
  display: block;
  margin: 16px auto 0px;
  width: 100%;
  border: 2px solid #3E7EFF;
  background-color: white;
}

.author-profile-divider-1 {
  border-bottom: 3px solid #B6B6B6;
  width: 100%;
  margin: 40px 0px 30px 0px;
}

.no-posts-div {
  margin: 70px auto;
}

.author-profile-posts-list-div {
  display: block;
  width: 70%;
  margin: 0px auto;
  background-color: #F9F9F9;
  padding: 40px;
}

@media (min-width: 900px) {
  .author-d-back-btn {
    position: absolute;
    top: 40px;
    left: 40px;
  }

  .author-verification-banner {
    width: 400px;
  }

  .author-more-opt {
    position: absolute;
    top: 40px;
    right: 110px;
    background: #FFFFFF;
    box-shadow: 12px 12px 40px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 10px;
  }

  .author-profile-sk-circle {
    height: 120px;
    width: 120px;
    display: block;
    margin: 0px auto;
  }

  .author-d-more-btn {
    position: absolute;
    top: 40px;
    right: 40px;
  }
}
@media (max-width: 600px) {
  .author-profile-main-div {
    padding: 10px 10px;
    margin-right: 0px;
    min-height: 90vh;
    width: 100%;
  }

  .author-profile-sk-posts {
    width: 100%;
  }

  .author-profile-posts-list-div {
    display: block;
    width: 100%;
    margin: 0px auto;
    padding: 10px;
  }

  .author-more-opt {
    position: absolute;
    top: 40px;
    right: 20px;
    background: #FFFFFF;
    box-shadow: 12px 12px 40px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 10px;
  }

  .eq-flex {
    flex: 1;
  }

  .author-profile-info-div {
    padding: 0px 10px;
  }

  .author-profile-btn-1 {
    border-radius: 30px;
    padding: 12px;
    width: 100%;
    display: block;
    margin: 20px auto;
  }
}
.margin-right-10 {
  margin-right: 10px;
}

.mt-px-70 {
  margin-top: 70px;
}

.text-danger {
  font: normal normal normal 15px Poppins;
  color: #FF5858;
}

.text-success {
  font: normal normal normal 15px Poppins;
  color: green;
}

.mt-px-40 {
  margin-top: 40px;
}

.ml-px-25 {
  margin-left: 25px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-top-10 {
  margin-top: 10px;
}

.mb-em-1 {
  margin-bottom: 1em;
}

.mb-px-10 {
  margin-bottom: 10px;
}

.mb-px-20 {
  margin-bottom: 20px;
}

.mr-px-10 {
  margin-right: 10px;
}

.mb-em-3 {
  margin-bottom: 3em;
}

.mb-em-2 {
  margin-bottom: 2em;
}

.mt-em-1 {
  margin-top: 1em;
}

.mb-37 {
  margin-bottom: 2em;
}

.mr-20 {
  margin-right: 20px;
}

.mr-15 {
  margin-right: 1em;
}

.flex-d {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-1p5 {
  flex: 1.5;
}

.flex-3 {
  flex: 3;
}

.text-center {
  text-align: center;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.desktop-only {
  display: none;
}

.text-underline {
  text-decoration: underline;
}

.text-black {
  color: #0F0F2D;
}

.text-blue {
  color: #3E7EFF;
}

.no-btn {
  border: none;
  outline: none;
  background-color: none;
}

.no-btn:focus, .no-btn:active {
  border: none;
  outline: none;
  background-color: none;
}

.text-danger-2 {
  font: normal normal bold 15px Poppins;
  color: #FF5858;
}

.f-16 {
  font-size: 1em;
}

.c-text-eclipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.c-text-eclipse-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.no-mobile {
  display: none;
}

.dum-input-div {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dum-input {
  outline: none;
  border: none;
}

.dum-input:focus {
  outline: none;
  border: none;
}

.blue-btn {
  background: #3E7EFF 0% 0% no-repeat padding-box;
  border-radius: 29px;
  font-family: "poppins-medium";
  font-size: 14px;
  color: white;
  padding: 6px 15px;
}

.input-no-border {
  display: block;
  border: 1px solid #979EA4;
  width: 100%;
  padding: 6px 15px;
  border-radius: 25px;
}

.input-no-border:focus {
  outline: none;
  border: 1px solid #979EA4;
}

.space-between {
  justify-content: space-between;
}

.text-eclipse {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.cancel-btn {
  background: #F4F3F8 0% 0% no-repeat padding-box;
  border-radius: 29px;
  font-family: "poppins-medium";
  font-size: 14px;
  color: #0F0F2D;
  padding: 6px 15px;
}

@media (min-width: 1200px) {
  .desktop-only {
    display: initial;
  }

  .no-mobile {
    display: block;
  }

  .c-text-eclipse {
    max-width: 55%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .c-text-eclipse-2 {
    max-width: 30%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
html, body {
  max-width: 100vw;
}

body {
  margin: 0;
}

.new-c-modal {
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000000;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  justify-content: center;
  align-items: center;
}

.new-c-modal-close {
  display: none;
}

@media (max-width: 600px) {
  .new-c-modal {
    align-items: flex-end;
    border-radius: 0px;
  }
}