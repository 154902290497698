.poppins-16-normal{
    font: normal normal normal 16px $poppins;
}

.poppins-18-normal{
    font: normal normal normal 18px $poppins;
}

.poppins-20-normal{
    font: normal normal normal 20px $poppins;
}

.poppins-light{
   font-family: poppins-light;
}

.poppins-regular{
    font-family: poppins-regular;
}

 .poppins-medium{
    font-family: poppins-medium;
 }

.poppins-semibold{
    font-family: poppins-bold;
    font-weight: 600;
}

.lato-regular{
    font-family: lato-regular;
}

.heading-4{
    font-family: poppins-medium;
    font-size: $h4;
    font-weight: 500;
}

.sub-header-1{
    font-family: poppins-medium;
    font-size: $h5;
}

.input-label{
    font-family: poppins-light;
    font-size: 14px;
}

.modal-title{
        font: normal normal 600 21px $poppins;
        color: #0F0F2D;
        margin-bottom: 1.5em;
}


@media (max-width: 600px){
    .modal-title{
        font-size: 16px;
    }

    .font-px-18{
        font-size: 8px;
     }

}

//color

.black-1{
   color: $black-3;
}

.blue{
    color: $blue;
}

.font-blue{
    color: #3E7EFF;
}

.font-black{
    color: black
}

.font-grey{
    color: #979EA4;
}

.font-white{
    color: white;
}



//font-sizes
.font-px-18{
   font-size: 18px;
}