.profile-info-outer-div{
    display: grid;
    grid-template-columns: 1.1fr 3fr;
    grid-template-rows: auto;
    grid-template-areas: "profile-info-menu-div profle-info-right-div";
}

.modal-close-custom{
    position: absolute;
    top: 10px;
    right: 10px;
 }

.profile-info-menu-div{
    border-right: 1px solid #D5D5D5;
}

.profile-info-menu-list{
    list-style: none;
    list-style-type: none;
    list-style-position: outside;
    padding: 0px;
}

.profile-info-menu-item-text{
    font: normal normal 400 16px Poppins;
    color: #0F0F2D;
    text-transform: capitalize;
}

.profile-info-menu-list li{
   height: 70px;
   padding-left:40px;
   border-bottom: 1px solid #D5D5D5;
   display: flex;
   align-items: center;
   font: normal normal 400 16px $poppins;
    color: #0F0F2D;
    text-transform: capitalize;
}

.profile-info-menu-icon{
    height: 40px;
    width: 40px;
    margin-right: 25px;
}

.profile-info-menu-icon-2{
    height: 22px;
    width: 22px;
}

.active-menu{
    background: #3E7EFF 0% 0% no-repeat padding-box;
    border-right: none;

    .profile-info-menu-icon-div{
        background-color: white;
    }
}

.profile-info-menu-icon-div{
    height:40px;
    width: 40px;
    background-color: #F4F4F5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
}

.profile-info-right-div{
    padding: 40px 50px;  
} 

.profile-info-text-2{
    font: normal normal 600 21px $poppins;
    color: #0F0F2D;
    text-transform: uppercase;
}

.profile-edit-div{
    margin-top: 50px;
    position: relative;
}

.profile-info-image-div{
    position: relative;
    height: 110px;
    width: 110px;
}
.profile-info-image{
    height: 110px;
    width: 110px;
    border-radius: 50%;
    object-fit: cover;
    padding: 2px;
    outline: 3px solid rgb(98, 161, 4);
}

.profile-line::before{
}

.profile-name-input-div{
    max-width: 80%;
    padding:15px 30px 25px;
}

.profile-info-edit-image{
     position: absolute;
     top: 0px;
     right: 0px;
     height: 30px;
     width: 30px;
}

.profile-cancel-text{
    position: absolute;
    top: 0px;
    right: 0px;
}

.divider-line{
    border: 1px solid #E9E9EB;
    margin: 35px 0px;
}

.divider-line-2{
    border: 1px solid #E9E9EB;
    margin: 20px 0px;
}

.divider-line-3{
    border: 1px solid #E9E9EB;
    margin: 2em 0px 1em 0px;
}

.profile-name-div{
    position: relative;
    display: flex;
    align-items: center;
}

.profile-change-pwd-btn{
    background: #d6dff0 0% 0% no-repeat padding-box;
    border-radius: 25px;
    padding: 5px 30px;
    outline: none;
    border: none;
}

.modal-close-cust-btn{
    padding: 5px;
    border-radius: 25%;
}

.profile-change-pwd-btn:focus, .profile-change-pwd-btn:active{
    background: #d6dff0 0% 0% no-repeat padding-box;
}

.profile-btn-1{
    letter-spacing: 0.8px;
    font-family: poppins;
    font-size: 16px;
    margin-top: 30px;
    height: 40px;
    width: 100%;
}

.profile-edit-btn, .profile-cancel-btn{
    letter-spacing: 0.8px;
    font-family: poppins;
    font-size: 16px;
    height: 40px;
    padding: 5px 20px;
    margin-right: 20px;
}

.gender-icon{
    height: 30px;
    width: 30px;
    margin-right: 15px;
}

.gender-icon-2{
    height: 30px;
    width: 30px;
    margin-right: 6px;
    margin-left: 12px;
}

.profile-radio-input{
    margin-right: 20px;
    font-size: 16px;
}

.mob-edit-profile-title-btn{
    display: none;
 }

@media (max-width: 600px){

    .p-mt-px-10{
        margin-top: 8px;
    }

    .profile-radio-input{
        margin-right: 10px;
        font-size: 12px;
    }

    .profile-info-right-div{
        padding: 20px 20px;  
    } 
    
    .profile-name-input-div{
        max-width: 100%;
        padding:15px 10px;
    }

    .profile-info-menu-div{
        display: none;
    }

    .profile-name-div{
        display: block;
    }

    .p-mt-px-20px{
        margin-top: 45px;
    }
    
    .profile-info-outer-div{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: "profle-info-right-div";
        padding-bottom: 30px;
        overflow-y: auto;
    }

    .mob-edit-profile-title-btn{
       display: flex;
       margin: 10px;
    }

    .edit-profile-title-btn{
        display: none;
    }

    .p-email-inp{
        width: 100%;
        min-width: 100%;
    }

    .p-line-2{
       display: none;
    }
}